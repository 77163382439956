import React from 'react';
import ReactDOM from 'react-dom';
import { configure } from 'axios-hooks';
import LRU from 'lru-cache';
import axios from './config/axios.config';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "./assets/fonts/NewYorkFont/NewYorkExtraLargeBold.otf";
import "./assets/fonts/NewYorkFont/NewYorkExtraLargeMedium.otf";
import "./assets/fonts/NewYorkFont/NewYorkExtraLargeBoldItalic.otf";
import 'react-loading-skeleton/dist/skeleton.css'
const cache = new LRU({ max: 100, maxAge: 100 * 60 * 60 * 5 });
configure({ axios, cache });

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
