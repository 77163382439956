import './button.scss';

const Button = ({ text, className, onClick, children, ...otherProps }) => {
	return (
		<button
			className={`button ${className}`}
			onClick={onClick}
			title={text || ''}
			tabIndex={0}
			onKeyDown={(e) => {
				if (e.key === 'Enter') {
					onClick?.();
				}
			}}
			{...otherProps}
		>
			{text || children}
		</button>
	);
};

export default Button;
