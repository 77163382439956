import * as React from "react"

const BagIcon = () => (
  <svg width={22} height={29} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11 .5c-.895 0-1.538.568-2.086 1.049-.548.48-1.028.887-1.49.957-.455.069-1.347-.396-2.204-.85-.429-.228-.85-.444-1.314-.512-.463-.07-1.035.12-1.33.591-.426.684-.24 1.496.15 2.167.39.672.992 1.301 1.623 1.868 1.26 1.132 2.658 1.999 2.658 1.999a.5.5 0 0 0 .264.076h7.458a.5.5 0 0 0 .264-.076s1.397-.867 2.658-2c.631-.566 1.233-1.195 1.623-1.867.39-.671.576-1.483.15-2.167-.295-.472-.867-.66-1.33-.591-.463.068-.885.284-1.314.511-.857.455-1.749.92-2.205.85-.461-.069-.941-.475-1.49-.956C12.539 1.068 11.896.5 11 .5ZM7.276 8.845a.5.5 0 0 0-.182.034c-2.15.841-3.898 2.855-5.134 5.209C.724 16.442 0 19.152 0 21.5c0 3.035 1.323 4.911 3.361 5.877C5.4 28.343 8.081 28.5 11 28.5c2.92 0 5.6-.157 7.639-1.123C20.677 26.411 22 24.535 22 21.5c0-2.348-.724-5.058-1.96-7.412s-2.983-4.368-5.134-5.21a.5.5 0 0 0-.182-.033H7.276Z"
      fill="#1163FC"
    />
    <path
      d="M6.5 6.845c-.822 0-1.5.677-1.5 1.5 0 .822.678 1.5 1.5 1.5h9c.822 0 1.5-.678 1.5-1.5 0-.823-.678-1.5-1.5-1.5h-9Z"
      fill="#85AFFD"
    />
    <path
      d="M9.149 15.872a.48.48 0 0 0-.488.488v1.037h-.765a.5.5 0 0 0 0 1h.765v.551h-.765a.5.5 0 0 0 0 1h.765v1.076c0 .128.04.235.12.32.08.086.183.129.311.129.134 0 .24-.043.32-.128a.435.435 0 0 0 .129-.32v-1.077h1.842l1.093 1.388c.038.043.09.078.16.104a.783.783 0 0 0 .216.033.477.477 0 0 0 .488-.488v-1.037h.764a.5.5 0 0 0 0-1h-.764v-.552h.764a.5.5 0 0 0 0-1h-.764V16.32a.45.45 0 0 0-.12-.32.407.407 0 0 0-.311-.128.418.418 0 0 0-.32.128.45.45 0 0 0-.12.32v1.076h-1.856L9.556 16.05a.32.32 0 0 0-.176-.129.62.62 0 0 0-.231-.048Zm.392 2.524h.62l.434.552H9.541v-.552Zm1.856 0h1.071v.552h-.64l-.431-.552Z"
      fill="#BFD5FE"
    />
    </svg>
)

export default BagIcon
