import Skeleton from "react-loading-skeleton";
import styledComponents from "styled-components";

const FolderCardLoader = styledComponents.div`
    width: 100%;
    min-width: 200px;
    min-height: 200px;
    height: 280px;
    @media screen and (min-width: 500px){
        max-width: 300px;  
    }
`;

export default () => {
    return (
        <FolderCardLoader>
            <Skeleton width="100%" height="100%"/>
        </FolderCardLoader>
    );
};
