import styledComponents from "styled-components"
import {Section, Container, BlogCardSkeleton} from "../../Components";

const ContentWrapper = styledComponents.div`
    margin-bottom : 6rem;
    .type{
        font-family: 'New York Extra Large';
        font-style: normal;
        font-weight: 800;
        font-size: 38px;
        line-height: 120%;
        color: #303030;
        margin-bottom: 1.5rem;
        @media screen and (max-width: 600px){
            font-size: 25px;
        }
    }
    .content{
        width: 100%;
        display: flex;
        grid-gap: 2rem;
        flex-flow: row wrap;
    }
`;
const LoadingOtherContent = ({ type }) => {
    return (
        <Section id={`other-${type}`}>
            <Container maxWidth="1000px">
                <ContentWrapper>
                    <div className="type">Other {type}</div>
                    <div className="content">
                    {[1,2,3,4].map(( id) => (
                        <BlogCardSkeleton
                            key={id}
                            curved
                        />
                    ))}
                    </div>
                </ContentWrapper>
            </Container>
        </Section>
    );
};

export default LoadingOtherContent;
