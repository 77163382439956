const CommentIcon = ({size}) => (
<svg  width={size || 17} height={size || 17} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.375 16.375v-3.637C1.281 11.46.625 9.834.625 8.063.625 3.955 4.151.625 8.5.625s7.875 3.33 7.875 7.438c0 4.107-3.526 7.437-7.875 7.437a8.182 8.182 0 0 1-4.14-1.11l-1.985 1.985ZM8.5 13.75c3.383 0 6.125-2.546 6.125-5.688 0-3.14-2.742-5.687-6.125-5.687S2.375 4.921 2.375 8.063c0 3.14 2.742 5.687 6.125 5.687Z"
      fill="currentColor"
    />
  </svg>
)

export default CommentIcon

