import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import CrownIcon from "../../assets/svgs/Crown";
import Logo from "../../assets/Logo.png";
import * as ROUTES from "../../routes";
import "./header.scss";

const Header = () => {
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [headerFixed, setHeaderFixed] = useState(false);
  const location = useLocation();
  const toggleNavbar = () => {
    setNavbarOpen((navbarOpen) => !navbarOpen);
  };

  const checkScrollHeight = () => {
    if (window.scrollY === 0) {
      setHeaderFixed(false);
    } else {
      setHeaderFixed(true);
    }
  };

  useEffect(() => {
    setNavbarOpen(false);
  }, [location]);

  useEffect(() => {
    window.addEventListener("scroll", checkScrollHeight);
    return () => {
      window.removeEventListener("scroll", checkScrollHeight);
    };
  }, []);

  return (
    <header id="header" className={headerFixed ? "fixed" : ""}>
      <nav id="nav">
        <NavLink to={ROUTES.HOME} className="logo">
          <img src={Logo} alt="chessinslums-logo" />
        </NavLink>

        <div
          id="hamburger"
          className={`${navbarOpen ? "open" : ""}`}
          onClick={toggleNavbar}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>

        <ul>
          <li className="active">
            <NavLink
              to={ROUTES.HOME}
              className={({ isActive }) =>
                `nav__link ${isActive ? "active" : ""}`
              }
            >
              <CrownIcon />
              Home
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/about"
              className={({ isActive }) =>
                `nav__link ${isActive ? "active" : ""}`
              }
            >
              <CrownIcon />
              About
            </NavLink>
          </li>
          <li>
            {/* <a
							href='https://t.co/kgUn6GwF7S'
							className='nav__link'
							target='_blank'
							rel='noreferrer'
						>
							Merchandise
						</a> */}

            <span>Merchandise</span>
          </li>
          <li>
            <NavLink
              to={ROUTES.BLOG}
              className={({ isActive }) =>
                `nav__link ${isActive ? "active" : ""}`
              }
            >
              <CrownIcon />
              Blog
            </NavLink>
          </li>
          <li>
            <NavLink
              to={ROUTES.CONTACT}
              className={({ isActive }) =>
                `nav__link ${isActive ? "active" : ""}`
              }
            >
              <CrownIcon />
              Contact
            </NavLink>
          </li>
          <li>
            <NavLink to={ROUTES.DONATE} className="nav__link donate">
              {" "}
              Donate{" "}
            </NavLink>
          </li>
        </ul>
      </nav>

      <nav id="nav__small" className={navbarOpen ? "open" : ""}>
        <ul className="slide__down">
          <li className="active">
            <NavLink
              to="/"
              className={({ isActive }) =>
                `nav__link ${isActive ? "active" : ""}`
              }
            >
              <CrownIcon />
              Home
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/about"
              className={({ isActive }) =>
                `nav__link ${isActive ? "active" : ""}`
              }
            >
              <CrownIcon />
              About
            </NavLink>
          </li>
          <li>
            {/* <a
              href="https://t.co/kgUn6GwF7S"
              className="nav__link"
              target="_blank"
              rel="noreferrer"
            >
              Merchandise
            </a> */}

            <span>Merchandise</span>
          </li>
          <li>
            <NavLink
              to="/contact"
              className={({ isActive }) =>
                `nav__link ${isActive ? "active" : ""}`
              }
            >
              <CrownIcon />
              Contact
            </NavLink>
          </li>
          <li>
            <NavLink
              to={ROUTES.BLOG}
              className={({ isActive }) =>
                `nav__link ${isActive ? "active" : ""}`
              }
            >
              <CrownIcon />
              Blog
            </NavLink>
          </li>
          <li>
            <NavLink to="/donate" className="nav__link donate">
              Donate
            </NavLink>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
