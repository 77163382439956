const ReplyIcon = ({ size }) => (
    <svg
        width={size || 18}
        height={size || 18}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M17 11.667a1.778 1.778 0 0 1-1.778 1.777H4.556L1 17V2.778A1.778 1.778 0 0 1 2.778 1h12.444A1.778 1.778 0 0 1 17 2.778v8.889Z"
            stroke="currentColor"
            strokeWidth={1.3}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default ReplyIcon;
