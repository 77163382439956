import styledComponents from "styled-components";

const AsideDiv = styledComponents.div`
    width: 100%;
    max-width: 315px;
    cursor: pointer;
    .image{
        width: 100%;
        height: 200px;
        background: url(${(props) => props.image});
        background-position: 50% 50%;
        background-size: cover; 
        margin-bottom: 1rem; 
    }
    .title{
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 134%;
        padding-bottom: 1rem;
    }
    .time{
        font-size: 12px;
        color: var(--text-gray);
    }
`

const AsideCard = ({image, title, date, navigate}) => {
    return <AsideDiv image={image} onClick={() => navigate?.()}>
            <div className="image"/>
            <div className="title">
                {title}
            </div>
            <div className="time">
                {date}
            </div>
    </AsideDiv>
};

export default AsideCard;
