import styledComponents from "styled-components";
import GifOne from "../../assets/CISA preloaderLight.gif";

const PageLoaderDiv = styledComponents.div`
    position : fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    flex-flow: column;
    .text{
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.19em;
        text-transform: uppercase;
        color: #DADADA;
        transition: all 500ms ease-in-out;
        &:hover{
            color: var(--primary-two);
        }
        transform: scale(1.2)
    }
    .gif{
        width: 110px;
        height: 110px;
        margin-bottom: 2rem;
        img{
            width: 100%;
            height: 100%;
        }
    }

`;

const PageLoader = () => {
    return (
        <PageLoaderDiv>
            <div className="gif"><img src={GifOne}/></div>
            <div className="text">loading ...</div>
        </PageLoaderDiv>
    );
};

export default PageLoader;
