import styledComponents from "styled-components";
import { BsDot } from "react-icons/bs";
import { Section, Container } from "../../Components";

const ContentHeaderDiv = styledComponents(Section)`
    width: 100%;
    --background : ${(props) => (props.inverse ? "black" : "white")};
    --color : ${(props) => (!props.inverse ? "#303030" : "white")};
    background-color: var(--background);
    .type{
        font-family: 'New York Extra Large';
        font-style: normal;
        font-weight: 800;
        font-size: 24px;
        line-height: 125%;
        color: var(--color);
        margin-bottom: 1.5rem;
        text-transform: capitalize;
    }
    .title{
        font-family: 'New York Extra Large';
        font-style: normal;
        font-weight: 800;
        font-size: 48px;
        line-height: 125%;
        color: var(--color);
        margin-bottom: 1.5rem;
    }
    .info{
        display: flex;
        padding-bottom: 1rem;
        color: var(--text-gray);
        .author, .timestamp, span{ 
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 125%;
            color: ${(props) =>
                !props.inverse ? "var(--text-gray)" : "#ffffff"};
        }
        span{
            font-weight: bold;
        }
    }
    @media screen and (max-width: 600px){
        .title{
            font-size: 32px;
        }
        .type{
            display: none;    
        }
    }
`;

const ContentHeader = ({ type, title, date, author, invert }) => {
    return (
        <ContentHeaderDiv
            id={`${type}-header`}
            inverse={invert}
            padding="4rem 2rem"
        >
            <Container maxWidth="1000px">
                <div className="type">{type}</div>
                <div className="title">{title}</div>
                <div className="info">
                    <div className="author">
                        <span>Author: </span>
                        {author}
                    </div>
                    <BsDot />
                    <div className="timestamp">{date}</div>
                </div>
            </Container>
        </ContentHeaderDiv>
    );
};

export default ContentHeader;
