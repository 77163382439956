const FbIcon = ({ size }) => {
    return (
        <svg
            width={size || 25}
            height={size || 26}
            viewBox="0 0 25 26"
            ffill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M25 13.079C25 5.856 19.403 0 12.5 0S0 5.856 0 13.079c0 6.527 4.57 11.938 10.547 12.92V16.86H7.372V13.08h3.175v-2.882c0-3.277 1.867-5.089 4.721-5.089 1.368 0 2.799.256 2.799.256v3.219H16.49c-1.552 0-2.037 1.008-2.037 2.043v2.453h3.466l-.553 3.781h-2.913V26C20.43 25.018 25 19.608 25 13.079Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default FbIcon;
