import styledComponents from "styled-components";


const FilesWrapper = styledComponents.div`
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(290px, 300px));
    grid-gap : 21px;
    @media screen and (max-width: 600px){
        grid-gap : 10px;
        grid-template-columns: repeat(auto-fit,minmax(200px, 1fr));
    }
`


export default FilesWrapper
