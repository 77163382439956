const HalfStar = () => (
  <svg width={14} height={14} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.017 12.85a.665.665 0 0 0 1.02.705l3.63-2.42 3.63 2.42a.667.667 0 0 0 1.011-.738l-1.22-4.266 3.025-2.722a.667.667 0 0 0-.394-1.16l-3.8-.302L7.274.726a.665.665 0 0 0-1.215 0l-1.644 3.64-3.801.303A.667.667 0 0 0 .201 5.81l2.81 2.738-.994 4.301Zm4.65-10.23 1.361 3.013.392.032 2.649.21-2.181 1.962v.002l-.31.277.115.398v.002l.835 2.923-2.861-1.907V2.619Z"
      fill="#CBB77A"
    />
  </svg>
)

export default HalfStar
