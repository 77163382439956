import { splitIntoParagraphs } from "../../utils";
import "./ourimpact.scss";

const OurImpact = ({ impactBody, impactTitle, impactImages }) => {
    const paragraphs = splitIntoParagraphs(impactBody);
    return (
        <section id="our__impact">
            <div className="our__impact__content">
                <h1 className="title">{impactTitle}</h1>
                <div className="image__text">
                    <div className="text__container">
                        {paragraphs.map((pag, id) => (
                            <p key={id}>{pag}</p>
                        ))}
                    </div>
                    <div className="image__container">
                        {impactImages?.map((imgUrl, id) => (
                            <img src={imgUrl + "/tr:q-80,w-392"} key={id} alt="our-impact" />
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default OurImpact;
