import { Button, NFTcard } from '../../Components';
import NFTone from '../../assets/NFTOne.png';
import NFTtwo from '../../assets/NFTtwo.png';
import NFTthree from '../../assets/NFTthree.png';
import './NFTDonation.style.scss';
import AppContext from '../../context/app.context';
import { useContext } from 'react';

const NFTDonation = ({ title }) => {
	const { NFTsData: suspender } = useContext(AppContext);

	const { docs } = suspender?.read() || {};

	return (
		<section id='NFT_donation'>
			<div className='container'>
				<div className='title'>
					{title ?? 'We also accept donations via our NFT purchase.'}
				</div>
				<div className='NFTs'>
					{docs.map((NFT) => (
						<NFTcard
							key={NFT?.id}
							image={NFT?.image}
							name={NFT?.name}
							price={`${NFT?.price} ETH`}
							url='https://foundation.app/collection/cis'
						/>
					))}

					{!Boolean(docs?.length) && (
						<>
							<NFTcard
								image={NFTone}
								name={'The Power Move'}
								price={'2.0 ETH'}
								url='https://foundation.app/@Shutabug/cis/3'
							/>
							<NFTcard
								image={NFTtwo}
								name={'WAGMI Omo Iya mi'}
								price={'2.0 ETH'}
								url='https://foundation.app/@Shutabug/cis/2'
							/>
							<NFTcard
								image={NFTthree}
								name={'Royalty in Everyone'}
								price={'2.0 ETH'}
								url='https://foundation.app/@Shutabug/cis/1'
							/>
						</>
					)}
				</div>
				<Button className={'buy_button'}>
					<a
						href='https://foundation.app/collection/cis'
						target='_blank'
						rel='noreferrer'
					>
						BUY NFTS
					</a>
				</Button>
			</div>
		</section>
	);
};

export default NFTDonation;
