import styledComponents from "styled-components";

const InputDiv = styledComponents.div`
    width : 100%;
    font-size : 16px;
    font-family: 'Kumbh Sans';
    font-style: normal;
    font-weight: 400;
    border: 1.5px solid #EBEBEB;
    border-radius:5px;
    padding: 1rem;
    min-height: 50px;
    display: flex;
    .tag{
        padding-right: .5rem;
        border-right: 1px solid #ADADAD;
    }
    input{
        flex : auto;
        outline: none;
        border: none;
        padding-left: .5rem;
        &::placeholder {
            color: #5C5C5C;
        }
    }
    outline-color : var(--primary-two);
`

const PhoneInput = ({name, onChange, value, ...otherProps}) => {
    return (
        <InputDiv>
            <div className="tag">+234</div>
            <input name={name} type="tel" required value={value} onChange={onChange} {...otherProps}/>
        </InputDiv>
    )
}

export default PhoneInput;
