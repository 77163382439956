import { useState, useEffect } from 'react';
import axios from '../config/axios.config';
import { wrapPromise } from '../utils';

const useNFTs = () => {
	const [NFTsData, setNFTsData] = useState(null);
	useEffect(() => {
		setNFTsData(wrapPromise(axios('/nfts')));
	}, []);
	return NFTsData;
};

export default useNFTs;
