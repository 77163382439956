import styledComponents from "styled-components";
import FbIcon from "../../assets/svgs/FbIcon";
import LinkedInIcon from "../../assets/svgs/LinkedInIcon";
import TwitterIcon from "../../assets/svgs/Twitter";

const TeamCard = ({
  image,
  name,
  email,
  position,
  linkedIn,
  twitter,
  facebook,
  isExecutive,
}) => {
  return (
    <TeamCardDiv>
      <div className="image_div">
        <img src={image} alt={`${name} avatar`} />
      </div>
      <div className="name">{name}</div>
      <div className="position">{position}</div>
      {email && (
        <a href={`mailto:${email}`} className="email">
          {email}
        </a>
      )}
      <div className="handles">
        {facebook && (
          <a href={facebook || "#"} target="_blank" rel="noreferrer">
            <FbIcon size={20} />
          </a>
        )}
        {twitter && (
          <a href={twitter || "#"} target="_blank" rel="noreferrer">
            <TwitterIcon inverted size={20} />
          </a>
        )}
        {linkedIn && (
          <a href={linkedIn || "#"} target="_blank" rel="noreferrer">
            <LinkedInIcon squared size={20} />
          </a>
        )}
      </div>

      {isExecutive && (
        <a
          href={
            "https://drive.google.com/file/d/10bkDZ0ebxqft9bFU9xd1UwVkCKoGeUxp/view?usp=drivesdk"
          }
          target="_blank"
          rel="noreferrer"
          className="biography"
        >
          View biography
        </a>
      )}
    </TeamCardDiv>
  );
};

const TeamCardDiv = styledComponents.div`
   width: 280px;

   .image_div{
        width:100%;
        height: 300px;
        overflow: hidden;
        border-radius: 8px;
        background: #C4C4C4;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
   }
    .name{
        padding: 1rem 0 .5rem 0;
        font-size: 20px;
        color: #1D2130;
        text-align: center;
        font-weight: 500;
    }
    .position,.email{
        display: block;
        color: #1D2130;
        opacity: 0.6;
        text-align: center;
        font-weight: 600;
        font-size: 13px;
        margin-bottom: .2rem;
    }
    .handles{
        margin-top: 12px;
        display: flex;
        grid-gap: 1rem;
        justify-content: center;
        align-items: center;
        a{
            color: #1D2130;
        }
    }
    .biography{
        display: block;
        margin-top: 9px;
        font-weight: 600;
        font-size: 13px;
        line-height: 160%;
        text-align: center;
        text-decoration-line: underline;
        color: #0067A2;
        opacity: 0.6;
    }
`;

export default TeamCard;
