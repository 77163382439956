import FormGroup from "../FormGroup";
import Modal from "../Modal";
import Input from "../Input";
import PhoneInput from "../PhoneInput";
import RadioInput from "../RadioInput";
import Button from "../Button";
import FormSuccessModal from "../FormSuccessModal";
import FormWrapper from "../FormWrapper";
import { useState, useMemo, useCallback } from "react";
import { debounceFunction } from "../../utils";
import { useAxiosBase } from "../../config/axios.config";
import FormLoader from "../FormLoader";
import { toast } from "react-toastify";

const FrequencyOption = [
    {
        name: "Daily",
        label: "daily",
        default: true
    },
    {
        name: "Weekly",
        label: "weekly",
    },
    {
        name: "Monthly",
        label: "monthly",
    },
];

const NewsletterModal = ({ open, close }) => {
    const [openModal, setOpenModal] = useState(false);
    const [data, setData] = useState({preferredFrequency : "daily"});
    const [{ loading }, submit] = useAxiosBase(
        { url: "/subscriptions", method: "POST" },
        { manual: true }
    );

    const clearForm = useCallback((form) => {
        form?.reset?.();
        setData({});
    }, []);

    const onDataChange = useCallback(
        (key, noRef = false) =>
            debounceFunction((e) => {
                setData((data) => {
                    let newData = { ...data };
                    if (noRef) {
                        newData[key] = e;
                    } else {
                        newData[key] = e.target.value;
                    }
                    return newData;
                });
            }),
        []
    );

    const onEmailChange = useMemo(() => onDataChange("email"), []);
    const onFirstNameChange = useMemo(() => onDataChange("firstName"), []);
    const onLastNameChange = useMemo(() => onDataChange("lastName"), []);
    const onPhoneChange = useMemo(() => onDataChange("phoneNumber"), []);
    const onFrequencyChange = useMemo(
        () => onDataChange("preferredFrequency", true),
        []
    );
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await submit({ data });
            setOpenModal(true);
            clearForm(e.target);
            close?.();
        } catch (e) {
            const data = e.response?.data;
            const message = data.message;
            if (message.email) { 
                toast.error(message.email)
            }
        }
    };

    return (
        <>
            <Modal open={open} close={close}>
                <form onSubmit={handleSubmit}>
                    <FormWrapper>
                        <div className="form_title">
                            Subscribe to our newsletter
                        </div>
                        <div className="form">
                            <div className="row">
                                <div className="col">
                                    <FormGroup
                                        label={"Firt Name"}
                                        forName="first_name"
                                    >
                                        <Input
                                            required
                                            placeholder="John"
                                            name="first_name"
                                            onChange={onFirstNameChange}
                                        />
                                    </FormGroup>
                                </div>
                                <div className="col">
                                    <FormGroup
                                        label={"Last Name"}
                                        forName="last_name"
                                    >
                                        <Input
                                            required
                                            placeholder="Doe"
                                            name="last_name"
                                            onChange={onLastNameChange}
                                        />
                                    </FormGroup>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <FormGroup label={"Email"} forName="email">
                                        <Input
                                            required
                                            placeholder="john@doe.com"
                                            name="email"
                                            onChange={onEmailChange}
                                        />
                                    </FormGroup>
                                </div>
                                <div className="col">
                                    <FormGroup
                                        label={"Phone number"}
                                        forName="number"
                                    >
                                        <PhoneInput
                                            name="phone"
                                            onChange={onPhoneChange}
                                        />
                                    </FormGroup>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <FormGroup
                                        label={"Preferred frequency"}
                                        forName="number"
                                    >
                                        <RadioInput
                                            name="frequency"
                                            options={FrequencyOption}
                                            onChange={onFrequencyChange}
                                        />
                                    </FormGroup>
                                </div>
                            </div>
                            <div className="row">
                                <Button text="Submit" />
                            </div>
                        </div>
                    </FormWrapper>
                </form>
            </Modal>
            {loading && <FormLoader />}
            <FormSuccessModal
                open={openModal}
                close={() => setOpenModal((v) => !v)}
                title="Thanks for subscribing"
                description={""}
            />
        </>
    );
};

export default NewsletterModal;
