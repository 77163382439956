import { useState, useEffect} from "react";
import {wrapPromise} from "../utils";
import axios from "../config/axios.config";


const useScholarshipPageData = () => {    
    const [data, setData] = useState({read: () => {}});
    useEffect(() => {
        setData(wrapPromise(axios.get("/scholarshipPages")));
    },[])

    return data
}

export default useScholarshipPageData;

