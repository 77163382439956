import Modal from "../Modal";
import styledComponents from "styled-components";

const ModalContent = styledComponents.div`
        width:100%;
        min-height: 500px;
        display:flex;
        justify-content: center;
        align-items:center;
        flex-flow: column;
        .title{
            font-family : "New York Extra Large";
            font-size: 56px;
            padding-bottom: 3rem;
            font-weight: 600;
            text-align: center;
            color: var(--title-color);
            max-width: 650px;
       }
       .description {
            color: var(--text-gray);
            font-size: 36px;
            text-align: center;
       }
       @media screen and (max-width: 800px){
            .title{
                font-size: 35px;
            }
            .description{
                font-size: 20px;
            }
       }
`;

const FormSuccessModal = ({ open, close, title, description}) => {
    return (
        <Modal open={open} close={close}>
            <ModalContent>
                <div className="title">{title ||"We have received your application"}</div>
                <div className="description">{description || "Please check your email"}</div>
            </ModalContent>
        </Modal>
    );
};

export default FormSuccessModal;
