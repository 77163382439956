
const FullStar = () => (
  <svg width={14} height={14} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.631 5.12a.668.668 0 0 0-.578-.451l-3.801-.302L7.607.726a.665.665 0 0 0-1.214 0l-1.645 3.64-3.8.303A.667.667 0 0 0 .534 5.81l2.808 2.738-.993 4.301a.667.667 0 0 0 1.02.705L7 11.135l3.63 2.42a.667.667 0 0 0 1.011-.738l-1.22-4.266 3.025-2.722a.668.668 0 0 0 .185-.71Z"
      fill="#CBB77A"
    />
  </svg>
)

export default FullStar
