import { useState, useEffect} from "react";
import {wrapPromise} from "../utils";
import axios from "../config/axios.config";


const useAlbums = () => {    
    const [albums, setAlbums] = useState({read : () => {}});

    useEffect(() => {
        setAlbums(wrapPromise(axios.get("/albums")));
    },[])

    return albums
}

export default useAlbums;

