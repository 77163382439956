import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { lazy, Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import {
	ErrorFallback,
	Footer,
	Header,
	PageLoader,
	ScrollToZero,
} from './Components';
import './App.scss';
import { Helmet } from 'react-helmet';
import * as ROUTES from './routes';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { AppProvider } from './context/app.context';
import TeamsPage from './pages/Teams';
const pageImport = (path) => lazy(() => import(`./pages${path}`));
const Home = pageImport('/Home');
const About = pageImport('/About');
const DonatePage = pageImport('/Donate');
const PartnerPage = pageImport('/Partner');
const VolunteerPage = pageImport('/Volunteer');
const Gallery = pageImport('/Gallery');
const ContactPage = pageImport('/Contact');
const Blog = pageImport('/b/blog');
const Album = pageImport('/Gallery/album');
const Folder = pageImport('/Gallery/folder');
const BlogPage = pageImport('/b');
const News = pageImport('/b/news');
const Announcements = pageImport('/b/announcements');
const Features = pageImport('/b/features');
const All = pageImport('/b/all');
const SingleBlog = pageImport('/b/Single/blog');
const SingleAnnouncement = pageImport('/b/Single/announcement');
const SingleNews = pageImport('/b/Single/news');
const ScholarshipPage = pageImport('/Scholarship');
const Page404 = pageImport('/404');

function App() {
	return (
		<ErrorBoundary FallbackComponent={ErrorFallback}>
			<AppProvider>
				<div id='app'>
					<Helmet>
						<title>Chess In Slums Africa | Home</title>
						<meta
							name='description'
							content='A non-profit organization using the game of chess to help youngsters in slum communities realize their full potential through chess training and life orientation.'
						/>
					</Helmet>
					<ToastContainer
						position='bottom-right'
						autoClose={5000}
						closeOnClick
						hideProgressBar={false}
						pauseOnFocusLoss
						draggable
						pauseOnHover
						progressStyle={{ color: 'var(--primary-two)' }}
					/>
					<Router>
						<Suspense fallback={<PageLoader />}>
							<Header />
							<ScrollToZero>
								<Routes>
									<Route path={ROUTES.HOME} element={<Home />} />
									<Route
										path={ROUTES.SCHOLARSHIP}
										element={<ScholarshipPage />}
									/>
									<Route path={ROUTES.ABOUT} element={<About />} />
									<Route path={ROUTES.DONATE} element={<DonatePage />} />
									<Route path={ROUTES.CONTACT} element={<ContactPage />} />
									<Route path={ROUTES.TEAMS} element={<TeamsPage />} />
									<Route path={ROUTES.PARTNER} element={<PartnerPage />} />
									<Route path={ROUTES.VOLUNTEER} element={<VolunteerPage />} />
									<Route path={ROUTES.GALLERY} element={<Gallery />} />
									<Route path={ROUTES.ALBUM} element={<Album />} />
									<Route path={ROUTES.FOLDER} element={<Folder />} />
									<Route path={ROUTES.BLOG} element={<BlogPage />}>
										<Route path='' element={<Blog />} />
										<Route path='blogs' element={<Blog />} />
										<Route path='news' element={<News />} />
										<Route path='announcements' element={<Announcements />} />
										<Route path='features' element={<Features />} />
										<Route path='all' element={<All />} />
										<Route path=':blogSlug' element={<SingleBlog />} />
										<Route path='blogs/:blogSlug' element={<SingleBlog />} />
										<Route
											path='announcements/:blogSlug'
											element={<SingleAnnouncement />}
										/>
										<Route path='news/:blogSlug' element={<SingleNews />} />
									</Route>
									<Route path='*' element={<Page404 />} />
								</Routes>
							</ScrollToZero>
							<Footer />
						</Suspense>
					</Router>
				</div>
			</AppProvider>
		</ErrorBoundary>
	);
}

export default App;
