import styledComponents from "styled-components";

const Input = styledComponents.input`
    width : 100%;
    font-size : 16px;
    font-family: 'Kumbh Sans';
    font-style: normal;
    font-weight: 400;
    border: 1.5px solid #EBEBEB;
    border-radius:5px;
    padding: 1rem;
    min-height: 50px;
    &::placeholder {
        color: #5C5C5C;
    }
    outline-color : var(--primary-two);
`

export default Input;
