import { Section, Container } from "../../Components";
import { useNavigate } from "react-router-dom";
import styledComponent from "styled-components";
import { Button } from "../../Components";
import * as ROUTES from "../../routes";

const DonateContainer = styledComponent(Container)`
    display:flex;
    flex-flow: row wrap;
`;

const Card = styledComponent.div`
    flex: 50%;
    min-width: 200px;
    height: 428px;
    background: ${(props) => props.background};
    background-position: 50% 20%;
    background-size: cover;
    display: flex;
    flex-flow: column;
    justify-content: center;
    .button__primary {
        width: 197px;
        height: 56px;
        border-radius: 43px;
    }
    .button__primary.v {
        width: 287px;
        height: 56px;
        border-radius: 43px;
    }
    padding: 0 6rem; 
    .title{
        font-family: "New York Extra Large";
        font-weight: 600;
        color: var(--white);
        font-size: 46px;
        max-width: 443px;
        padding-bottom: 1rem;
    }
    @media screen and (max-width : 900px){
        flex : auto;
        padding: 2rem;
    }
`;

const DonateVolunteer = () => {
    const navigate = useNavigate();
    return (
        <Section padding="0">
            <DonateContainer maxWidth="1600px">
                <Card
                    background={`linear-gradient(90deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 140.78%), url(https://ik.imagekit.io/w348wgpgc11/assets/tr:q-50,w-500,fo-auto/BlogImage3_sLwsNYbt2C.png?ik-sdk-version=javascript-1.4.3&updatedAt=1644616799603)`}
                >
                    <div className="title">Donate to support our cause</div>
                    <Button
                        className="button__primary"
                        text="donate now"
                        onClick={() => navigate("/donate")}
                    />
                </Card>
                <Card
                background={`linear-gradient(90.2deg, rgba(0, 0, 0, 0.38) 5.09%, rgba(0, 0, 0, 0.005) 140.36%), url(https://ik.imagekit.io/w348wgpgc11/assets/tr:q-50,w-500,fo-auto/ChildrenImage_IuQ6nFQ9u.png)`}
                >
                    <div className="title">Want to help us do more?</div>
                    <Button
                        className="button__primary v"
                        text="Volunteer with us"
                        onClick={() => navigate(ROUTES.VOLUNTEER)}
                    />
                </Card>
            </DonateContainer>
        </Section>
    );
};
export default DonateVolunteer;
