import { Section, Container } from "../../Components";
import styledComponents from "styled-components";
import { useEffect, useRef } from "react";

const ScholarshipCost = ({ title, image, body }) => {
  const ref = useRef();

  useEffect(() => {
    if (body && ref.current) {
      ref.current.innerHTML = body;
    }
  }, [body, ref]);

  return (
    <Section background="#FFFCF5" padding="0">
      <Container maxWidth="1600px">
        <ScholarshipCostDiv>
          <div className="content">
            <div className="wrap">
              <div className="title">{title ?? "Be a child's chaperone"}</div>
              <div className="description">
                <p className="p">
                  Education is the capacity for independent thought, a
                  fundamental right every child deserves.
                </p>

                <p className="p">
                  Today, you can make a difference by sponsoring a child through
                  our academic scholarship program at Chess in Slums Africa.
                </p>

                <p className="p">
                  Our scholarships cater to both Primary/Secondary and
                  University/College education. The average cost per session is
                  N200,000 ($300) for Primary/Secondary and N300,000 ($400) for
                  University/College.
                </p>

                <p className="p">
                  Additionally, you can contribute to vocational training,
                  internships, or share your expertise to mentor a CISA child.
                  The average cost to enroll a child in a vocational program is
                  N100,000.
                </p>

                <p className="p">
                  Your support not only grants access to education but also
                  provides a platform to actualize their dreams, shaping a
                  brighter future for our champions and their communities.
                </p>
              </div>
            </div>
          </div>
          <div className="image">
            <img
              src={
                image ||
                "https://ik.imagekit.io/w348wgpgc11/assets/tr:q-50/CISA_1Uniform_FogUoV2hk.png?ik-sdk-version=javascript-1.4.3&updatedAt=1649194789343"
              }
              alt="boy-image"
            />
          </div>
        </ScholarshipCostDiv>
      </Container>
    </Section>
  );
};

const ScholarshipCostDiv = styledComponents.div`
    padding: 5rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .content{
        flex: auto;
        padding: 0 1rem;
        .wrap{
            margin: 0 auto;
            max-width: 650px;
            .title{
                font-family: 'New York Extra Large';
                font-style: normal;
                font-weight: 600;
                font-size: 48px;
                line-height: 110%;
                color: #303030;
            }
            .description{
                width: 100%;
                padding: 2rem 0;
                p{
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 170%;
                    color: var(--text-gray);
                    padding-bottom: 2rem;
                }
            }
        }
    }
    .image{
        width: 530px;
        height: 670px;
        border-radius: 1rem 0 0 1rem; 
        overflow:hidden;
        img{
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
    @media screen and (max-width: 900px){
        flex-flow: column; 
        .image{
            width: 100%;
            height: 500px;
            margin-top: 2rem;
            align-self: flex-end;
            max-width: 400px;
        }
    }
`;
export default ScholarshipCost;
