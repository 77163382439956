import styledComponents from "styled-components";
import {
    Section,
    Container,
    Comment,
    TextArea,
    Input,
    FormGroup,
    Button,
} from "../../Components";
import { useState, useCallback } from "react";
import ReactPaginate from "react-paginate";
import ArrowDown from "../../assets/svgs/ArrowDown";
import useArticlesComments from "../../hooks/useArticlesComments";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";

const BlogComments = ({ id }) => {
    const comments = useArticlesComments(id);
    const [name, setName] = useState("");
    const [text, setText] = useState("");
    const createComment = useCallback(
        async (e) => {
            e.preventDefault();
            if (comments.sending) {
                return;
            }
            let response = await comments.create(name, text);
            toast.success("Comment created")
            if (response) {
                setName("");
                setText("");
            }
        },
        [comments, text, name]
    );
    return (
        <Section id="comment_and_form">
            <Container maxWidth="1000px">
                <FormWrapper>
                    <div className="comments">
                        {comments.loading &&
                            ["a", "b", "c"].map((i) => (
                                <Skeleton
                                    width="100%"
                                    height="100px"
                                    margin=".5rem 0"
                                    key={i}
                                />
                            ))}
                        {!comments.loading &&
                            comments
                                .read()
                                .map((comment) => (
                                    <Comment
                                        text={comment.text}
                                        name={comment.name}
                                        key={comment.id}
                                        curved
                                    />
                                ))}
                    </div>
                    <div className="comment__pagination">
                        <MyPaginate
                            breakLabel="..."
                            nextLabel={<ArrowDown />}
                            onPageChange={(v) =>
                                comments.loadPage(+v.selected + 1)
                            }
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={1}
                            pageCount={comments.pages}
                            previousLabel={<ArrowDown />}
                            renderOnZeroPageCount={null}
                        />
                    </div>
                    <div id="comment__form">
                        <form
                            className="comment__form"
                            onSubmit={createComment}
                        >
                            <div className="form__title">Comment</div>
                            <div className="name__input">
                                <FormGroup label="Name" forName="name">
                                    <Input
                                        name="name"
                                        placeholder="John"
                                        value={name}
                                        onChange={(e) =>
                                            setName(e.target.value)
                                        }
                                    />
                                </FormGroup>
                            </div>
                            <div className="text__input">
                                <TextArea
                                    background="var(--white)"
                                    value={text}
                                    onChange={(e) => setText(e.target.value)}
                                />
                            </div>
                            <Button text="POST COMMENT" />
                        </form>
                    </div>
                </FormWrapper>
            </Container>
        </Section>
    );
};

const FormWrapper = styledComponents.div`
        width: 100%;
        margin-bottom: 2.5rem;
        .comments{
            display: flex;
            flex-flow: column;
            grid-gap: 1rem;
            padding: 2rem 0;
        }
        .comment__form{
            margin-top: 5rem;
            padding: 2rem;
            border-radius: 15px;
            background: rgba(248, 248, 248, 0.5);
            .form__title{
                font-weight: 600;
                font-size: 20px;
                line-height: 150%;
                padding-bottom: 1rem;
            }
            .name__input, .text__input{
                margin-bottom: 1.5rem;
            }
            button{
                padding : 1rem 2rem;
                min-width: 200px;
                text-align: center;
                border-radius: 40px;
                color: var(--white);
                background-color: var(--primary-two);
            }
        }
`;

const MyPaginate = styledComponents(ReactPaginate).attrs({
    activeClassName: "active",
})`
    display: flex;
    flex-direction: row;
    list-style-type: none;
    padding: 1rem 0 ;
    align-items: center;
    li a {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        background: none;
        border-radius: 5px;
        color: var(--text-gray);
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        min-width: 27px;
        min-height: 27px;
        transform-origin: 50% 50%;
    }
    li.active a {
    background: #FFF0DD;
    border-radius: 5px;
    }
    li.disabled a {
    color: grey;
    }
    li.disable,
    li.disabled a {
    cursor: default;
    }
    li.next a{
        transform: rotate(270deg);
    }
    li.previous a{
        transform: rotate(90deg);
    }
`;

export default BlogComments;
