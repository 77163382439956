const RetweetIcon = ({size}) => (
  <svg width={size || 20} height={size || 16} fill="none" viewBox="0 0 20 16"  xmlns="http://www.w3.org/2000/svg">
    <path
      d="m12.974 12.2 2.763 2.8 2.763-2.8"
      stroke="currentColor"
      strokeWidth={1.3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.29 1h2.763a3.66 3.66 0 0 1 2.605 1.093c.69.7 1.079 1.65 1.079 2.64V13.6M6.526 3.8 3.763 1 1 3.8"
      stroke="currentColor"
      strokeWidth={1.3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.21 15H7.447a3.66 3.66 0 0 1-2.605-1.094c-.69-.7-1.079-1.65-1.079-2.64V2.4"
      stroke="currentColor"
      strokeWidth={1.3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default RetweetIcon

