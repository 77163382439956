import styledComponents from "styled-components";

const SectionWrapper =  styledComponents.section`
    background : ${props => props.background || "var(--white)"};
    padding:  ${props => props.padding ||"0 2rem"};
    .container {
        margin : 0 auto;
        width: 100%;
        max-width : ${props => props.maxWidth || "1440px"};
    }
`


export default SectionWrapper;
