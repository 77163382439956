import styledComponents from "styled-components";

const CommentDiv = styledComponents.div`
    width: 100%;
    height: 50px;
    padding: 0.5rem;
    border-radius: 5px;
    border: 1.5px solid #EBEBEB;
    display: flex;
    .name__input{
        flex: 0 0 25%;
        border-right: 2px solid #EBEBEB;
    }
    .comment__input{
        flex: auto;
    }
    input{
        width:100%;
        padding: 0 0.5rem;
        &::placeholder {
            color: #5C5C5C;
        }
        border: none;
        background none;
    }
`;

const CommentInput = ({ name, comment, onChangeName, onChangeComment }) => {
    return (
        <CommentDiv>
            <input placeholder="Name" className="name__input" value={name} onChange={onChangeName}/>
            <input  placeholder="Comment" className="comment__input" value={comment} onChange={onChangeComment}/>
        </CommentDiv>
    );
};

export default CommentInput;
