import { useState, useEffect } from 'react';
import axios from '../config/axios.config';
import { wrapPromise } from '../utils';

const useMerchandise = () => {
	const [merchandisesData, setmerchandisesData] = useState(null);
	useEffect(() => {
		setmerchandisesData(wrapPromise(axios('/merchandises/featured')));
	}, []);
	return merchandisesData;
};

export default useMerchandise;
