import { AccountModal, DonationCard, DonationStat } from "../../Components";
import GoFundIcon from "../../assets/GoFundMeLogo.png";
import BitIcon from "../../assets/BitCoin.png";
import cisadonate from "../../assets/cisadonate.png";
import BagIcon from "../../assets/svgs/BagIcon";
import "./donationMethods.style.scss";
import { useState } from "react";
import MonthlyDonateModal from "../../Components/MonthlyDonateModal";

const DonationMethods = ({ title, fundPurpose, fundStat, fundUseMethods }) => {
  const [seeAccount, openAccount] = useState(false);
  const [open, setOpen] = useState(false);

  return (
    <section id="donation_methods">
      <div className="container">
        <div className="title">
          {title ?? "Kindly choose a method of donation"}
        </div>
        <div className="methods">
          <div className="recurring" onClick={() => setOpen((v) => !v)}>
            <DonationCard
              title={"Recurring Donation"}
              content={
                "We have made it convenient for you to donate monthly. Become a superhero to the kids, and help bring the joy of chess to underprivileged communities."
              }
              btnTitle={"Donate monthly"}
              backgrounColor={"#F2F4F5"}
              color={"#1163FC"}
              name="Donate in Naira"
            />
          </div>
          <a
            href="https://t.co/ZRQwbYjhzK"
            target="_blank"
            rel="noreferrer"
            className="go"
          >
            <DonationCard
              backgrounColor={"#EBF5F1"}
              color={"#00BA5E"}
              icon={<img src={GoFundIcon} title="fund" alt="go fund" />}
              name="Gofundme"
            />
          </a>
          <div className="naira" onClick={() => openAccount((v) => !v)}>
            <DonationCard
              backgrounColor={"#E7EFFF"}
              color={"#1163FC"}
              icon={<BagIcon />}
              name="Donate in Naira"
            />
          </div>
          {/*
                    <a
                        href="https://t.co/kgUn6GwF7S"
                        target="_blank"
                        rel="noreferrer"
                        className="merch"
                    >
                        <DonationCard
                            backgrounColor={"#F6EAEE"}
                            color={"#ED095B"}
                            icon={<MerchIcon />}
                            name="Merchandise"
                        />
                    </a>
                    {/*
					<a
						href='https://foundation.app/collection/cis'
						target='_blank'
						rel='noreferrer'
						className='nfts'
					>
						<DonationCard
							backgrounColor={'#F7F0FF'}
							color={'#9538FF'}
							icon={<NFTIcon />}
							name='NFTs'
						/>

                    </a>
                   							icon={<img src={BitIcon} title='crypto' alt='crypto' />} 
			  https://donation.lazerpay.finance/?title=chess-in-slums-africa-fundraiser */}

          <a
            href="https://flutterwave.com/pay/chessinslums"
            target="_blank"
            rel="noreferrer"
            className="merch"
          >
            <DonationCard
              backgrounColor={"#FFF0DD"}
              color={"#F7931A"}
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1013.12 241.26"
                >
                  <rect
                    fill="#2a3362"
                    x="328.78"
                    y="82.1"
                    width="16.11"
                    height="82.89"
                  ></rect>{" "}
                  <path
                    fill="#2a3362"
                    d="M407.24,135.58c0,11.44-7.36,16.58-17.16,16.58s-16.35-5.14-16.35-16V106.62H357.62V139.9c0,16.57,10.39,26.26,27.67,26.26,10.86,0,16.93-4,21-8.52h.94l1.4,7.36h14.82V106.62H407.24Z"
                  ></path>{" "}
                  <path
                    fill="#2a3362"
                    d="M565.27,153.44c-11.79,0-18.44-5.37-19.49-13.19h51.13a33.78,33.78,0,0,0,.35-4.91c-.11-21-16-29.89-33-29.89-19.73,0-34.56,11.8-34.56,30.83,0,18.09,14.25,29.88,35.61,29.88,17.87,0,29.77-7.93,32.23-20.08H581.62C579.63,150.87,573.91,153.44,565.27,153.44Zm-1-35.26c10.28,0,16.23,4.55,17.17,11H546.13C547.64,123,553.6,118.18,564.22,118.18Z"
                  ></path>{" "}
                  <path
                    fill="#2a3362"
                    d="M624.63,115h-1l-1.52-8.41H607.47V165h16.11V139.9c0-11.33,6.53-17.63,18.68-17.63a32.5,32.5,0,0,1,6.58.58V106.62h-2.25C635.85,106.62,629.18,108.84,624.63,115Z"
                  ></path>{" "}
                  <polygon
                    fill="#2a3362"
                    points="727.94 146.78 727.01 146.78 713.23 110.24 696.65 110.24 683.11 146.67 682.06 146.67 669.22 106.62 653.22 106.62 672.95 165 690.47 165 704.48 127.75 705.41 127.75 719.19 165 736.82 165 756.55 106.62 740.55 106.62 727.94 146.78"
                  ></polygon>{" "}
                  <path
                    fill="#2a3362"
                    d="M820.67,148V128.46c0-15.88-13.43-23-30.13-23-17.74,0-28.83,8.41-30.35,21H776.3c1.17-5.49,5.84-8.52,14.24-8.52s14,3.15,14,9.57V129l-26.27,2c-12.14.94-21,6.31-21,17.75,0,11.79,10.16,17.39,25.1,17.39,12.06,0,19.41-3.36,23.91-8.43h.8c2.53,5.7,7.66,7.27,13.24,7.27h6.77V153.09h-1.52C822.18,153.09,820.67,151.46,820.67,148Zm-16.12-6.19c0,9.23-11,12.26-20.43,12.26-6.42,0-10.62-1.63-10.62-6.07,0-4,3.62-5.95,9-6.42l22.06-1.63Z"
                  ></path>{" "}
                  <polygon
                    fill="#2a3362"
                    points="880.23 106.62 861.43 148.89 860.38 148.89 841.35 106.62 823.95 106.62 851.15 165 870.42 165 897.5 106.62 880.23 106.62"
                  ></polygon>{" "}
                  <path
                    fill="#2a3362"
                    d="M949,146.08c-2,4.79-7.71,7.36-16.35,7.36-11.79,0-18.44-5.37-19.49-13.19h51.13a33.78,33.78,0,0,0,.35-4.91c-.11-21-16-29.89-33-29.89-19.73,0-34.55,11.8-34.55,30.83,0,18.09,14.24,29.88,35.6,29.88,17.87,0,29.77-7.93,32.23-20.08Zm-17.4-27.9c10.28,0,16.23,4.55,17.17,11H913.47C915,123,920.94,118.18,931.56,118.18Z"
                  ></path>{" "}
                  <path
                    fill="#2a3362"
                    d="M302.6,102.32c0-5.14,3.62-7.35,8.29-7.35a24.17,24.17,0,0,1,6.42.93L320,84.22a36.71,36.71,0,0,0-12.14-2.1c-11.91,0-21.48,6.31-21.48,19.38v5.12h-13.9v12.79h13.9V165H302.6v-45.6h18.16V106.62H302.6Z"
                  ></path>{" "}
                  <path
                    fill="#2a3362"
                    d="M459,90.16H444l-.84,16.46H430.48v12.79h12.38v28.78c0,9.8,5,18,20,18a52.84,52.84,0,0,0,11.56-1.28V152.62a34.29,34.29,0,0,1-6.66.82c-8.05,0-8.75-4.55-8.75-8.06v-26h16V106.62H459Z"
                  ></path>{" "}
                  <path
                    fill="#2a3362"
                    d="M509.59,90.16H494.64l-.84,16.46H481.09v12.79h12.38v28.78c0,9.8,5,18,20,18A52.84,52.84,0,0,0,525,164.88V152.62a34.29,34.29,0,0,1-6.66.82c-8.05,0-8.75-4.55-8.75-8.06v-26h16.05V106.62H509.59Z"
                  ></path>{" "}
                  <path
                    fill="#009a46"
                    d="M48.23,79.89c0-9.37,2.74-17.37,8.49-23.12l10,10C55.59,77.86,65.31,112.34,97,144.06s66.19,41.43,77.31,30.32l10,10c-18.76,18.76-61.49,5.45-97.26-30.33C62.24,129.23,48.23,101.07,48.23,79.89Z"
                  ></path>{" "}
                  <path
                    fill="#ff5805"
                    d="M111.29,193c-9.37,0-17.37-2.74-23.13-8.49l10-10c11.11,11.11,45.59,1.39,77.31-30.32S216.89,78,205.78,66.89l10-10c18.77,18.76,5.45,61.49-30.33,97.26C160.63,179,132.47,193,111.29,193Z"
                  ></path>{" "}
                  <path
                    fill="#f5afcb"
                    d="M188.76,139.84c-6.07-17.48-18.47-36.16-34.92-52.6-35.77-35.78-78.5-49.1-97.26-30.33h0c-1.33,1.34-.18,4.65,2.58,7.41s6.07,3.9,7.4,2.57c11.12-11.11,45.6-1.39,77.31,30.33,15,15,26.18,31.75,31.57,47.25,4.72,13.59,4.26,24.55-1.24,30.05h0c-1.34,1.33-.18,4.65,2.57,7.4s6.07,3.91,7.41,2.57C193.79,174.88,195.42,159,188.76,139.84Z"
                  ></path>{" "}
                  <path
                    fill="#ff9b00"
                    d="M215.76,56.91c-9.63-9.63-25.49-11.26-44.67-4.59-17.47,6.06-36.16,18.47-52.6,34.91C82.72,123,69.4,165.73,88.16,184.5h0c1.34,1.33,4.65.18,7.41-2.57s3.91-6.07,2.57-7.41C87,163.41,96.75,128.93,128.47,97.21c15-15,31.75-26.18,47.25-31.57,13.59-4.71,24.55-4.26,30.06,1.24h0c1.33,1.33,4.65.18,7.4-2.58S217.09,58.24,215.76,56.91Z"
                  ></path>
                </svg>
              }
              name="Donate Online"
            />
          </a>
          <div className="purpose">
            {/* <DonationStat fundPurpose={fundPurpose} fundStat={fundStat} fundUseMethods={fundUseMethods}/> */}
            <img src={cisadonate} className="purpose-img" />
          </div>
        </div>
      </div>
      <AccountModal open={seeAccount} close={() => openAccount((v) => !v)} />
      <MonthlyDonateModal open={open} close={() => setOpen((v) => !v)} />
    </section>
  );
};

export default DonationMethods;
