import styledComponents from "styled-components";
import { Section, Container, AsideCarousel } from "../../Components";

const BlogsDiv = styledComponents.div`
        width: 100%;
        display: grid;
        grid-template-areas : ${(props) =>
            props.showAside ? "'blogs aside'" : "'blogs blogs'"};
        grid-template-columns: 1fr 300px;
        grid-gap: 2.5rem; 
        padding: 5rem 0;
        @media screen and (max-width: 1200px){
            grid-template-areas: "blogs  blogs" "aside aside";
        }
        .main{
            grid-area: blogs;
            .content{
                width: 100%;
            }
        }
        .aside{
            grid-area: aside;
            position: relative;
            .content{
                width: 100%;
                display: flex;
                flex-flow: row wrap;
                justify-content: flex-start;
                -ms-overflow-style: none;
                scrollbar-width: none;
                &::-webkit-scrollbar{
                    display: none
                }
                @media screen and (min-width: 900px){
                    ${(props) =>
                        props.fixed
                            ? "position: fixed; top: 100px; max-width:300px"
                            : ""}
                }
            }
        }
`;

const BlogsSection = ({ children, noAside, aside }) => {

    return (
        <Section id="blogs__section" padding="0 1rem">
            <Container maxWidth="1200px">
                <BlogsDiv showAside={!noAside}>
                    <div className={"main"}>
                        <div className="content">{children}</div>
                    </div>
                    {!noAside && (
                        <div className={`aside`}>
                            <div className="content">
                                {Array.isArray(aside)
                                    ? aside.map((type) => (
                                          <AsideCarousel key={type} type={type} />
                                      ))
                                    : ""}
                            </div>
                        </div>
                    )}
                </BlogsDiv>
            </Container>
        </Section>
    );
};

export default BlogsSection;
