import { useCallback, useContext, useEffect, useState } from "react";
import styledComponents from "styled-components";
import LikeIcon from "../../assets/svgs/LikeIcon";
import CommentIcon from "../../assets/svgs/CommentIcon";
import { formatNumber } from "../../utils/numberFormatter";
import Modal from "../Modal";
import Comment from "../Comment";
import CommentInput from "../CommentInput";
import Button from "../Button";
import { BsFullscreen, BsFullscreenExit } from "react-icons/bs";
import AppContext from "../../context/app.context";
import useImageComments from "../../hooks/useImageComments";
import { toast } from "react-toastify";

const ImageCard = ({
    image,
    thumbnail,
    title,
    likeCount,
    id,
}) => {
    const { likePicture } = useContext(AppContext);
    const [likes, setLikes] = useState(likeCount || 0);
    const [openModal, setOpenModal] = useState(false);
    const [fullscreen, setFullscreen] = useState(false);
    const [imageFull, setFullImage] = useState(false);
    const [name, setName] = useState("");
    const [text, setText] = useState("");
    const comments = useImageComments(id);
    const likeImage = useCallback(async () => {
        try {
            let response = await likePicture(id);
            if (response?.data?.status?.toLowerCase() == "ok") {
                toast.success("Image liked")
                setLikes((v) => v + 1);
            }
        } catch (e) {
            console.log(e);
        }
    }, [id]);

    useEffect(() => {
        const id = setTimeout(() => {
            setFullImage(fullscreen);
        }, 500);
        return () => clearTimeout(id);
    }, [fullscreen]);

    const createComment = useCallback(async () => {
        if (comments.sending) {
            return;
        }
        let response = await comments.create(name, text);
        if (response) {
            setName("");
            setText("");
        }
    }, [comments, text, name]);
    return (
        <ImageCardDiv theme={{ fullscreen }}>
            <OverFlowHidden>
                <ImageDiv
                    background={thumbnail || image}
                    tabIndex={0}
                    title={title}
                    onClick={() => setOpenModal((v) => !v)}
                >
                    <div className="background__wrapper" />
                </ImageDiv>
            </OverFlowHidden>
            <div className="title" onClick={() => setOpenModal((v) => !v)}>
                {title}
            </div>
            <div className="actions">
                <div className="action" onClick={likeImage}>
                    <LikeIcon />{" "}
                    {`${formatNumber(likes)} Like${likes > 1 ? "s" : ""}`}
                </div>
                <div className="action" onClick={() => setOpenModal((v) => !v)}>
                    <CommentIcon />{" "}
                    {`${formatNumber(comments.count)} Comment${
                        comments.count > 0 ? "s" : ""
                    }`}
                </div>
            </div>
            <Modal
                padding="0"
                open={openModal}
                maxWidth="1200px"
                close={() => setOpenModal((v) => !v)}
            >
                <div className="modal__content">
                    <div
                        className="image__section"
                        onDoubleClick={() => setFullscreen((v) => !v)}
                    >
                        <ImageDiv
                            background={image}
                            tabIndex={0}
                            title={title}
                            fullscreen={imageFull}
                        >
                            <div className="background__wrapper" />
                            <div
                                className="fullscreen_toggle"
                                onClick={() => setFullscreen((v) => !v)}
                            >
                                {fullscreen ? (
                                    <BsFullscreenExit />
                                ) : (
                                    <BsFullscreen />
                                )}
                            </div>
                        </ImageDiv>
                    </div>
                    <div className="comment__section">
                        <div className="title">{title}</div>
                        <div className="description">Comments</div>
                        <div className="comments">
                            {comments.read().map((comment) => (
                                <Comment
                                    text={comment.text}
                                    name={comment.name}
                                    key={comment.id}
                                />
                            ))}
                            {comments.hasMore && (
                                <div className="more" onClick={comments.loadMore}>load more</div>
                            )}
                        </div>
                        <div className="comment__actions">
                            <div className="actions">
                                <div className="action" onClick={likeImage}>
                                    <LikeIcon />{" "}
                                    {`${formatNumber(likes)} Like${
                                        likes > 0 ? "s" : ""
                                    }`}
                                </div>
                                <div className="action">
                                    <CommentIcon />{" "}
                                    {`${formatNumber(comments.count)} Comment${
                                        comments.count > 0 ? "s" : ""
                                    }`}
                                </div>
                            </div>
                            <div className="comment__input__div">
                                <div className="input">
                                    <CommentInput
                                        name={name}
                                        comment={text}
                                        onChangeName={(e) =>
                                            setName(e.target.value)
                                        }
                                        onChangeComment={(e) =>
                                            setText(e.target.value)
                                        }
                                    />
                                </div>
                                <Button
                                    text="POST"
                                    className={`${
                                        comments.sending && "loading"
                                    }`}
                                    onClick={createComment}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </ImageCardDiv>
    );
};

const OverFlowHidden = styledComponents.div`
        width:100%;
        height: 300px; 
        overflow: hidden;
`;
const ImageDiv = styledComponents.div`
        width: 100%;
        height:100%;
        position: relative;
        cursor: pointer;
        .background__wrapper{
            width:100%;
            height:100%;
            background : ${(props) =>
                "url('" +
                (props.background ||
                    "https://ik.imagekit.io/w348wgpgc11/assets/tr:q-60,w-500,fo-auto/HomeGallery1_HfR04rcNv.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1644616828321") +
                "')"};
            background-position: center;
            background-size: cover;
            ${(props) => props.fullscreen && `background-size: contain;`}
            background-repeat: no-repeat;
            filter: grayscale(20);
            transition: all 0.5s ease-in-out;
        }
        .fullscreen_toggle{
            position: absolute;
            top: 1rem;
            left: 1rem;
            width: 2rem;
            height: 2rem;
            display: none;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            box-shadow: 0px 5px 14px 3px rgba(0,0,0,0.09);
            background : var(--white);
            ${(props) =>
                props.fullscreen &&
                `
                display: flex;
            `}
            @media screen and (max-width: 600px){
                display: flex;
            }
        }
        &:hover, &:focus {
            .background__wrapper{
                filter: grayscale(0);
            }
            .fullscreen_toggle{
                display: flex;
            }
        }
`;

const ImageCardDiv = styledComponents.div`
        width:100%;
        min-width: 200px;
        max-width: 300px;
        position: relative;
        @media screen and (max-width:600px){
            max-width: unset;
        }
        .title{
            cursor: pointer;
            user-select: none;
            width: 100%;
            padding: 1.3rem 0;
            font-weight: bold;
            font-size: 20px;
            color: #000000;
        }
        .actions{
            display: flex;
            flex-flow: row wrap;
            .action {
                cursor: pointer;
                display: flex;
                align-items:center;
                margin-right: 0.5rem;
                font-size: 14px;
                color: var(--text-gray);
                svg{
                    width: 16px;
                    height: 16px;
                    transition: scale 200ms ease-in-out;
                    margin-right: 0.5rem; 
                }
                &:hover{
                    svg{
                        transform: scale(1.1);
                        fill : var(--primary-two);
                        stroke: var(--primar-two);
                        path{
                            fill : var(--primary-two);
                            stroke: var(--primary-two);
                        }
                    }
                }

            }
        }
        .modal__content{
            width: 100%;
            height: 90vh;
            display: flex;
            overflow:hidden;
            border-radius: 20px;
            .background__wrapper{
                transform: scale(1)
            }
            .image__section{
                flex: auto;
                overflow: hidden;
            }
            .comment__section{
                flex: 0 0 50%;
                overflow: hidden;
                flex-flow: column;
                padding: 2rem;
                display: flex;
                transition: all 500ms ease-in-out, padding 500ms 250ms ease;
                overflow: hidden;
                max-width: 200vw;
                ${(props) =>
                    props.theme?.fullscreen &&
                    `
                    max-width: 0;
                    padding:0;
                `}
                .title{
                    font-size: 30px;
                }
                .description{
                    font-size: 17px;
                    font-weight: 600;
                    padding-bottom: 1.5rem ;
                }
                .comments{
                    cursor: all-scroll;
                    flex: auto;
                    display: flex;
                    overflow-y: auto;
                    flex-flow: column;
                    -ms-overflow-style: none;
                    scrollbar-width: none;
                    &::-webkit-scrollbar{
                        display: none
                    }
                    .more{
                        width: 100%;
                        text-align: center;
                        padding: 1rem 0;
                        color: var(--primary-two);
                        cursor: pointer;
                    }
                }
                .comment__actions{
                    padding: 2rem 0 0;
                    .actions{
                        padding-bottom: 2rem;
                        .action{
                            padding-right: 1rem;
                            svg{
                                width: 20px;
                                height: 20px;
                            }
                        }
                    }
                    .comment__input__div{
                        width:100%;
                        display: flex;
                        align-items: center;
                        .input{
                            padding-right: 1rem;
                            flex: auto;
                        }
                        button{
                            padding: 1rem 2rem;
                            background-color: var(--primary-two);
                            color: var(--white, white);
                            border-radius:  30px;
                            &.loading{
                                opacity: 0.5;
                                cursor: not-allowed;
                            }
                        }
                    }
                }
            }
            @media screen and (max-width: 1000px){
                flex-flow: column;
                .image__section{
                
                }
                .comment__section{
                    max-height: 300px;   
                    max-width: unset;
                    padding: 0.5rem 1rem;
                    ${(props) =>
                        props.theme?.fullscreen &&
                        `
                        max-height: 0;
                        padding:0;
                    `}
                   .title{
                        padding: 0;
                        padding-bottom:0.5rem;
                        font-size: 20px;
                   }
                   .description{
                        padding: 0;
                        font-size: 11px;
                   }
                   .comment__actions{
                        padding: 1rem 0 0.5rem;
                        .actions{
                            padding-bottom: .5rem;
                        }
                        .comment__input__div{
                            .input{
                                padding: 0.5rem;
                                font-size: 1rem;
                            }
                            button{
                                font-size: 11px;
                                padding: 0.5rem 2rem;
                                height: 50px;
                            }
                        }
                   }
                }
            }
        }
`;

export default ImageCard;
