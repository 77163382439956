export const ngnDonationLinks = [
  {
    name: "Donate for 3 months",
    link: "https://flutterwave.com/pay/naira-3monthsdonationplan",
    hoverText:
      "Your credit/debit card will automatically donate your preferred amount to Chess In Slums for 3 months.   ",
  },
  {
    name: "Donate for 6 months",
    link: "https://flutterwave.com/pay/naira-6monthsdonationplan",
    hoverText:
      "Your credit/debit card will automatically donate your preferred amount to Chess In Slums for 6 months.   ",
  },
  {
    name: "Donate for 12 months",
    link: "https://flutterwave.com/pay/naira-12monthsdonationplan",
    hoverText:
      "Your credit/debit card will automatically donate your preferred amount to Chess In Slums for 12 months.   ",
  },
  {
    name: "Donate indefinitely",
    link: "https://flutterwave.com/pay/naira-indefiniteplan-chessinslumsafrica",
    hoverText:
      "Your credit/debit card will automatically donate your preferred amount to Chess In Slums for as long as you want, until you cancel.",
  },
];

export const usdDonationLinks = [
  {
    name: "Donate for 3 months",
    link: "https://flutterwave.com/pay/usd-3monthsdonationplan",
    hoverText:
      "Your credit/debit card will automatically donate your preferred amount to Chess In Slums for 3 months.   ",
  },
  {
    name: "Donate for 6 months",
    link: "https://flutterwave.com/pay/usd-6monthsdonationplan",
    hoverText:
      "Your credit/debit card will automatically donate your preferred amount to Chess In Slums for 6 months.   ",
  },
  {
    name: "Donate for 12 months",
    link: "https://flutterwave.com/pay/usd-12monthsdonationplan",
    hoverText:
      "Your credit/debit card will automatically donate your preferred amount to Chess In Slums for 12 months.   ",
  },
  {
    name: "Donate indefinitely",
    link: "https://flutterwave.com/pay/usd-indefiniteplan-chessinslumsafrica",
    hoverText:
      "Your credit/debit card will automatically donate your preferred amount to Chess In Slums for as long as you want, until you cancel.",
  },
];
export const cadDonationLinks = [
  {
    name: "Donate for 3 months",
    link: "https://flutterwave.com/pay/canadiandollars-3monthsdonationplan",
    hoverText:
      "Your credit/debit card will automatically donate your preferred amount to Chess In Slums for 3 months.   ",
  },
  {
    name: "Donate for 6 months",
    link: "https://flutterwave.com/pay/canadiandollars-6monthsdonationplan",
    hoverText:
      "Your credit/debit card will automatically donate your preferred amount to Chess In Slums for 6 months.   ",
  },
  {
    name: "Donate for 12 months",
    link: "https://flutterwave.com/pay/canadiandollars-12monthsdonationplan",
    hoverText:
      "Your credit/debit card will automatically donate your preferred amount to Chess In Slums for 12 months.   ",
  },
  {
    name: "Donate indefinitely",
    link: "https://flutterwave.com/pay/usd-indefiniteplan-chessinslumsafrica",
    hoverText:
      "Your credit/debit card will automatically donate your preferred amount to Chess In Slums for as long as you want, until you cancel.",
  },
];
export const gbpDonationLinks = [
  {
    name: "Donate for 3 months",
    link: "https://flutterwave.com/pay/pounds-3monthsdonationplan",
    hoverText:
      "Your credit/debit card will automatically donate your preferred amount to Chess In Slums for 3 months.   ",
  },
  {
    name: "Donate for 6 months",
    link: "https://flutterwave.com/pay/pounds-6monthsdonationplan",
    hoverText:
      "Your credit/debit card will automatically donate your preferred amount to Chess In Slums for 6 months.   ",
  },
  {
    name: "Donate for 12 months",
    link: "https://flutterwave.com/pay/pounds-12monthsdonationplan",
    hoverText:
      "Your credit/debit card will automatically donate your preferred amount to Chess In Slums for 12 months.   ",
  },
  {
    name: "Donate indefinitely",
    link: "https://flutterwave.com/pay/pounds-indefiniteplan-chessinslumsafrica",
    hoverText:
      "Your credit/debit card will automatically donate your preferred amount to Chess In Slums for as long as you want, until you cancel.",
  },
];
export const eurDonationLinks = [
  {
    name: "Donate for 3 months",
    link: "https://flutterwave.com/pay/euro-3monthsdonationplan",
    hoverText:
      "Your credit/debit card will automatically donate your preferred amount to Chess In Slums for 3 months.   ",
  },
  {
    name: "Donate for 6 months",
    link: "https://flutterwave.com/pay/euro-6monthsdonationplan",
    hoverText:
      "Your credit/debit card will automatically donate your preferred amount to Chess In Slums for 6 months.   ",
  },
  {
    name: "Donate for 12 months",
    link: "https://flutterwave.com/pay/euro-12monthsdonationplan",
    hoverText:
      "Your credit/debit card will automatically donate your preferred amount to Chess In Slums for 12 months.   ",
  },
  {
    name: "Donate indefinitely",
    link: "https://flutterwave.com/pay/euro-indefiniteplan-chessinslumsafrica",
    hoverText:
      "Your credit/debit card will automatically donate your preferred amount to Chess In Slums for as long as you want, until you cancel.",
  },
];
