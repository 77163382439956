import AboutHeroImage from "../../assets/AboutHeroImage.png";
import {splitIntoParagraphs} from "../../utils";
import "./abouthero.scss";

const AboutHero = ({ visionIntro, visionBody, visionImage }) => {
    let paragraphs = splitIntoParagraphs(visionBody)
    return (
        <section id="about__hero">
            <div className="about__hero__content">
                <h1 className="title">Founder’s Vision</h1>
                <h2 className="sub__title">
                    {visionIntro}
                </h2>
                <div className="image__text">
                    <div className="text__container">
                        {paragraphs.map((pag, id) => (<p key={id}>{pag}</p>))}
                    </div>
                    <div className="image__container">
                        <img src={visionImage + "/tr:q-50,w-466" } alt="founders-bg" />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutHero;
