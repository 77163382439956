import React, { useRef, useState } from "react";

import Modal from "../Modal";
import img from "../../assets/cisabgchess.png";
import img1 from "../../assets/cisanig.png";
import img2 from "../../assets/cisausa.png";
import img3 from "../../assets/cisacad.png";
import img4 from "../../assets/cisagbp.png";
import img5 from "../../assets/cisaeur.png";
import img6 from "../../assets/cisaalert.png";
import img7 from "../../assets/cisaarrowup.png";
import "./monthlyDonateModal.style.scss";
import {
  ngnDonationLinks,
  cadDonationLinks,
  eurDonationLinks,
  gbpDonationLinks,
  usdDonationLinks,
} from "./paymentLinks";
import DonationLink from "../DonationLink";
const MonthlyDonateModal = ({ open, close }) => {
  const [selectedPayment, setSelectedPayment] = useState(null);
  const paymentType = [
    {
      name: "NGN",
      img: img1,
    },
    {
      name: "USD",
      img: img2,
    },
    {
      name: "CAD",
      img: img3,
    },
    {
      name: "GBP",
      img: img4,
    },
    {
      name: "EUR",
      img: img5,
    },
  ];
  const handleRadioChange = (event) => {
    setSelectedPayment(event.target.value); // Update state with selected value
  };

  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleMouseOver = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseOut = () => {
    setHoveredIndex(null);
  };

  const [showText, setShowText] = useState(false);
  console.log(showText);

  const handleMouseOver1 = () => {
    setShowText(true);
  };

  const handleMouseOut1 = () => {
    setShowText(false);
  };

  return (
    <Modal open={open} close={close} iconVisible>
      <div
        style={{
          height: "408px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <img
          src={img}
          alt="chess"
          style={{
            position: "absolute",
            right: 0,
            height: 300,
            marginRight: "70px",
            bottom: 0,
          }}
        />
        <div>
          <div>
            <h1 className="monthly-donate-header-text">Choose currency</h1>
            <p className="monthly-donate-header-paragraph">
              We currently accept recurring donations from 5 secured currencies
            </p>
          </div>
          <div className="monthly-donate-currency">
            {paymentType.map((item, index) => (
              <>
                <label
                  key={index}
                  className="monthly-donate-currency-item"
                  style={
                    selectedPayment === item.name
                      ? {
                          border: "1px solid #00BA5E",
                        }
                      : {}
                  }
                >
                  <img
                    src={item.img}
                    alt="currency"
                    style={{ width: "30px", height: "30px" }}
                    className="monthly-donate-currency-item-img"
                  />
                  <p className="monthly-donate-currency-item-text">
                    {item.name}
                  </p>
                  <input
                    type="radio"
                    name="currency"
                    id={item.name} // Add ID for conditional styling (optional)
                    value={item.name} // Set value to item's name for easy access
                    className="monthly-donate-currency-item-radio"
                    checked={selectedPayment === item.name} // Set checked state based on selection
                    onChange={handleRadioChange}
                  />
                </label>
              </>
            ))}
          </div>
          <p className="choose-plan-text">Choose donation plan</p>
          <div className="choose-plan-text-con" style={{ marginTop: "20px" }}>
            {selectedPayment === "NGN" &&
              ngnDonationLinks.map((item, index) => (
                <DonationLink
                  key={index}
                  item={item}
                  index={index}
                  img1={img6}
                  img2={img7}
                  hoveredIndex={hoveredIndex}
                  handleMouseOut={handleMouseOut}
                  handleMouseOver={handleMouseOver}
                />
              ))}
            {selectedPayment === "USD" &&
              usdDonationLinks.map((item, index) => (
                <DonationLink
                  key={index}
                  item={item}
                  index={index}
                  img1={img6}
                  img2={img7}
                  hoveredIndex={hoveredIndex}
                  handleMouseOut={handleMouseOut}
                  handleMouseOver={handleMouseOver}
                />
              ))}
            {selectedPayment === "CAD" &&
              cadDonationLinks.map((item, index) => (
                <DonationLink
                  key={index}
                  item={item}
                  index={index}
                  img1={img6}
                  img2={img7}
                  hoveredIndex={hoveredIndex}
                  handleMouseOut={handleMouseOut}
                  handleMouseOver={handleMouseOver}
                />
              ))}
            {selectedPayment === "GBP" &&
              gbpDonationLinks.map((item, index) => (
                <DonationLink
                  key={index}
                  item={item}
                  index={index}
                  img1={img6}
                  img2={img7}
                  hoveredIndex={hoveredIndex}
                  handleMouseOut={handleMouseOut}
                  handleMouseOver={handleMouseOver}
                />
              ))}
            {selectedPayment === "EUR" &&
              eurDonationLinks.map((item, index) => (
                <DonationLink
                  key={index}
                  item={item}
                  index={index}
                  img1={img6}
                  img2={img7}
                  hoveredIndex={hoveredIndex}
                  handleMouseOut={handleMouseOut}
                  handleMouseOver={handleMouseOver}
                />
              ))}
          </div>
        </div>
        <div className="donation-bottom-con">
          <p className="donation-bottom-text">Help & support</p>
          <div
            onMouseOver={handleMouseOver1}
            onMouseOut={handleMouseOut1}
            onTouchStart={handleMouseOver1}
            onTouchEnd={handleMouseOut1}
            style={{
              cursor: "pointer",
            }}
          >
            {showText && (
              <div className="donation-link-hover-con1">
                <p className="donation-link-hover-text">
                  Yes, you can cancel your recurring donation at anytime. You
                  can easily do that via the emails you receive from us at every
                  successful donations. We can also assist you should incase you
                  face any challenges.
                </p>
              </div>
            )}
            <p className="donation-bottom-text">
              Can i cancel my recurring donation?
            </p>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default MonthlyDonateModal;
