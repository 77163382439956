import { BsDot } from "react-icons/bs";
import styledComponents from "styled-components";
import { htmlToString } from "../../utils";

const BlogDiv = styledComponents.div`
    cursor: pointer;
    width: 100%;
    display: flex;
    padding: 0.2rem;
    ${(props) => (props.curved ? "max-width:300px;" : "")}
    flex-flow: ${(props) =>
        props.theme.display === "flex" ? "row" : "column"} nowrap;
    .image{
        width: 100%;
        max-width: 300px;
        height: 200px;
        background: url(${(props) => props.image});
        background-position: 50% 50%;
        background-size: cover;
        ${(props) => (props.curved ? "border-radius:9px;" : "")}
        
    }
    .card__content{
        ${(props) =>
            props.theme.display === "flex" ? "flex: auto" : "width: 100%"};
        display: flex;
        flex-flow: column;
        ${(props) =>
            props.theme.display === "flex"
                ? " padding: 1.5rem"
                : "padding 1.5rem 0"};
        .info{
            display: flex;
            align-items: ${({ theme: { display } }) =>
                display === "flex" ? "center" : "flex-start"};
            padding-bottom: 1rem;
            color: var(--text-gray);
            .author, .timestamp, span{
                font-size: 12px;
            }
            span{
                font-weight: bold;
            }
        }
        .title{
            font-style: normal;
            font-weight: 600;
            font-size: 22px;
            line-height: 134%;
            padding-bottom: 1rem;
        }
        .text{
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;
            color: var(--text-gray);
        }
        @media screen and (max-width: 650px){
            padding: 1rem 0;
        }
    }
    @media screen and (max-width: 650px){
        flex-flow: column;
        .image{
            max-width: unset;
        }
    }
    &:hover{
        border-bottom: 1px solid var(--text-gray);
    }
`;
const BlogCardTwo = ({ blog, curved }) => {
    const preview = blog.body ? htmlToString(blog.body).substring(0, 100) + "..." : null;
    return (
        <BlogDiv
        image={blog?.image}
            curved={curved}
            onClick={() => blog.navigate?.()}
        >
            <div className="image" />
            <div className="card__content">
                <div className="info">
                    <div className="author">
                        <span>Author: </span>
                        {blog?.author}
                    </div>
                    <BsDot />
                    <div className="timestamp">{blog?.date}</div>
                </div>
                <div className="title">{blog?.title}</div>
                {preview && <div className="text">{preview}</div>}
            </div>
        </BlogDiv>
    );
};

export default BlogCardTwo;
