import * as React from "react"

const HeartIcon = () => (
  <svg width={54} height={49} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M53.54 15.27C53.54 7.113 46.927.5 38.77.5c-4.95 0-9.32 2.444-12 6.18C24.09 2.944 19.72.5 14.77.5 6.613.5 0 7.111 0 15.27c0 1.156.147 2.275.398 3.354C2.448 31.366 16.616 44.81 26.77 48.5c10.152-3.691 24.32-17.134 26.368-29.874.255-1.08.402-2.199.402-3.356Z"
      fill="#DD2E44"
    />
  </svg>
)

export default HeartIcon

