import styledComponents from "styled-components"

const RadioDiv = styledComponents.div`
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    min-height: 50px;
    align-items: center;
    color: #101010;
    font-family: 'Kumbh Sans';
    .radio{
        input{
            margin-right: .5rem;
        }
        padding-right: 1rem;
    }
`
const RadioInput = ({name, options, onChange}) => {
    return <RadioDiv>
        {
            options?.map((option, key) => (
                <div className="radio" key={key}>
                    <input type="radio" value={option.label} name={name} onChange={(e) => {
                        onChange?.(e.target.value)
                    }} defaultChecked={option.default}/>
                    <label>{option.name}</label>
                </div>
            ))
        }
    </RadioDiv>
}

export default RadioInput
