/* eslint-disable react-hooks/exhaustive-deps */
import {
    Section,
    Container,
    FormGroup,
    Input,
    Button,
    PhoneInput,
    RadioInput,
    AmountInput,
    FormSuccessModal,
} from "../../Components";
import styledComponents from "styled-components";
import { FormLoader } from "../../Components";
import { useAxiosBase } from "../../config/axios.config";
import { debounceFunction } from "../../utils";
import { useState, useMemo, useCallback } from "react";
const options = [
    {
        name: "Yes",
        label: "yes",
    },
    {
        name: "No",
        label: "no",
        default: true,
    },
];
const InquirySection = () => {
    const [modal, setOpenModal] = useState(false);
const [data, setData] = useState({haveAChildInMind:"no", pickAchildForYou: "no"});
    const [{loading}, submit] = useAxiosBase({url:"/chaperones", method:"POST"}, {manual :true})
    
    const clearForm = useCallback((form) => {
        form?.reset?.();
        setData({});
    }, []);

    const onDataChange = useCallback(
        (key, noRef = false) =>
            debounceFunction((e) => {
                setData((data) => {
                    let newData = { ...data };
                    if (noRef) {
                        newData[key] = e;
                    } else {
                        newData[key] = e.target.value;
                    }
                    return newData;
                });
            },300),
        []
    );

    const onEmailChange = useMemo(() => onDataChange("email"), []);
    const onNameChange = useMemo(() => onDataChange("name"), []);
    const onChildNameChange = useMemo(() => onDataChange("nameOfChild"), []);
    const onPhoneChange = useMemo(() => onDataChange("phoneNumber"), []);
    const onCommmitmentChange = useMemo(() => onDataChange("commitment"), []);
    const onChildInMindChange = useMemo(() => onDataChange("haveAChildInMind", true), []);
    const onPickAChildChange = useMemo(() => onDataChange("pickAchildForYou", true), []);
    const handleSubmit = async (e) => {
        e.preventDefault();
        try{
            let response = await submit({data})
            clearForm(e.target);
            setOpenModal(true);
        }catch(e){
            console.log(e.message) 
        }
    };
    return (
        <Section id="chaperone__form" padding="7rem 1rem">
            <Container maxWidth="800px">
                <InquiryFormDiv>
                    <div className="title">Chaperone form</div>
                    <form
                        className="form"
                        method="POST"
                        onSubmit={handleSubmit}
                    >
                        <div className="row">
                            <FormGroup
                                label="Chaperone's name"
                                forName={"name"}
                            >
                                <Input
                                    name="name"
                                    placeholder="john"
                                    onChange={onNameChange}
                                    required
                                />
                            </FormGroup>
                        </div>
                        <div className="row">
                            <FormGroup label="Phone number" forName={"number"}>
                                <PhoneInput
                                    name={"number"}
                                    onChange={onPhoneChange}
                                />
                            </FormGroup>
                        </div>
                        <div className="row">
                            <FormGroup label="Email" forName={"email"}>
                                <Input
                                    name="email"
                                    placeholder="john@doe.com"
                                    type="email"
                                    onChange={onEmailChange}
                                    required
                                />
                            </FormGroup>
                        </div>
                        <div className="row">
                            <FormGroup
                                label="Do you have a child in mind?"
                                forName={"have_child"}
                            >
                                <RadioInput
                                    name="have_child"
                                    options={options}
                                    onChange={onChildInMindChange}
                                />
                            </FormGroup>
                        </div>
                        <div className="row">
                            <FormGroup
                                label="If yes, child’s name"
                                forName={"child_name"}
                            >
                                <Input
                                    name="child_name"
                                    placeholder="john"
                                    onChange={onChildNameChange}
                                />
                            </FormGroup>
                        </div>
                        <div className="row">
                            <FormGroup
                                label="If no, would you like us to pick a child for you?"
                                forName={"pick_child_for_me"}
                            >
                                <RadioInput
                                    name="pick_child_for_me"
                                    onChange={onPickAChildChange}
                                    options={options}
                                />
                            </FormGroup>
                        </div>
                        <div className="row">
                            <div className="sum_text">
                                <div className="text">
                                    I am commiting the sum of
                                </div>
                                <div className="amount">
                                    <AmountInput
                                        name="amount"
                                        onChange={onCommmitmentChange}
                                    />
                                </div>
                                <div className="text">
                                    to sponsor this child to school{" "}
                                </div>
                            </div>
                        </div>
                        <Button text="submit" />
                    </form>
                    <FormSuccessModal
                        title="Thank you for filling out the form"
                        description="Our representative will reach out to you with more information
"
                        open={modal}
                        close={() => setOpenModal((v) => !v)}
                    />
                </InquiryFormDiv>
            </Container>
            {loading && <FormLoader/>}
        </Section>
    );
};

const InquiryFormDiv = styledComponents.div`
    width: 100%;
    padding : 3rem 0;
    .title {
        width: 100%;
        font-family: 'New York Extra Large';
        font-style: normal;
        font-weight: 600;
        font-size: 48px;
        line-height: 110%;
        color: #303030;
        padding-bottom: 2rem;
        @media screen and (max-width: 600px){
            font-size: 30px;
        }
    }
    .description {
        text-align: center;
        color: var(--text-gray);
        font-weight: 500;
        font-size: 22px;
        line-height: 120%;
        padding-bottom: 2rem;
    }
    .form{
        .row{
            padding-bottom: 1.5rem;
            .sum_text{
                display: flex;
                flex-flow: row wrap;
                align-items: center;
                > div{
                    margin-top: 0.5rem;
                }
                .text{
                    color: var(--text-gray);
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 150%;
                }
                .amount{
                    padding: 0 0.5rem;
                    max-width: 152px;
                    flex: auto;
                    input{
                        max-width: 90px;
                    }
                }
            }
        }
        .button{
            padding: 1rem 2rem;
            color: var(--white);
            background: var(--primary-two);
            border-radius: 50px;
        }
    }
`;

export default InquirySection;
