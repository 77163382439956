import { useState, useEffect } from 'react';
import { wrapPromise } from '../utils';
import axios from '../config/axios.config';

const useHomeData = () => {
	const [workStreams, setWorkStreams] = useState({ read: () => {} });
	const [home, setHome] = useState({ read: () => {} });
	const [features, setFeatures] = useState({ read: () => {} });
	useEffect(() => {
		setWorkStreams(wrapPromise(axios.get('/workStreams')));
		setHome(wrapPromise(axios.get('/homes')));
		setFeatures(wrapPromise(axios.get('/features/homePage')));
	}, []);

	return {
		workStreams,
		home,
		features,
	};
};

export default useHomeData;
