import Modal from "../Modal";

import FbIcon from '../../assets/svgs/FbIcon';
import IgIcon from '../../assets/svgs/IgIcon';
import LinkedInIcon from '../../assets/svgs/LinkedInIcon';
import TwitterIcon from '../../assets/svgs/Twitter';
import YoutubeIcon from '../../assets/svgs/Youtube';
import styledComponents from "styled-components";
import TikTokIcon from "../../assets/svgs/TikTok";

const ModalContent = styledComponents.div`
        width:100%;
        min-height: 500px;
        display:flex;
        justify-content: center;
        align-items:center;
        flex-flow: column;
        .title{
            font-family : "New York Extra Large";
            font-size: 56px;
            padding-bottom: 2rem;
            font-weight: 600;
            text-align: center;
            color: var(--title-color);
       }
       .description {
            color: var(--text-gray);
            font-size: 28px;
            text-align: center;
       }
       @media screen and (max-width: 800px){
            .title{
                font-size: 35px;
            }
            .description{
                font-size: 16px;
            }
       }
	    .social_handles {
			width: 100%;
			padding-top: 2rem;
			display: flex;
            align-items: center;
            justify-content: center;
			@media screen and (max-width: 450px) {
				padding-top: 3rem;
			}

			a {
				margin-right: 1rem;

				svg {
					color: #000;
					font-size: 2rem;
				}
            }
		}
`;

const ApplicationClosed = ({ open, close }) => {
    return (
        <Modal open={open} close={close}>
            <ModalContent>
                <div className="title">Sorry, we are currently not accepting applications</div>
                <div className="description">To get updated on when we are taking applications, please follow us via our social media pages</div>
                <div className="social_handles">
                    <a
                        href="https://www.instagram.com/invites/contact/?i=ppt6swjc1x3j&utm_content=3wc4y6a"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <IgIcon />
                    </a>
                    <a
                        href="https://youtube.com/channel/UC62YPHI030ASBLQCOtl3jYg"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <YoutubeIcon />
                    </a>
                    <a
                        href="https://vm.tiktok.com/ZMLj84US7/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <TikTokIcon />
                    </a>
                    <a
                        href="https://www.linkedin.com/company/chess-in-slums-africa"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <LinkedInIcon />
                    </a>
                    <a
                        href="https://www.facebook.com/chessinslums/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <FbIcon />
                    </a>
                    <a
                        href="https://twitter.com/chessinslums?t=f8Xvw1veIcjRFHU4nq3nrg&s=09"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <TwitterIcon />
                    </a>
                </div>
            </ModalContent>
        </Modal>
    );
};

export default ApplicationClosed;
