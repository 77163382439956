import styledComponents from "styled-components";
import {Link} from "react-router-dom";

const OverFlowHidden = styledComponents.div`
        width:100%;
        min-width: 200px;
        height:100%;
        overflow: hidden;
`
const AlbumCardDiv = styledComponents(Link)`
        width: 100%;
        height: 100%;
        display: block;
        position: relative;
        .background__wrapper{
            width:100%;
            height:100%;
            background : ${props =>
                "linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('" + (
                    props.background || "https://ik.imagekit.io/w348wgpgc11/assets/tr:q-10,w-500,fo-auto/HomeGallery1_HfR04rcNv.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1644616828321" )+ "')"};
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            filter: grayscale(20);
            transition: all 0.5s ease-in-out;
        }
            &:hover, &:focus {
                .background__wrapper{
                    transform: scale(1.15);
                    filter: grayscale(0);
                }
            }
        .text__wrapper{
            position: absolute;
            top: 0;
            left:0;
            width:100%;
            height: 100%;
            display: flex;
            cursor: pointer;
            justify-content: center;
            align-items: center;
            .text{
                font-weight: 600;
                font-size: 40px;
                line-height: 120%;
                color: #ffffff;
                max-width: 200px;
                text-align: center;
                @media screen and (max-width: 1000px){
                    font-size: 30px;
                }
            }
        }
`;


const AlbumCard = ({name, path, image, state}) => {
    return (
        <OverFlowHidden>
            <AlbumCardDiv to={path || "#"} tabIndex={0} title={`folder-${name}`} background={image} state={state}>
                <div className="background__wrapper"/>
                <div className="text__wrapper">
                    <div className="text">
                        {name}
                    </div>
                </div>
            </AlbumCardDiv>
        </OverFlowHidden>
    )
}

export default AlbumCard
