import { ContactCard } from "../../Components"
import "./contactUs.style.scss"

const ContactUs = ({data}) => {
    return (
        <section id="contact_us">
            <div className="container">
                <div className="title_div">
                    <div className="title">
                        {data.intro}
                    </div>
                    <div className="description">
                        {data.callToAction}
                    </div>
                </div>
                <div className="cc">
                    <ContactCard data={data}/>
                </div>
            </div>
        </section>
    )
}

export default ContactUs
