import { useEffect, useRef } from 'react';
import styledComponents from 'styled-components';
import { Section, Container } from '../../Components';

const InfoDiv = styledComponents.div`
    margin-top: 10rem;
    margin-bottom: 2rem;
    .title, .description{
        max-width: 1000px;
        margin: 0 auto;
        width: 100%;
    }
    .title{
        font-family: 'New York Extra Large';
        font-style: normal;
        font-weight: 600;
        font-size: 48px;
        line-height: 125%;
        color: #303030;
        margin-bottom: 3rem;
    }
    .description{
        p{
            font-weight: 500;
            font-size: 20px;
            line-height: 170%;
            color: var(--text-gray);
            padding-bottom: 2rem;
        }
    }
    .boxes{
        width: 100%;
        display: flex;
        grid-gap: 1.5rem;
        justify-content: space-between;
        padding: 10rem 0;
        .two{
            transform: translateY(20%);
        }
        .three{
            transform: translateY(-20%);
        }
        .four{
            transform: translateY(10%);
        }
    }
`;

const ScholarshipInfo = ({ title, body, images }) => {
	const ref = useRef();

	useEffect(() => {
		if (body && ref.current) {
			ref.current.innerHTML = body;
		}
	}, [body, ref]);

	return (
		<Section>
			<Container maxWidth='1200px'>
				<InfoDiv>
					<div className='title'>
						{title ??
							'Over the years we have provided over 200 scholarships to kids in slums'}
					</div>
					<div className='description'>
						<p className='p' ref={ref}>
							According to UNICEF, there are currently 10.5million out-of-school
							children in Nigeria, which is the highest globally. Lack of access
							to formal education has increased the rate of crime and poverty in
							Nigeria. Chess in Slums Africa is committed to reaching 1 million
							children in slum communities within five years to end this vicious
							cycle of poverty.
							<br />
							<br />
							You can join a team of change-makers at Chess in Slums Africa by
							partnering with us to raise champions from the slums.
						</p>
					</div>
					<div className='boxes'>
						<Box
							className='one'
							background={`url(${
								images?.[0] ??
								'https://ik.imagekit.io/w348wgpgc11/assets/tr:q-50/Scholarship-One_K2tvmQPYn.png?ik-sdk-version=javascript-1.4.3&updatedAt=1649192652629'
							})`}
						/>
						<Box
							className='two'
							background={`url(${
								images?.[1] ??
								'https://ik.imagekit.io/w348wgpgc11/assets/tr:q-50/Scholarship-Two_8Gh1CvVeA.png?ik-sdk-version=javascript-1.4.3&updatedAt=1649192653261'
							})`}
						/>
						<Box
							className='three'
							background={`url(${
								images?.[2] ??
								'https://ik.imagekit.io/w348wgpgc11/assets/tr:q-50/Scholarship-Three_hT2nADBNE.png?ik-sdk-version=javascript-1.4.3&updatedAt=1649192652988'
							})`}
						/>
						<Box
							className='four'
							background={`url(${
								images?.[3] ??
								'https://ik.imagekit.io/w348wgpgc11/assets/tr:q-50/scholarship-four_eMG6vGQqR.png?ik-sdk-version=javascript-1.4.3&updatedAt=1649192653387'
							})`}
						/>
					</div>
				</InfoDiv>
			</Container>
		</Section>
	);
};

const Box = styledComponents.div`
        width: 280px;
        height: 300px;
        min-width: 280px;
        min-height: 300px;
        background: ${(props) => props.background || '#E2E2E2'};
        border-radius: 5px;
`;

export default ScholarshipInfo;
