const ExitIcon = ({ size }) => {
    return (
        <svg
            width={size || 189}
            height={size || 189}
            viewBox="0 0 189 189"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M86.625 55.125h15.75v55.125h-15.75V55.125Zm0 63h15.75v15.75h-15.75v-15.75Z"
                fill="#CECECE"
            />
            <path
                d="m170.943 57.432-39.375-39.375A7.84 7.84 0 0 0 126 15.75H63a7.844 7.844 0 0 0-5.568 2.307L18.057 57.432A7.843 7.843 0 0 0 15.75 63v63c0 2.095.827 4.095 2.307 5.568l39.375 39.375A7.852 7.852 0 0 0 63 173.25h63a7.843 7.843 0 0 0 5.568-2.307l39.375-39.375A7.85 7.85 0 0 0 173.25 126V63a7.835 7.835 0 0 0-2.307-5.568ZM157.5 122.74l-34.76 34.76H66.26L31.5 122.74V66.26L66.26 31.5h56.48l34.76 34.76v56.48Z"
                fill="#CECECE"
            />
        </svg>
    );
};

export default ExitIcon;
