import { Hero } from "../../Components";

const ScholarshipHero = ({ text, image }) => {
    return (
        <Hero
            id="scholarship__hero"
            title={text ?? "Help us send these kids to school"}
            buttonText={"BECOME A CHAPERONE"}
            sectionId="#chaperone__form"
            background={`linear-gradient( 90.35deg, rgba(34, 34, 34, 0.76) 26.34%,	rgba(57, 57, 57, 0) 99.71% ),
		url(${
            image ||
            "https://ik.imagekit.io/w348wgpgc11/assets/tr:q-40/DonteHero_Zoq3Bd3DK.png?ik-sdk-version=javascript-1.4.3&updatedAt=1644616802936"
        })`}
        />
    );
};

export default ScholarshipHero;
