import { useNavigate } from "react-router-dom";
import { Button } from "../../Components";
import * as ROUTES from "../../routes";
import "./donatebanner.scss";

const DonateBanner = () => {
    const navigate = useNavigate();

    return (
        <section id="donate__banner">
            <div className="donate__banner__content">
                <h1 className="title">Donate to support our cause</h1>
                <div className="buttons">
                    <Button
                        className="button__primary"
                        text="donate"
                        onClick={() => navigate(ROUTES.DONATE)}
                    />
                    <Button
                        className="button__inverted__white"
                        text="partner with us"
                        onClick={() => navigate(ROUTES.PARTNER)}
                    />
                </div>
            </div>
        </section>
    );
};

export default DonateBanner;
