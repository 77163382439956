const VerifiedIcon = () => (
    <svg
        width="1em"
        height="1em"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M10.067.87a2.89 2.89 0 0 0-4.135 0l-.622.638-.89-.01A2.89 2.89 0 0 0 1.496 4.42l.01.89-.636.622a2.89 2.89 0 0 0 0 4.135l.637.622-.01.89a2.89 2.89 0 0 0 2.923 2.924l.89-.01.622.636a2.892 2.892 0 0 0 4.135 0l.622-.637.89.01a2.89 2.89 0 0 0 2.924-2.923l-.01-.89.636-.622a2.892 2.892 0 0 0 0-4.135l-.637-.622.01-.89a2.89 2.89 0 0 0-2.923-2.924l-.89.01-.622-.636Zm.287 5.984-3 3a.5.5 0 0 1-.709 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.794l2.647-2.648a.5.5 0 0 1 .708.708Z"
            fill="#1DA1F2"
        />
    </svg>
);

export default VerifiedIcon;
