import styledComponents from "styled-components";

const BlogsBanner = styledComponents.div`
    width : 100%;
    height: 350px;
    filter: grayscale(20);
    background : url(${props => props.background});
    background-size: cover;
    background-position: 80% 20%;
    @media screen and (max-width: 800px){
        height: 150px;
    }
`


export default BlogsBanner;
