import "./brandsSupport.style.scss";
import Lulogo from "../../assets/lufthansaIconDark.png";
import DanP from "../../assets/DanhyProDark.png";
import ChessaDark from "../../assets/ChessableDark.png";
import Crevatal from "../../assets/CrevatalDark.png";
import Chipper from "../../assets/ChipperDark.png";
import Laze from "../../assets/lazerpayDark.png";
import Venture from "../../assets/Venture gardenDark.png";
import Cafe from "../../assets/cafeOneDark.png";
import ChessKid from "../../assets/chesskidDark.png";
import Flutterwave from "../../assets/flutterwave.jpeg";
import Octafx from "../../assets/octalogo_dark.jpeg";
import ChessDotCom from "../../assets/chess-com.jpg";
import Nexford from "../../assets/nexford.svg";
import Kidsthatcode from "../../assets/kidsthatcode.jpg";
import Majuers from "../../assets/Majuers.jpeg";
import Giftofchess from "../../assets/gift-of-chess.png";
import Mindfulyouth from "../../assets/mindful-youth.jpg";
import Stc from "../../assets/STC-logo.png";
import Internationalwomen from "../../assets/international-women-removebg-preview.png";
import Whanyinnaschool from "../../assets/whanyinna-school-removebg.png";

const BrandsSupport = ({logos}) => {
    return (
        <section id="brand__supports__banner">
            <div className="container">
                <div className="title">
                    <div className="content">
                        <p className="title-text">
                            Brands that support our vision
                        </p>
                    </div>
                </div>
                <div className="b-logo one">
                    <div className="content">
                        <img src={logos[0] || Lulogo} alt="lu-logo" />
                    </div>
                </div>
                <div className="b-logo l1 f">
                    <div className="content">
                        <img src={logos[1] || DanP} alt="dp-logo" />
                    </div>
                </div>
                <div className="b-logo l1 two">
                    <div className="content">
                        <img src={logos[2] || ChessaDark} alt="cd-logo" />
                    </div>
                </div>
                <div className="b-logo l1 three">
                    <div className="content">
                        <img src={logos[3] || Crevatal} alt="cv-logo" />
                    </div>
                </div>
                <div className="b-logo l1 four">
                    <div className="content">
                        <img src={logos[4] || Chipper} alt="ch-logo" />
                    </div>
                </div>
                <div className="b-logo l2 f">
                    <div className="content">
                        <img src={logos[5] || Laze} alt="laz-logo" />
                    </div>
                </div>
                <div className="b-logo l2 two">
                    <div className="content">
                        <img src={logos[6] || Venture} alt="vent-logo" />
                    </div>
                </div>
                <div className="b-logo l2 three">
                    <div className="content">
                        <img src={logos[7] || Cafe}  alt="cafe-logo" />
                    </div>
                </div>
                <div className="b-logo l2 four">
                    <div className="content">
                        <img src={logos[8] || ChessKid} alt="kid-logo" />
                    </div>
                </div>
               
                 <div className="b-logo l1 f">
                    <div className="content">
                        <img src={logos[9] || Flutterwave} alt="flutterwave-logo" />
                    </div>
                </div>
                <div className="b-logo l1 two">
                    <div className="content">
                        <img src={logos[10] || Octafx} alt="Octafx-logo" />
                    </div>
                </div>

                <div className="b-logo l1 three">
                    <div className="content">
                        <img src={logos[11] || ChessDotCom} alt="ChessDotCom-logo" />
                    </div>
                </div>

                <div className="b-logo l1 four">
                    <div className="content">
                        <img src={logos[12] || Nexford} alt="Nexford-logo" />
                    </div>
                </div>

                <div className="b-logo l2 f">
                    <div className="content">
                        <img src={logos[13] || Kidsthatcode} alt="Kidsthatcode-logo" />
                    </div>
                </div>

                <div className="b-logo l2 two">
                    <div className="content">
                        <img src={logos[14] || Majuers} alt="Majuers-logo" />
                    </div>
                </div>
                <div className="b-logo l2 three">
                    <div className="content">
                        <img src={logos[15] || Giftofchess}  alt="Giftofchess-logo" />
                    </div>
                </div>

                <div className="b-logo l2 four">
                    <div className="content">
                        <img src={logos[16] || Mindfulyouth} alt="Mindfulyouth-logo" />
                    </div>
                </div>

                <div className="b-logo l1 f">
                    <div className="content">
                        <img src={logos[17] || Stc} alt="Stc-logo" />
                    </div>
                </div>
                
                <div className="b-logo l1 two">
                    <div className="content">
                        <img src={logos[18] || Internationalwomen} alt="Internationalwomen-logo" />
                    </div>
                </div>
                
                <div className="b-logo l1 three">
                    <div className="content">
                        <img src={logos[19] || Whanyinnaschool} alt="Whanyinnaschool-logo" />
                    </div>
                </div>
                
                
                

                
                <div className="b-logo last">
                    <div className="content">
                    </div>
                </div>
            </div>
        </section>
    );
};

export default BrandsSupport;
