import { Hero } from '../../Components';
const DonateHero = ({ headerIntroText, headerTextBody }) => {
	return (
		<Hero
			id='donate_hero'
			title={headerIntroText ?? 'Help us raise the next kings & queens'}
			buttonText={'Donate Now'}
			sectionId='#donation_methods'
			description={
				headerTextBody ??
				'CISA is on a mission to teach and unlock the potential in every child by using the game of chess as a framework to promote education and raise champions from underserved communities in Africa.'
			}
			background={`linear-gradient(89.85deg, #222222 28.63%, rgba(57, 57, 57, 0) 119.72%),url('https://ik.imagekit.io/w348wgpgc11/assets/tr:q-40/DonteHero_Zoq3Bd3DK.png?ik-sdk-version=javascript-1.4.3&updatedAt=1644616802936')`}
		/>
	);
};

export default DonateHero;
