import { useState, useEffect } from "react";
import { wrapPromise } from "../utils";
import axios from "../config/axios.config";

const useContactData = () => {
    const [contacts, setContacts] = useState({ read: () => {} });
    useEffect(() => {
        setContacts(wrapPromise(axios.get("/contacts")));
    }, []);

    return contacts;
};
export default useContactData;
