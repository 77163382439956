import Input from "../Input";
import { useState, useEffect } from "react";
import styledComponents from "styled-components";

const DateInputDiv = styledComponents.div`
    display: flex;
    input:first-child{
        margin-right: 1rem;
    }
`;

const DateBox = styledComponents(Input)`
    width : 81px;
    text-align: center;
    min-width: 81px;
`;

const DateInput = ({onChangeDay, onChangeMonth}) => {
    const [dd, setDD] = useState("01");
    const [mm, setMM] = useState("01");

    useEffect(() => {
        onChangeDay?.(+dd)
    }, [dd])
    useEffect(() => {
        onChangeMonth?.(+mm)
    },[mm])
    const keyEventHandler = (e, type) => {
        const key = e.key;
        const currentValue = Number(type === "dd" ? dd : mm);
        if (key === "ArrowDown") {
            e.preventDefault();
            if (currentValue === 1){
                return;
            } else {
                if (type === "dd") {
                    setDD(`0${currentValue - 1}`.slice(-2));
                } else {
                    setMM(`0${currentValue - 1}`.slice(-2));
                }
            }
        }
        if (key == "ArrowUp") {
            e.preventDefault();
            if (currentValue === (type === "dd" ? 31 : 12)) {
                return;
            } else {
                if (type === "dd"){
                    setDD(`0${currentValue + 1}`.slice(-2));
                } else {
                    setMM(`0${currentValue + 1}`.slice(-2));
                }
            }
        }
    };
    const handleChangeEvent = (e, type) => {
        if (e.target.value.includes("e")) {
            return;
        }
        let newNumber = Number(e.target.value);
        if (newNumber >= 0) {
            if (type === "dd"){
                if(newNumber > 31){
                    return;    
                }
                setDD(`${newNumber}`.slice(-2));
            } else {
                if(newNumber > 12){
                    return;    
                }
                setMM(`${newNumber}`.slice(-2));
            }
        }
    };
    return (
        <DateInputDiv>
            <DateBox
                required
                value={mm}
                onChange={(e) => handleChangeEvent(e, "mm")}
                onKeyDown={(e) => keyEventHandler(e, "mm")}
                key={2}
            />
            <DateBox
                required
                value={dd}
                onChange={(e) => handleChangeEvent(e, "dd")}
                onKeyDown={(e) => keyEventHandler(e, "dd")}
                key={1}
            />
        </DateInputDiv>
    );
};

export default DateInput;
