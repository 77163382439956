import { useState, useCallback, useEffect } from "react";
import { axiosBase, useAxiosBase } from "../config/axios.config";
import { toast } from "react-toastify";

const useArticlesComments = (id) => {
    const [commnets, setComments] = useState([]);
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [{ loading: sending }, sendComment] = useAxiosBase(
        { url: `/comments`, method: "POST" },
        { manual: true }
    );
    const [pages, setPages] = useState(0);
    const [hasMore, setHasMore] = useState(false);

    const loadComments = useCallback(
        async (page) => {
            try {
                if (loading) return;
                setLoading((v) => !v);
                let response = await axiosBase.get(`/comments/blog/${id}`, {
                    params: {
                        page,
                        limit: 4,
                    },
                });
                setLoading((v) => !v);
                let {
                    docs: commnets,
                    hasNextPage,
                    totalDocs,
                    totalPages,
                } = response.data;
                setComments(commnets);
                setHasMore(hasNextPage);
                setCount(totalDocs);
                setPages(totalPages);
            } catch (e) {
                setComments([]);
                setPages(0)
                setLoading(false);
            }
        },
        [hasMore, id]
    );

    const postComment = useCallback(
        async (name, text) => {
            if (!name || !text) {
                return false;
            }
            try {
                let response = await sendComment({
                    data: { name, text, type: "blog", contentId: id },
                });
                let comment = response.data;
                toast.success("Comment created")
                setComments((v) => {
                    return [comment, ...v];
                });
                setCount((v) => v + 1);
                return true;
            } catch (e) {
                return false;
            }
        },
        [id]
    );

    const loadPage = useCallback((page) => {
        loadComments(page);
    });

    useEffect(() => {
        loadPage(1);
    }, [id]);

    return {
        read: () => commnets,
        loading,
        hasMore,
        count,
        pages,
        create: postComment,
        sending,
        loadPage,
    };
};

export default useArticlesComments;
