import { useState, useRef , useEffect} from "react";
import * as ROUTES from "../../routes.js";
import { NavLink } from "react-router-dom";
import styledComponents from "styled-components";
import ArrowDown from "../../assets/svgs/ArrowDown";
import {clickOutside} from "../../utils";

const BlogNavDiv = styledComponents.div`
    width: 100%;
    height: 64px;
    background-color: black;
    margin-top: 90px;
    @media screen and (max-width: 1000px){
        margin-top: 62px;
    }
    .nav__container{
        margin: 0 auto;
        max-width: 300px;
        width:100%;
        height: 100%;
        position: relative;
        .top{
            width: 100%;
            height: 100%;
            justify-content: center;
            align-items: center;
            display: flex;
            cursor: pointer;
            .title{
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                color: var(--white);
                margin-right: 0.5rem;
            }
            .icon { 
                color: var(--white);
                width : 18px;
                height: 9px;
                display: flex;
                justify-content: center;
                align-items: center;
                transition:all 200ms ease-in-out;
                transform-origin: 50% 50%;
                ${props => props.open ? "transform: rotate(-180deg)" : ""}
            }
        }
        .dropdown{
            z-index: 100;
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            transition: max-height 200ms ease-in-out;
            overflow: hidden;
            max-height: 0;
            ${(props) => {
                return props.open ? " max-height: 200vh;" : "";
            }}
            background-color: black;
            padding: 0;
            display: flex;
            flex-flow: column;
            li{
             .nav{
                    width: 100%;
                    display: block;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 20px;
                    padding: 1.5rem 1rem;
                    color: var(--white);
                    text-align: center;
                    &:hover{
                        background-color: rgba(255,255,255, 0.1);
                    }
                }
            }
        }
    }
`;

const BlogNav = () => {
    const [open, setOpen] = useState(false);
    const Dropref = useRef();
    useEffect(() => {
        if(Dropref.current){
            return clickOutside(window,Dropref.current, () => setOpen(false))
        }
    },[Dropref])

    return (
        <BlogNavDiv open={open} ref={Dropref}>
            <div className="nav__container" onClick={() => setOpen(v => !v)}>
                <div className="top">
                    <div className="title">Departments</div>
                    <div className="icon">
                        <ArrowDown />
                    </div>
                </div>
                <ul className="dropdown">
                    <li >
                        <NavLink
                            to={ROUTES.ALL}
                            className={({ isActive }) =>
                                `nav ${isActive ? "active" : ""}`
                            }
                        >
                            All
                        </NavLink>
                    </li>
                    <li >
                        <NavLink
                            to={ROUTES.BLOG}
                            className={({ isActive }) =>
                                `nav ${isActive ? "active" : ""}`
                            }
                        >
                            Blogs
                        </NavLink>
                    </li>
                    <li >
                        <NavLink
                            to={ROUTES.ANNOUNCEMENTS}
                            className={({ isActive }) =>
                                `nav ${isActive ? "active" : ""}`
                            }
                        >
                            Announcement
                        </NavLink>
                    </li>
                    <li >
                        <NavLink
                            to={ROUTES.FEATURES}
                            className={({ isActive }) =>
                                `nav ${isActive ? "active" : ""}`
                            }
                        >
                            Features
                        </NavLink>
                    </li>
                    <li >
                        <NavLink
                            to={ROUTES.NEWS}
                            className={({ isActive }) =>
                                `nav ${isActive ? "active" : ""}`
                            }
                        >
                            News
                        </NavLink>
                    </li>
                </ul>
            </div>
        </BlogNavDiv>
    );
};

export default BlogNav;
