import { Hero } from '../../Components';

const PartnerHero = ({ headerText, ctaText }) => {
	return (
		<Hero
			id='partner__hero'
			title={headerText ?? 'Let’s join forces to raise the next Kings & Queens'}
			buttonText={ctaText ?? 'PARTNER WITH US'}
			sectionId='#partner__section'
			background={`linear-gradient( 90.35deg, rgba(34, 34, 34, 0.76) 26.34%,	rgba(57, 57, 57, 0) 99.71% ),
		url('https://ik.imagekit.io/w348wgpgc11/assets/tr:q-40/DonteHero_Zoq3Bd3DK.png?ik-sdk-version=javascript-1.4.3&updatedAt=1644616802936')`}
		/>
	);
};

export default PartnerHero;
