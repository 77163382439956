import { createContext } from 'react';
import useAboutData from '../hooks/useAboutData';
import useHomeData from '../hooks/useHomeData';
import useContactData from '../hooks/useContactData';
import useArticles from '../hooks/useArticles';
import useFeatured from '../hooks/useFeatured';
import useAlbums from '../hooks/useAlbums';
import useFeatures from '../hooks/useFeatures';
import useLikesFunctions from '../hooks/useLikesFunctions';
import useScholarshipPageData from '../hooks/useScholarshipPageData';
import useTeamMembers from '../hooks/useTeamMembers';
import useDonation from '../hooks/useDonation';
import usePartnership from '../hooks/usePatnership';
import useVolunteer from '../hooks/useVolunteer';
import useMerchandise from '../hooks/useMerchandise';
import useNFTs from '../hooks/useNFTs';
const AppContext = createContext({});

export const AppProvider = ({ children }) => {
	const { home, workStreams, features: homeFeatures } = useHomeData();
	const { about, teamMembersData } = useAboutData();
	const contacts = useContactData();
	const blogs = useArticles('Blog');
	const featured = useFeatured();
	const news = useArticles('News');
	const announcements = useArticles('Announcement');
	const albums = useAlbums();
	const features = useFeatures();
	const likeFunctions = useLikesFunctions();
	const scholarshipPageData = useScholarshipPageData();
	const detailedTeamMembersData = useTeamMembers();
	const donationPageData = useDonation();
	const partnershipPageData = usePartnership();
	const volunteerPageData = useVolunteer();
	const merchandiseData = useMerchandise();
	const NFTsData = useNFTs();

	return (
		<AppContext.Provider
			value={{
				home,
				homeFeatures,
				contacts,
				workStreams,
				about,
				blogs,
				featured,
				news,
				announcements,
				albums,
				features,
				scholarshipPageData,
				teamMembersData,
				detailedTeamMembersData,
				donationPageData,
				partnershipPageData,
				volunteerPageData,
				NFTsData,
				merchandiseData,
				...likeFunctions,
			}}
		>
			{children}
		</AppContext.Provider>
	);
};

export default AppContext;
