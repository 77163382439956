import { useNavigate } from 'react-router-dom';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-skyblue.min.css';
import { Button } from '../../Components';
import './homehero.scss';

const HomeHero = ({images, descriptions}) => {
	const navigate = useNavigate();

	return (
		<section id='home__hero'>
			<div className='home__hero__content'>
				<Splide
					options={{
						type: 'loop',
						perPage: 1,
						autoplay: true,
						arrows: true,
						pagination: false,
					}}
				>
                    {images?.map((image, id) => (
                         <SplideSlide key={id}>
                            <div
                                className='banner banner__one'
                                style={{
                                    '--bg-url':
                                        `url(${image}/tr:q-40)`,
                                }}
                            >
                                <div className='cover'></div>
                                <img
                                    src={
                                        `${image}/tr:q-40`
                                    }
                                    alt='banner one'
                                />
                                <div className='banner__content'>
                                    <h1 className='title'>
                                        {descriptions[id]}
                                    </h1>
                                    <div className='buttons'>
                                        <Button
                                            className='button__primary'
                                            text='scholarship program'
                                            onClick={() => navigate('/scholarship')}
                                        />
                                        <Button
                                            className='button__inverted__white'
                                            text='partner with us'
                                            onClick={() => navigate('/partner')}
                                        />
                                    </div>
                                </div>
                            </div>
                        </SplideSlide>
                    ))}
				</Splide>
			</div>
		</section>
	);
};

export default HomeHero;
