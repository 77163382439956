import styledComponents from "styled-components";
import { Section, Container, BlogCardTwo } from "../../Components";
import {useNavigate} from "react-router-dom";
import {formatDate} from "../../utils";

const ContentWrapper = styledComponents.div`
    margin-bottom : 6rem;
    .type{
        font-family: 'New York Extra Large';
        font-style: normal;
        font-weight: 800;
        font-size: 38px;
        line-height: 120%;
        color: #303030;
        margin-bottom: 1.5rem;
        @media screen and (max-width: 600px){
            font-size: 25px;
        }
    }
    .content{
        width: 100%;
        display: flex;
        grid-gap: 2rem;
        flex-flow: row wrap;
    }
`;
const OtherContent = ({ data, type }) => {
    const navigate = useNavigate();
    return (
        <Section id={`other-${type}`}>
            <Container maxWidth="1000px">
                <ContentWrapper>
                    <div className="type">Other {type}</div>
                    <div className="content">
                    {data?.slice(0,3).map((blog, id) => (
                        <BlogCardTwo
                            key={id}
                            blog={{
                                image:
                                    blog.headerPixUrl || blog.headerPixSource,
                                author: blog.authors?.[0]
                                    ? `${blog.authors[0].lastName}`
                                    : "",
                                date: formatDate(blog.publishDate),
                                title: blog.title,
                                body: blog.body,
                                slug: blog.slug,
                                navigate: () => navigate('/blog/' + blog.slug, {state : {blog}})
                            }}
                            curved
                        />
                    ))}
                    </div>
                </ContentWrapper>
            </Container>
        </Section>
    );
};

export default OtherContent;
