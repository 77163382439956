import styledComponents from "styled-components";
import { Section, Container } from "../../Components";

const BlogHeroDiv = styledComponents(Container)` 
		border-radius: 12px;
		overflow: hidden;
		min-height: 343px;
        margin: 1rem auto;
        background: url(${props => props.image});
        background-position: 50% 50%;
		background-repeat: no-repeat;
        background-size: cover;
        @media screen and (max-width : 600px){
            min-height: 200px;
        }
`
const BlogBanner = ({image}) => {
	return (
		<Section id='blog__banner'>
            <BlogHeroDiv image={image} maxWidth="1000px">
            </BlogHeroDiv>
		</Section>
	);
};

export default BlogBanner;
