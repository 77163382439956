import styledComponents from "styled-components";
import AlbumCard from "../AlbumCard";

const FolderCardDiv = styledComponents.div`
    width: 100%;
    min-width: 200px;
    min-height: 200px;
    height: 280px;
    @media screen and (min-width: 500px){
        max-width: 300px;  
    }
`;

export default (props) => {
    return (
        <FolderCardDiv>
            <AlbumCard {...props} />
        </FolderCardDiv>
    );
};
