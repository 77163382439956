import { BsDot } from "react-icons/bs";
import styledComponents from "styled-components";
import { htmlToString } from "../../utils";
import Skeleton from "react-loading-skeleton";
const BlogDiv = styledComponents.div`
    cursor: pointer;
    width: 100%;
    display: flex;
    ${(props) => (props.curved ? "max-width:300px;" : "")}
    flex-flow: ${(props) =>
        props.theme.display === "flex" ? "row" : "column"} nowrap;
    .image{
        width: 100%;
        max-width: 300px;
        height: 200px;
        ${(props) => (props.curved ? "border-radius:9px;" : "")}
        
    }
    .card__content{
        ${(props) =>
            props.theme.display === "flex" ? "flex: auto" : "width: 100%"};
        display: flex;
        flex-flow: column;
        ${(props) =>
            props.theme.display === "flex"
                ? " padding: 0.5rem"
                : "padding 0.5rem 0"};
        @media screen and (max-width: 650px){
            padding: 0.5rem 0;
        }
    }
    @media screen and (max-width: 650px){
        flex-flow: column;
        .image{
            max-width: unset;
        }
    }
`;
const BlogCardTwo = ({ curved }) => {
    return (
        <BlogDiv curved={curved}>
            <div className="image">
                <Skeleton height="100%" width="100%" />
            </div>
            <div className="card__content">
                <Skeleton  width="50%"/>
                <Skeleton />
                <Skeleton />
            </div>
        </BlogDiv>
    );
};

export default BlogCardTwo;
