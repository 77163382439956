import styledComponents from "styled-components";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/themes/splide-skyblue.min.css";
import AsideCard from "../AsideCard";
import { BlogCardSkeleton } from "..";
import useCarouselData from "../../hooks/useCarouselData";
import { Suspense, useCallback } from "react";
import { formatDate } from "../../utils";
import { useNavigate } from "react-router-dom";

const AsideCarousel = ({ type }) => {
    const data = useCarouselData(type);
    return (
        <AsideCarouselDiv>
            <div className="carousel__title">{type}</div>
            <Suspense fallback={<BlogCardSkeleton />}>
                <Suspensesful data={data} type={type} />
            </Suspense>
        </AsideCarouselDiv>
    );
};

const Suspensesful = ({ data, type }) => {
    const blogsData = data.read();
    const navigate = useNavigate();

    const redirect = useCallback(
        (slug, blog) => {
            return navigate(`/blog/${type}/${slug}`, { state: { blog } });
        },
        [type]
    );

    return (
        <Splide
            options={{
                type: "loop",
                perPage: 1,
                autoplay: true,
                arrows: false,
            }}
        >
            {blogsData?.length == 0 ? <BlogCardSkeleton /> : ""}
            {blogsData?.slice?.(0, 3).map((blogPost) => (
                <SplideSlide key={blogPost.slug}>
                    <AsideCard
                        title={blogPost.title || blogPost.description}
                        date={formatDate(blogPost.publishDate || blogPost.date)}
                        image={
                            blogPost.headerPixUrl ||
                            blogPost.headerPixSource ||
                            blogPost.captionImage ||
                            blogPost.image
                        }
                        navigate={() =>
                            type == "features"
                                ? window.open(blogPost.url, "_blank")
                                : redirect(blogPost.slug, blogPost)
                        }
                    />
                </SplideSlide>
            ))}
        </Splide>
    );
};
const AsideCarouselDiv = styledComponents.div`
        width: 100%;
        max-width: 300px;
        padding-bottom: 2rem;
        @media screen and (max-width: 1200px){
            margin-right: 2rem;
        }
        .carousel__title{
            font-family: 'New York Extra Large';
            font-style: normal;
            font-weight: 600;
            font-size: 32px;
            line-height: 125%;
            text-transform: capitalize;
            padding-bottom: 1.5rem;
        }
		.splide {
			&__track {
				padding: 0 0 60px;
			}

			.splide__list {
				align-items: stretch;
			}

			.splide__pagination {
				margin-top: 30px;
                justify-content: flex-start;
                padding: 0;
				button.splide__pagination__page {
					background: #c4c4c4;

					&.is-active {
						background: #282828;
					}
				}
			}

			.splide__slide {
                .blog__card {
                    background: #FFFCF5;
                    .details {
                        background: #FFFCF5;
                        .read_button {
                            border: none;
                            background: none;
                            color: var(--primary-two);
                        }
                    }
                }
			}
		}
`;
export default AsideCarousel;
