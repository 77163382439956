const IgIcon = () => (
  <svg  width="25" height="25"  fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.497 8.332A4.177 4.177 0 0 0 8.328 12.5a4.177 4.177 0 0 0 4.169 4.168 4.177 4.177 0 0 0 4.168-4.168 4.177 4.177 0 0 0-4.168-4.168ZM24.999 12.5c0-1.726.015-3.437-.082-5.16-.097-2.001-.553-3.777-2.017-5.24C21.434.632 19.66.18 17.66.081 15.932-.015 14.222.001 12.5.001c-1.726 0-3.437-.016-5.16.081C5.34.18 3.563.636 2.1 2.1.632 3.566.18 5.34.081 7.34-.015 9.066.001 10.777.001 12.5c0 1.723-.016 3.437.081 5.16C.18 19.66.636 21.437 2.1 22.9c1.467 1.467 3.24 1.92 5.241 2.018 1.726.097 3.437.081 5.16.081 1.726 0 3.436.016 5.16-.081 2-.097 3.777-.554 5.24-2.017 1.467-1.467 1.92-3.24 2.017-5.241.1-1.723.082-3.434.082-5.16Zm-12.502 6.414A6.405 6.405 0 0 1 6.083 12.5a6.405 6.405 0 0 1 6.414-6.414A6.405 6.405 0 0 1 18.91 12.5a6.405 6.405 0 0 1-6.413 6.414ZM19.173 7.32c-.829 0-1.498-.669-1.498-1.497 0-.83.67-1.498 1.498-1.498a1.496 1.496 0 0 1 1.384 2.071 1.495 1.495 0 0 1-1.384.925Z"
      fill="#000"
    />
  </svg>
)

export default IgIcon
