import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/themes/splide-skyblue.min.css";
import { useContext } from "react";

import { BlogCard } from "../../Components";
import AppContext from "../../context/app.context";
import { htmlToString } from "../../utils";
import "./newsandhighlights.scss";
import { typedBlog, typedBlog1 } from "../../pages/b/Single/typed-blog";

const NewsAndHighlights = () => {
  const { featured } = useContext(AppContext);
  const { docs } = featured.read();
  const modifiedDocs = [...docs];
  modifiedDocs.unshift(typedBlog1, typedBlog);

  return (
    <section id="news__and__highlights">
      <div className="news__and__highlights__content">
        <h1 className="title">News & Highlights</h1>
        <Splide
          options={{
            type: "loop",
            gap: "12px",
            perPage: 3,
            autoplay: true,
            arrows: false,

            breakpoints: {
              950: {
                perPage: 2,
              },
              600: {
                perPage: 1.5,
              },
              500: {
                perPage: 1,
              },
            },
          }}
        >
          {modifiedDocs.map((blogPost, id) => (
            <SplideSlide key={blogPost.title + id}>
              <BlogCard
                blogId={blogPost.id}
                blogImage={blogPost.headerPixUrl || blogPost.headerPixSource}
                blogTitle={blogPost.title}
                blogExcerpts={
                  htmlToString(blogPost.body).substring(0, 200) + "..."
                }
                blogUrl={blogPost.slug}
                type={blogPost.articleType}
              />
            </SplideSlide>
          ))}
        </Splide>
      </div>
    </section>
  );
};

export default NewsAndHighlights;
