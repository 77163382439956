import "./index.css";

const FormLoader = () => {
    return (
        <div className="form-loader">
            <div className="lds-roller">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
};

export default FormLoader;
