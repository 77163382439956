import { useNavigate } from 'react-router-dom';
import * as ROUTES from "../../routes";
import { Button } from '../../Components';
import './volunteerBanner.style.scss';

const VolunteerBanner = () => {
	const navigate = useNavigate();

	return (
		<section id='volunteer_banner'>
			<div className='container'>
				<div className='title'>Join our team. Sign up to volunteer</div>
				<Button
					className={'volunteer_button'}
					onClick={() => navigate(ROUTES.VOLUNTEER)}
				>
					VOLUNTEER NOW
				</Button>
			</div>
		</section>
	);
};

export default VolunteerBanner;
