import * as React from 'react';

const YoutubeIcon = (props) => (
	<svg
		width={26}
		height={26}
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{...props}
	>
		<g clipPath='url(#a)' fill='#000'>
			<path d='m11.197 15.097 3.606-2.092-3.606-2.102v4.194Z' />
			<path d='M13 0a13 13 0 1 0 0 26 13 13 0 0 0 0-26Zm6.842 15.3a2.59 2.59 0 0 1-2.588 2.59H8.746a2.587 2.587 0 0 1-2.588-2.59v-4.6a2.588 2.588 0 0 1 2.588-2.59h8.508a2.588 2.588 0 0 1 2.588 2.59v4.6Z' />
		</g>
		<defs>
			<clipPath id='a'>
				<path fill='#fff' d='M0 0h26v26H0z' />
			</clipPath>
		</defs>
	</svg>
);

export default YoutubeIcon;
