import "./activitiesCard.style.scss"
const ActivitiesCard = ({image, title, content, background}) => {
    return (
        <div
            className="activites_card"
            style={{
                "--background": background
            }}
        >
            <div className="image_div">
                <img src={image} alt=""/>
            </div>
            <div className="info">
                <div className="act_title">
                    {title}
                </div>
                <div className="content">
                    {content}  
                </div>
            </div>
        </div>
    )
}

export default ActivitiesCard