import styledComponents, { css } from "styled-components";
import { Container, Section, MerchCard } from "../../Components";
import ChessFila from "../../assets/ChessFilaCap.png";
import { useContext, useEffect, useRef } from "react";
import AppContext from "../../context/app.context";

const BuyMerchandise = ({ title, description, background, disabled }) => {
  const ref = useRef();
  const { merchandiseData: suspender } = useContext(AppContext);

  const { image, category, price, name } = suspender?.read() || {};

  useEffect(() => {
    if (description && ref.current) {
      ref.current.innerHTML = description;
    }
  }, [description, ref]);

  return (
    <Section
      background={background || "#FFFCF5"}
      padding="0 1.5rem"
      id="buy__merch"
    >
      <MerchContainer maxWidth="1100px">
        <div className="content">
          <div className="title">{title}</div>
          {description && <div className="description">{description}</div>}

          {disabled ? (
            <BuyButton disabled={disabled}>OUT OF STOCK</BuyButton>
          ) : (
            <BuyButton
              href="https://www.chessinslumstore.com/"
              target="_blank"
              rel="noreferrer"
            >
              BUY NOW
            </BuyButton>
          )}
        </div>
        <MerchCard
          image={image ?? ChessFila}
          name={name ?? "Chess Fila"}
          type={category ?? "Cap"}
          price={
            price
              ? `N${new Intl.NumberFormat("en-us").format(price)}`
              : "N5,000"
          }
          disabled={disabled}
        />
      </MerchContainer>
    </Section>
  );
};

const MerchContainer = styledComponents(Container)`
        display : flex;
        flex-flow : row wrap;
        align-items: center;
        padding: 4rem 0;
        min-height: 659px;
        .content{
            flex: auto;
            .title{
                font-family: 'New York Extra Large';
                font-weight: 600;
                max-width: 555px;
                font-size: 56px;
                padding-bottom: 1rem;
                color : var(--title-color);
            }
            .description{
                font-style: normal;
                font-weight: 500;
                color: var(--text-gray);
                font-size: 20px;
                line-height: 170%;
                max-width: 555px;
                padding-bottom: 2rem;
                p {
                    font-style: normal;
                    font-weight: 500;
                    color: var(--text-gray);
                    font-size: 20px;
                    line-height: 170%;
                    padding-bottom: 2rem;
                }
            }
            @media screen and (max-width : 900px) and (min-width : 400px){
                .title{
                    max-width : 300px
                }
            }
            @media screen and (max-width : 900px){
                padding-bottom: 1rem;
                .title{
                    color : var(--title-color);
                    font-size: 32px;
                }
            }
        }
        @media screen and (max-width: 400px){
            padding: 0; 
            .merch_card{
                width: 100%;
                max-width: none;
            }    
        }
`;

const BuyButton = styledComponents.a`
        display: flex;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        max-width: 317px;
        padding: 18px 0;
        border-radius: 36px;
        font-style: normal;
        font-weight: bold;
        font-size: 1rem;
        line-height: 20px;
        letter-spacing: 0.19em;
        text-transform: uppercase;
        color: #ffffff;
        transition: all 500ms ease-in-out;
        

        ${(props) =>
          props.disabled
            ? css`
                cursor: not-allowed;
                background: var(--disabled);
              `
            : css`
                cursor: pointer;
                background: var(--primary-two);

                &:hover {
                  transform: scale(1.1);
                }
              `}
                
        @media screen and (max-width : 500px){
            display: none;
      }
`;

export default BuyMerchandise;
