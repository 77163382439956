import styledComponents from "styled-components";

const TextArea = styledComponents.textarea`
    border: 1px solid rgba(0, 0, 0, 0.2);
    background: ${props => props.background ||  "#F8F8F8"};
    border-radius: 5px;
    outline:none;
    width: 100%;
    min-height: 150px;
    padding: 1rem;
    ${props => props.background == "var(--white)" ? "border: 1.5px solid #EBEBEB;" : ""}
`

export default TextArea;
