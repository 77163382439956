import FullStar from "../../assets/svgs/FullStar";
import HalfStar from "../../assets/svgs/HalfStar";
import "./merchCard.style.scss";

const MerchCard = ({ image, type, name, price, disabled }) => {
  return (
    <div className="merch_card">
      <div className="showcase">
        {disabled ? (
          <img src={image} alt="name" />
        ) : (
          <a href="https://t.co/kgUn6GwF7S" target="_blank" rel="noreferrer">
            <img src={image} alt="name" />
          </a>
        )}
      </div>
      <div className="info_section">
        <div className="type">{type}</div>
        <div className="item_details">
          <div className="name">{name}</div>
          <div className="price">{price}</div>
        </div>
        <div className="star-rating">
          <FullStar />
          <FullStar />
          <FullStar />
          <FullStar />
          <HalfStar />
        </div>
      </div>
    </div>
  );
};

export default MerchCard;
