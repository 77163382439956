const LinkedInIcon = ({ size, squared }) => {
    if (squared) {
        return (
            <svg
                width={size || 20}
                height={size || 20}
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M18.225.928H1.988C1.21.928.582 1.543.582 2.303v16.319c0 .76.628 1.378 1.406 1.378h16.237c.778 0 1.41-.618 1.41-1.375V2.303c0-.76-.632-1.375-1.41-1.375ZM6.235 17.18H3.406V8.076h2.828v9.104ZM4.82 6.836a1.64 1.64 0 1 1-.004-3.278 1.64 1.64 0 0 1 .004 3.278ZM16.818 17.18h-2.824v-4.425c0-1.054-.019-2.414-1.47-2.414-1.47 0-1.693 1.151-1.693 2.34v4.5H8.01V8.075h2.709V9.32h.037c.376-.715 1.299-1.471 2.672-1.471 2.862 0 3.39 1.885 3.39 4.336v4.995Z"
                    fill="currentColor"
                />
            </svg>
        );
    }
    return (
        <svg
            width={size || 25}
            height={size || 26}
            viewBox="0 0 25 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12.5 0C5.596 0 0 5.82 0 13s5.596 13 12.5 13S25 20.18 25 13 19.404 0 12.5 0ZM9.44 18.388H6.91V9.917H9.44v8.471ZM8.16 8.877c-.8 0-1.317-.59-1.317-1.318 0-.743.533-1.315 1.35-1.315.816 0 1.316.572 1.331 1.315 0 .728-.515 1.318-1.364 1.318Zm10.526 9.511h-2.531v-4.695c0-1.092-.367-1.835-1.283-1.835-.7 0-1.114.503-1.298.986-.068.172-.085.416-.085.658v4.885h-2.532v-5.769a58.79 58.79 0 0 0-.067-2.703h2.2l.115 1.177h.051a2.907 2.907 0 0 1 2.516-1.368c1.665 0 2.914 1.16 2.914 3.655v5.01Z"
                fill="currentColor"
            />
        </svg>
    );
};
export default LinkedInIcon;
