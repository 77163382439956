import { useState, useEffect } from 'react';
import axios from '../config/axios.config';
import { wrapPromise } from '../utils';

const useVolunteer = () => {
	const [volunteerPageData, setVolunteerPageData] = useState(null);
	useEffect(() => {
		setVolunteerPageData(wrapPromise(axios('/volunteerPages')));
	}, []);
	return volunteerPageData;
};

export default useVolunteer;
