import {splitIntoParagraphs} from '../../utils';
import './sustainableimpact.scss';

const SustainableImpact = ({title, description}) => {
    const paragraphs = splitIntoParagraphs(description)
	return (
		<section id='sustainable__impact'>
			<div className='sustainable__impact__content'>
				<h1 className='title'>{title}</h1>
                {paragraphs.map((p,i) => <p key={i}>{p}</p>)}
			</div>
		</section>
	);
};

export default SustainableImpact;
