import styledComponents from "styled-components";
import CommentIcon from "../../assets/svgs/CommentIcon";
import LikeIcon from "../../assets/svgs/LikeIcon";
import ShareIcon from "../../assets/svgs/ShareIcon";
import {
    Section,
    Container,
} from "../../Components";

const BlogActions = ({ like, liked }) => {
    return (
        <Section id="blog__actions">
            <Container maxWidth="1000px">
                <ActionsWrapper>
                    <div className="actions">
                        <button className={`action ${liked && "liked"}`} onClick={() => like?.()}>
                            Like <LikeIcon />
                        </button>
                        {/* <button className="action">
                            share <ShareIcon />
                        </button> */}
                        <a href="#comment__form"className="action">
                            Comment <CommentIcon />
                        </a>
                    </div>
                </ActionsWrapper>
            </Container>
        </Section>
    );
};

const ActionsWrapper = styledComponents.div`
        width: 100%;
        padding: 2rem 0;
        .actions{
            display: flex;
            flex-flow: row wrap;
            grid-gap: 1.5rem;
            .action{
                border: none;
                display: flex;
                color: black;
                align-items: center;
                font-size: 20px;
                padding: 1rem 1.5rem;
                background-color: #F7F7F7;
                border-radius: 50px;
                font-weight: 600;
                cursor:pointer;
                svg{
                    margin-left: 0.5rem; 
                }
                &:hover, &.liked{
                    color : var(--primary-two);
                }
                @media screen and (max-width: 600px){
                    font-size: 12px;
                    padding: 0.5rem 1rem;
                    border-radius: 30px;
                }
            }
        }
        @media screen and (max-width: 600px){
            padding: 1rem 0;
        }
`;
export default BlogActions;
