import {useNavigate} from "react-router-dom";
import { Button } from "../../Components";
import "./homegallery.scss";

const HomeGallery = () => {
	const navigate = useNavigate();
    return (
        <section id="home__gallery">
            <div className="home__gallery__content">
                <h1 className="title">Gallery</h1>
                <div className="image__gallery__grid">
                    <div className="image">
                        <div
                            style={{
                                "--img": `url('https://ik.imagekit.io/w348wgpgc11/assets/tr:q-80,w-300,fo-auto/HomeGallery1_HfR04rcNv.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1644616828321')`,
                            }}
                        >
                            {/* <img
							src={
								'https://ik.imagekit.io/w348wgpgc11/assets/tr:q-80,w-300,fo-auto/HomeGallery1_HfR04rcNv.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1644616828321'
							}
							alt='gallery__one'
						/> */}
                        </div>
                    </div>
                    <div className="image">
                        <div
                            style={{
                                "--img": `url('https://ik.imagekit.io/w348wgpgc11/assets/tr:q-80,w-300,fo-auto/HomeGallery2_HwyG6stE3gDX.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1644616768617')`,
                            }}
                        >
                            {/* <img
							src={
								'https://ik.imagekit.io/w348wgpgc11/assets/tr:q-80,w-300,fo-auto/HomeGallery2_HwyG6stE3gDX.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1644616768617'
							}
							alt='gallery__two'
						/> */}
                        </div>
                    </div>
                    <div className="image">
                        <div
                            style={{
                                "--img": `url('https://ik.imagekit.io/w348wgpgc11/assets/tr:q-80,w-300,fo-auto/HomeGallery3_O62UiY_A-qj.JPG?ik-sdk-version=javascript-1.4.3&updatedAt=1644616768274')`,
                            }}
                        >
                            {/* <img
							src={
								'https://ik.imagekit.io/w348wgpgc11/assets/tr:q-80,w-300,fo-auto/HomeGallery3_O62UiY_A-qj.JPG?ik-sdk-version=javascript-1.4.3&updatedAt=1644616768274'
							}
							alt='gallery__three'
						/> */}
                        </div>
                    </div>
                    <div className="image">
                        <div
                            style={{
                                "--img": `url('https://ik.imagekit.io/w348wgpgc11/assets/tr:q-80,w-300,fo-auto/HomeGallery4_OP9gmavFuO.JPG?ik-sdk-version=javascript-1.4.3&updatedAt=1644616767123')`,
                            }}
                        >
                            {/* <img
							src={
								'https://ik.imagekit.io/w348wgpgc11/assets/tr:q-80,w-300,fo-auto/HomeGallery4_OP9gmavFuO.JPG?ik-sdk-version=javascript-1.4.3&updatedAt=1644616767123'
							}
							alt='gallery__four'
						/> */}
                        </div>
                    </div>
                    <div className="image">
                        <div
                            style={{
                                "--img": `url('https://ik.imagekit.io/w348wgpgc11/assets/tr:q-80,w-300,fo-auto/HomeGallery5_xdr2_s4n2.JPG?ik-sdk-version=javascript-1.4.3&updatedAt=1644616757321')`,
                            }}
                        >
                            {/* <img
							src={
								'https://ik.imagekit.io/w348wgpgc11/assets/tr:q-80,w-300,fo-auto/HomeGallery5_xdr2_s4n2.JPG?ik-sdk-version=javascript-1.4.3&updatedAt=1644616757321'
							}
							alt='gallery__five'
						/> */}
                        </div>
                    </div>
                    <div className="image">
                        <div
                            style={{
                                "--img": `url('https://ik.imagekit.io/w348wgpgc11/assets/tr:q-80,w-300,fo-auto/HomeGallery6_IU272Mlfg.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1644616844063')`,
                            }}
                        >
                            {/* <img
							src={
								'https://ik.imagekit.io/w348wgpgc11/assets/tr:q-80,w-300,fo-auto/HomeGallery6_IU272Mlfg.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1644616844063'
							}
							alt='gallery__six'
						/> */}
                        </div>
                    </div>
                    <div className="image">
                        <div
                            style={{
                                "--img": `url('https://ik.imagekit.io/w348wgpgc11/assets/tr:q-80,w-300,fo-auto/HomeGallery7_rk1Mcqye9.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1644620925378')`,
                            }}
                        >
                            {/* <img
							src={
								'https://ik.imagekit.io/w348wgpgc11/assets/tr:q-80,w-300,fo-auto/HomeGallery7_rk1Mcqye9.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1644620925378'
							}
							alt='gallery__seven'
						/> */}
                        </div>
                    </div>
                    <div className="more__image">            
                        <Button
                            className='button__primary'
                            text='view more'
                        onClick={() => navigate('/gallery', {state: {top: true}})}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HomeGallery;
