import { Section, Container, Button } from '../../Components';
import { useNavigate } from 'react-router-dom';
import styledComponents from 'styled-components';
import * as ROUTES from '../../routes';
import SubTeamSection from './SubTeamSection';

const TeamSection = ({ teamMembersData, noTitle, goToTeams }) => {
	const navigate = useNavigate();
	const formattedTeamMembersData = new Map();

	teamMembersData?.map((member) => {
		if (formattedTeamMembersData.has(member.type)) {
			formattedTeamMembersData.set(member.type, [
				...formattedTeamMembersData.get(member.type),
				member,
			]);
		} else {
			formattedTeamMembersData.set(member.type, [member]);
		}
	});

	return (
		<Section padding='3rem 1rem'>
			<Container maxWidth='1200px'>
				<TeamWrapper>
					{!noTitle && <div className='title'>Meet our team</div>}
					{Array.from(formattedTeamMembersData.keys()).map(
						(teamType, index) => {
							return (
								<SubTeamSection
									key={index}
									subteamData={formattedTeamMembersData.get(teamType)}
									subteamTitle={teamType}
								/>
							);
						}
					)}

					{goToTeams && (
						<Button
							className='button__primary'
							onClick={() => navigate(ROUTES.TEAMS)}
						>
							VIEW MORE TEAM
						</Button>
					)}
				</TeamWrapper>
			</Container>
		</Section>
	);
};

const TeamWrapper = styledComponents.div`
    width: 100%;
    .title{
        font-family: 'New York Extra Large';
        font-style: normal;
        font-weight: 600;
        font-size: 56px;
        line-height: 130%;
        text-align: center;
        color: var(--title-color);
        padding: 2rem 0;
    }
    .sub_title{
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 120%;
        text-align: center;
        color: #1D2130;
        padding-bottom: 1.5rem;
    }
    .teams{
        display: flex;
        flex-flow: row wrap;
        grid-gap: 50px 1rem;
        justify-content: center;
        padding-bottom: 5.5rem;
    }
    .button__primary{
        padding: 0 50px;
        height: 56px;
        border-radius: 43px;
        margin: 0 auto;
    }
`;

export default TeamSection;
