import styledComponents from "styled-components";
import { BsDot } from "react-icons/bs";
import TwHeartIcon from "../../assets/svgs/TwHeartIcon";
import DownloadsIcon from "../../assets/svgs/DownloadsIcon";
import RetweetIcon from "../../assets/svgs/RetweetIcon";
import ReplyIcon from "../../assets/svgs/ReplyIcon";
import VerifiedIcon from "../../assets/svgs/VerifiedIcon";
import LikeIcon from "../../assets/svgs/LikeIcon";

const TweetCard = ({image, username, handle, body}) => {
    return (
        <TweetCardDiv>
            <div className="liked__by">
                <div className="icon"><LikeIcon size={14}/></div>
                <div className="name">Tunde Onakoya liked</div>
            </div>
            <div className="tw__content">
                <div className="image_section">
                    <div className="image_div">
                        <img
                            src={image || "https://ik.imagekit.io/w348wgpgc11/assets/Ellipse_91Photo_NnPGCfloD.png?ik-sdk-version=javascript-1.4.3&updatedAt=1649312930381"}
                            alt="user profile"
                        />
                    </div>
                </div>
                <div className="tw__section">
                    <div className="tw__author">
                        <div className="name"> {username}</div>
                        <div className="check">
                            {" "}
                            <VerifiedIcon />{" "}
                        </div>
                        <div className="username">@{handle || "chessinslums"}</div>
                        <div className="check">
                            <BsDot/>
                        </div>
                        <div className="time">1 min</div>
                    </div>
                    <div className="tw__body">
                        {body}
                    </div>
                    <div className="tw__action">
                        <div className="action">
                            <ReplyIcon /> 4
                        </div>
                        <div className="action">
                            <RetweetIcon /> 1
                        </div>
                        <div className="action">
                            <TwHeartIcon /> 2
                        </div>
                        <div className="action">
                            <DownloadsIcon /> 16
                        </div>
                    </div>
                </div>
            </div>
        </TweetCardDiv>
    );
};

const TweetCardDiv = styledComponents.div`
    width: 490px;
    min-height: 100px;
    min-width: 490px;
    margin: 0 1rem ;
    padding: 1.5rem;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(119, 119, 119, 0.2);
    border-radius: 10px;
    display: flex;
    flex-flow: column;
    grid-gap: 6px;
    .liked__by{
        height: 20px;
        padding-left: 2rem;
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        color: #5B7083;
        .name{
            padding-left: 0.4rem;
            font-style: normal;
            font-weight: 700;
            font-size: 12px;   
            color: #5B7083;
        }
        .icon{
            svg{
                fill: currentColor;
            }
        }
    } 
    .tw__content{
        flex:auto;
        width: 100%;
        display: flex;
        grid-gap: 10px;
        .image_section{
            width: 40px;
        }
        .tw__section{
            flex: auto;
            height: 100%;
            display: flex;
            flex-flow: column;
            .tw__author {
                height: 20px;
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                .name{
                    font-style: normal;
                    font-weight: 700;
                    font-size: 14px;   
                    color: #0F1419;
                    padding-right: 0.4rem;
                }
                .username, .time{
                    font-size: 14px;
                    padding-left: 0.4rem;
                    color: var(--text-gray)
                }
                .time{
                    padding: 0;
                }
                .check{
                    height: 100%;
                    display: flex;
                    align-items: center;
                }
            } 
            .tw__body{
                flex: auto;
                font-weight: 500;
                font-size: 16px;
                line-height: 170%;
                color : var(--text-gray);
                padding: 0.5rem 0;
                margin-bottom: 0.5rem;
            }
            .tw__action{
                height: 30px;
                display: flex;
                max-width: 350px;
                justify-content: space-between;
                .action{
                    svg{
                        margin-right: .5rem;
                    }
                    display: flex;
                    align-items: center;
                    color: #5B7083;
                }
            }
        }
        .image_section{
            .image_div{
                width: 40px;
                height : 40px;
                min-width: 40px;
                mih-height: 40px;
                border-radius: 50%;
                overflow: hidden;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

`;

export default TweetCard;
