import { AiFillHeart } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import NewsletterModal from "../NewsletterModal";
import { useState, useContext } from "react";
import LogoLarge from "../../assets/LogoLarge.png";
import AppContext from "../../context/app.context";
import "./footer.scss";
const Footer = () => {
  const [openNewsModal, setOpenNewsModal] = useState(false);
  const { contacts: suspender } = useContext(AppContext);
  const data = suspender?.read() || {};
  return (
    <footer id="footer">
      <div className="footer__content">
        <div className="flex__wrapper">
          <img src={LogoLarge} alt="chessinslums-logo" />

          <div className="flex__wrapper__right">
            <div className="links__wrapper__copyright">
              <div className="footer__links__wrapper">
                <section className="footer__links">
                  <p className="footer__links__title">About Us</p>
                  <ul>
                    <li className="footer__link">
                      <NavLink to="/about">Team</NavLink>
                    </li>
                    <li className="footer__link">
                      <NavLink to="/about">The Initiative</NavLink>
                    </li>
                    <li className="footer__link">
                      <NavLink to="/contact">Contact</NavLink>
                    </li>
                    <li className="footer__link">
                      <a
                        href="https://youtube.com/channel/UC62YPHI030ASBLQCOtl3jYg"
                        target="_blank"
                        rel="noreferrer"
                      >
                        YouTube
                      </a>
                    </li>
                  </ul>
                </section>

                <section className="footer__links">
                  <p className="footer__links__title">More</p>
                  <ul>
                    <li className="footer__link">
                      {/* <a
												href='https://t.co/kgUn6GwF7S'
												target='_blank'
												rel='noreferrer'
											>
												Merchandise
											</a> */}

                      <span>Merchandise</span>
                    </li>
                    <li className="footer__link">
                      <NavLink to="/donate">Donate</NavLink>
                    </li>
                    <li className="footer__link">
                      <a href="/#news__and__highlights">News</a>
                    </li>
                    <li className="footer__link">
                      <NavLink to="/volunteer">Volunteer</NavLink>
                    </li>
                  </ul>
                </section>

                <section className="footer__links">
                  <p className="footer__links__title">Contact Us</p>
                  <ul>
                    <li className="footer__link">
                      <a href={data.facebook} target="_blank" rel="noreferrer">
                        Facebook
                      </a>
                    </li>
                    <li className="footer__link">
                      <a href={data.instagram} target="_blank" rel="noreferrer">
                        Instagram
                      </a>
                    </li>
                    <li className="footer__link">
                      <a href={data.twitter} target="_blank" rel="noreferrer">
                        Twitter
                      </a>
                    </li>
                    <li className="footer__link">
                      <a href={data.instagram} target="_blank" rel="noreferrer">
                        LinkedIn
                      </a>
                    </li>
                  </ul>
                </section>
              </div>

              <p className="copyright__text">
                Crevated with &nbsp; <AiFillHeart /> &nbsp; by &nbsp;
                <a
                  href="https://crevatal.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Crevatal
                </a>
              </p>
            </div>

            <div className="subscribe__newsletter">
              <p className="title">Stay updated on our moves round the board</p>
              <p className="description">Subscribe to our newsletter</p>
              <button onClick={() => setOpenNewsModal((v) => !v)}>
                {" "}
                SUBSCRIBE{" "}
              </button>
            </div>
          </div>
        </div>
      </div>
      <NewsletterModal
        open={openNewsModal}
        close={() => setOpenNewsModal((v) => !v)}
      />
    </footer>
  );
};

export default Footer;
