import AppContext from "../../context/app.context";
import { useContext } from "react";
import "./featuredbanner.scss";

const FeaturedBanner = () => {
    const { homeFeatures: suspender } = useContext(AppContext);
    const data = suspender?.read() || {};
    const { docs } = data;
    return (
        <div id="featured__banner">
            <p className="title">We are featured on</p>
            {docs?.map((link, i) => (
                <a href={link.url} target="_blank" rel="noreferrer" key={i}>
                    <img src={link.image} alt="BBC" />
                </a>
            ))}
        </div>
    );
};

export default FeaturedBanner;
