import {
	Section,
	Container,
	FormWrapper,
	FormGroup,
	Input,
	PhoneInput,
	RadioInput,
	Button,
	TextArea,
	FormSuccessModal,
} from '../../Components';
import styledComponents from 'styled-components';
import { useState, useMemo, useCallback } from 'react';
import { debounceFunction } from '../../utils';
import { FormLoader } from '../../Components';
import { toast } from 'react-toastify';
import { useAxiosBase } from '../../config/axios.config';

const StatusOptions = [
	{
		name: 'Individual',
		label: 'individual',
		default: true,
	},
	{
		name: 'Organization',
		label: 'organization',
	},
];

const PartnerForm = ({ introText }) => {
	const [openModal, setOpenModal] = useState(false);
	const [data, setData] = useState({ partnerStatus: 'individual' });
	const [{ loading }, submit] = useAxiosBase(
		{ url: '/partners', method: 'POST' },
		{ manual: true }
	);
	const clearForm = useCallback((form) => {
		form?.reset?.();
		setData({});
	}, []);

	const onDataChange = useCallback(
		(key, noRef = false) =>
			debounceFunction((e) => {
				setData((data) => {
					let newData = { ...data };
					if (noRef) {
						newData[key] = e;
					} else {
						newData[key] = e.target.value;
					}
					return newData;
				});
			}),
		[]
	);

	const onEmailChange = useMemo(() => onDataChange('email'), []);
	const onFirstNameChange = useMemo(() => onDataChange('firstName'), []);
	const onLastNameChange = useMemo(() => onDataChange('lastName'), []);
	const onPhoneChange = useMemo(() => onDataChange('phoneNumber'), []);
	const onStatusChange = useMemo(() => onDataChange('partnerStatus', true), []);
	const onDetailsChange = useMemo(() => onDataChange('partnershipDetail'), []);

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			let response = await submit({ data });
			clearForm(e.target);
			setOpenModal(true);
		} catch (e) {
			const data = e.response?.data;
			const message = data.message;
			if (message.email) {
				toast.error(message.email);
			}
		}
	};
	return (
		<PartnerSection id='partner__section'>
			<Container maxWidth={'1000px'}>
				<form onSubmit={handleSubmit} method='POST'>
					<FormWrapperP>
						<div className='form_title'>
							{introText ?? 'Thank you for choosing to partner with us'}
						</div>
						<div className='form'>
							<div className='description'>
								Kindly fill the form below to get started
							</div>
							<div className='row'>
								<div className='col'>
									<FormGroup label={'Firt Name'} forName='first_name'>
										<Input
											required
											placeholder='John'
											name='first_name'
											onChange={onFirstNameChange}
										/>
									</FormGroup>
								</div>
								<div className='col'>
									<FormGroup label={'Last Name'} forName='last_name'>
										<Input
											required
											placeholder='Doe'
											name='last_name'
											onChange={onLastNameChange}
										/>
									</FormGroup>
								</div>
							</div>
							<div className='row'>
								<div className='col'>
									<FormGroup label={'Status'} forName='status'>
										<RadioInput
											name='Status'
											options={StatusOptions}
											onChange={onStatusChange}
										/>
									</FormGroup>
								</div>
								<div className='col'>
									<FormGroup label={'Phone number'} forName='number'>
										<PhoneInput name='phone' onChange={onPhoneChange} />
									</FormGroup>
								</div>
							</div>
							<div className='row'>
								<FormGroup label={'Email'} forName='email'>
									<Input
										required
										placeholder='john@doe.com'
										name='email'
										type='email'
										onChange={onEmailChange}
									/>
								</FormGroup>
							</div>
							<div className='row'>
								<FormGroup
									label={'Partners Details'}
									forName='partners_details'
								>
									<TextArea
										name='partners_details'
										required
										onChange={onDetailsChange}
									/>
								</FormGroup>
							</div>
							<div className='row'></div>
							<div className='row'>
								<Button text='Submit' />
							</div>
						</div>
					</FormWrapperP>
				</form>
				<FormSuccessModal
					open={openModal}
					close={() => setOpenModal((v) => !v)}
				/>
			</Container>
			{loading && <FormLoader />}
		</PartnerSection>
	);
};
const PartnerSection = styledComponents(Section)`
    padding: 9rem 1rem;
`;

const FormWrapperP = styledComponents(FormWrapper)`
    padding: 0;
    .form_title{
        margin-bottom: 1rem;
        max-width: 635px;
    }
    .form{
        .description{            
            font-family: 'Kumbh Sans';
            font-style: normal;
            font-weight: 500;
            font-size: 22px;
            line-height: 120%;
            color: #101010;
            margin-top: 3rem;
        }
        .row{
            margin-top: 1.5rem;
            .col{
                max-width: 500px;
                min-width: 400px;
                @media screen and (max-width: 830px){
                    min-width: unset; 
                }
             }
        
            button{
                padding: 1rem 3.5rem;
            }
        }
    }
`;
export default PartnerForm;
