import styledComponents from "styled-components";
import HeartIcon from "../../assets/svgs/HeartIcon";
import { Section, TweetCard } from "../../Components";
import Marquee from "react-fast-marquee";

const TWEETS = [
    {
        handle: "HenshawKate",
        image: "https://ik.imagekit.io/w348wgpgc11/assets/Ellipse_91Kate_LBoI_iKsus.png?ik-sdk-version=javascript-1.4.3&updatedAt=1653837364894",
        username: "Kate Henshaw",
        body: "Little drops make a mighty ocean. 🙏 Again, I applaud you Tunde... You will never tire and great doors will be opened to you because you cared for others other than yourself..",
    },
    {
        handle: "ChetoManji",
        image: "https://ik.imagekit.io/w348wgpgc11/assets/Ellipse_91Manji_Ee6PdkPX-T.png?ik-sdk-version=javascript-1.4.3&updatedAt=1653837364909",
        username: "Manji Cheto",
        body: "What a moving story. Well done t@Tunde_OD and team. I suspect we’ll hear more amazing stories about Ferdinand in the not-so-distant future 👏🏾👏🏾👏🏾👏🏾",
    },
    {
        handle: "LateefSaka",
        image: "https://ik.imagekit.io/w348wgpgc11/assets/Ellipse_91Lateef_D6OGgyVug.png?ik-sdk-version=javascript-1.4.3&updatedAt=1653837364900",
        username: "Lateef",
        body: "This most likely the most beautiful thing I’ve seen on Twitter in a very long time. I love how these children and young men have been shown and their stories shared without poverty or any negative preconceived bias being at the forefront.",
    },
    {
        handle: "mrmacaronii",
        image: "https://ik.imagekit.io/w348wgpgc11/assets/Ellipse_91Macaroni_yB6YeaPi3E.png?ik-sdk-version=javascript-1.4.3&updatedAt=1653837364897",
        username: "MrMacaroni",
        body: "Improving lives, developing the community and creating an enabling environment where every Nigerian Youth can succeed. This is what leadership should be about. Thank you @Tunde_OD This is absolutely inspiring.",
    },
    {
        handle: "ChessKidcom",
        image: "https://ik.imagekit.io/w348wgpgc11/assets/Ellipse_91ChessKid_nyweAI8ii.png?ik-sdk-version=javascript-1.4.3&updatedAt=1653837364901",
        username: "ChessKid.com",
        body: "🌍 The Canadian High Commission visited these awesome kids at the Oshodi Underbridge! ♥️  We're proud to support @chessinslumsand their fantastic work to uplift communities through chess #ChessInSlumsAfrica #ChessKid",
    },
    {
        handle: "Evra",
        image: "https://ik.imagekit.io/w348wgpgc11/assets/Ellipse_91Patrice_4f6Gp8okO.png?ik-sdk-version=javascript-1.4.3&updatedAt=1653837364900",
        username: "Patrice Evra",
        body: "Checkmate ♟ Evra bus 🚌 stop #Nigeria ! 🔥🇳🇬 I'm soo impressed by those genius kids @Tunde_ 🙏 🏽 @burnaboy #ilovethisgame #positive4evra",
    },
    {
        handle: "chippercashapp",
        image: "https://ik.imagekit.io/w348wgpgc11/assets/Ellipse_91ChipperCash_CAMUJXpAB.png?ik-sdk-version=javascript-1.4.3&updatedAt=1653837364898",
        username: "Chipper Cash",
        body: "It’s amazing what kids can do when they’re empowered! Thanks to @Tunde_OD for letting us in on the amazing work he has been doing with @chessinslums Africa. Together, we visited the biggest floating slum riding in canoes where we saw the amount of work done by Tunde and his team.",
    },
    {
        handle: "DamiOyedele",
        image: "https://ik.imagekit.io/w348wgpgc11/assets/Ellipse_91Damilola_7dKhiM72t.png?ik-sdk-version=javascript-1.4.3&updatedAt=1653837364896",
        username: "Damilola Oyedele",
        body: "Took everything in me not to bawl while reading this thread. This initiative is writing the future of Nigeria. Please read and support❤️",
    },
    {
        handle: "NetflixNaija",
        image: "https://ik.imagekit.io/w348wgpgc11/assets/Ellipse_91NetflixNija_BSUNhI6hs.png?ik-sdk-version=javascript-1.4.3&updatedAt=1653837364906",
        username: "Netflix Nigeria",
        body: "It is indeed possible to do great things from a small place. Well done @chessinslums 👑♟",
    },
    {
        handle: "SavvyRinu",
        image: "https://ik.imagekit.io/w348wgpgc11/assets/Ellipse_91Rinu_ij5ibpbpFQ.png?ik-sdk-version=javascript-1.4.3&updatedAt=1653837364905",
        username: "Rinu Oduala 🔥🔫",
        body: "This is huge. Thank you for taking on these grassroots initiatives. Thank you Tunde 🙏🏾",
    },
    {
        handle: "ParisHilton",
        image: "https://ik.imagekit.io/w348wgpgc11/assets/Ellipse_91ParisHilton_yq9VPD8zq.png?ik-sdk-version=javascript-1.4.3&updatedAt=1653837364895",
        username: "ParisHilton.eth",
        body: "My pleasure.😇 Thank you for what you are doing to help these children. May God always bless you 🙏 ❤️ ",
    },
];
const WallOfLove = () => {
    return (
        <Section id={"wall__of__love"} padding="0 0 4em" maxWidth="1800px">
            <div className="container">
                <Title>
                    <span>Our wall of love</span>
                    <HeartIcon />
                </Title>
                <Tweets>
                    <Marquee
                        gradient={false}
                        pauseOnClick={true}
                        style={{ padding: "1rem" }}
                        direction="right"
                        pauseOnHover
                        delay="5"
                    >
                        {TWEETS.slice(0, 6).map((tweet) => (
                            <TweetCard
                                image={tweet.image}
                                username={tweet.username}
                                handle={tweet.handle}
                                body={tweet.body}
                                key={tweet.username}
                            />
                        ))}
                    </Marquee>
                </Tweets>
                <Tweets style={{ marginBottom: "2rem" }}>
                    <Marquee
                        gradient={false}
                        style={{ padding: "1rem" }}
                        direction="right"
                        pauseOnHover
                        pauseOnClick
                    >
                        {TWEETS.slice(6).map((tweet) => (
                            <TweetCard
                                image={tweet.image}
                                username={tweet.username}
                                handle={tweet.handle}
                                body={tweet.body}
                                key={tweet.username}
                            />
                        ))}
                    </Marquee>
                </Tweets>
            </div>
        </Section>
    );
};

const Title = styledComponents.div`
        margin : 0 auto;
        maxWidth : 500px;
        display : flex;
        justify-content: center;
        align-items: center;
        color: var(--title-color);
        padding : 1rem 0.5rem;
        span { 
            font-family: "New YOrk Extra Large";
            font-weight: 600;
            font-size: 56px;
            margin-right: 1rem;
        }
        @media screen and (max-width : 900px){
            span{
                font-size: 25px;
                margin: 0;
            } 
            svg{
                display: none;
            }
        }
`;

const Tweets = styledComponents.div`
        width : 100%;
`;
export default WallOfLove;
