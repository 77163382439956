import {splitIntoParagraphs} from '../../utils';
import './ouridentity.scss';


const OurIdentity = ({identityText1, identityText2, identityImage}) => {
    let paragraphs = splitIntoParagraphs(identityText2)
	return (
		<section id='our__identity'>
			<div className='our__identity__content'>
				<h1 className='title'>Our Identity</h1>
				<p className='follow__up__text'>
                    {identityText1}
				</p>
				<div className='image__text'>
                    <img src={identityImage + "/tr:q-80,w-392"} alt='our-identity' />
					<div className='text__container'>
                        {paragraphs.map((pag, id) => (<p key={id}>{pag}</p>))}
					</div>
				</div>
			</div>
		</section>
	);
};

export default OurIdentity;
