import { useEffect, useRef } from "react";
import Skeleton from "react-loading-skeleton";
import styledComponents from "styled-components";
import { Container, Section } from "../../Components";

const ContentDiv = styledComponents.div`
    padding: 2.5rem 0;
    font-weight: normal;
    font-size: 1.25rem;
    line-height: 150%;
    color: #5C5C5C;
    > * {
        font-size : 1.25rem;
    }
    a{
        font-size: 1.25rem;
        color: #497ad4;
        text-decoration: underline;
    }
    .loader{
        display: flex;
        justify-content: center;
    }

    @keyframes ldio-xkaxaxmxsdq {
      0% {
        top: 96px;
        left: 96px;
        width: 0;
        height: 0;
        opacity: 1;
      }
      100% {
        top: 18px;
        left: 18px;
        width: 156px;
        height: 156px;
        opacity: 0;
      }
    }.ldio-xkaxaxmxsdq div {
      position: absolute;
      border-width: 4px;
      border-style: solid;
      opacity: 1;
      border-radius: 50%;
      animation: ldio-xkaxaxmxsdq 1s cubic-bezier(0,0.2,0.8,1) infinite;
    }.ldio-xkaxaxmxsdq div:nth-child(1) {
      border-color: #bc8540;
      animation-delay: 0s;
    }
    .ldio-xkaxaxmxsdq div:nth-child(2) {
      border-color: #d5912e;
      animation-delay: -0.5s;
    }
    .loadingio-spinner-ripple-yrf6d28snur {
      width: 300px;
      min-height: 300px;
      display: inline-block;
      overflow: hidden;
    }
    .ldio-xkaxaxmxsdq {
      width: 100%;
      height: 100%;
      position: relative;
      transform: translateZ(0) scale(1);
      backface-visibility: hidden;
      transform-origin: 0 0; /* see note above */
    }
    .ldio-xkaxaxmxsdq div { box-sizing: content-box; }
`;
const BlogContent = ({ post }) => {
    const contentDiv = useRef();

    useEffect(() => {
        contentDiv.current.innerHTML = post
    }, [post, contentDiv]);

    return (
        <Section id="blog_content">
            <Container maxWidth={"1000px"}>
                <ContentDiv ref={contentDiv}>
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton width="50%"/>
                    <Skeleton />
                    <Skeleton />
                    <Skeleton width="50%"/>
                </ContentDiv>
            </Container>
        </Section>
    );
};

export default BlogContent;
