import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToZero = ({ children }) => {
	const location = useLocation();
    
    useEffect(() => {
        if(location.hash){
            return;
        }
           setTimeout(() => {
                window.scrollTo({top: 0,left: 0, behavior:"smooth"});
           },300)
	}, [location]);

	return <div>{children}</div>;
};

export default ScrollToZero;
