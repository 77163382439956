import styledComponents from "styled-components";
import ArrowIcon from "../../assets/svgs/Arrow";
import { Link } from "react-router-dom";

const Bread = styledComponents.div`
        width: 100%;
        display: flex;
        flex-flow: wrap;
        padding: 0.5rem 0;
        align-items: center;
`;

const Crumbs = styledComponents.div`
    display: flex;
    align-items: center;
    padding: 1rem 0;
    flex-flow: row nowrap;
    svg {
        margin: 0 1rem;
        width: 18px;
        height: 18px;
    }
    .text{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #606060;
        &.gold {
            color: var(--primary-two);
        }
        font-weight: 600;
        font-size: 24px;
    }
    &:hover{
      .text{
            color: var(--primary-two);
      }  
    }
    @media screen and (max-width: 900px){
        .text{
            font-size: 16px;
        }
        svg {
            width : 11px;
            height : 11px;
        }
    }
    @media screen and (max-width: 400px){
        .text{
            font-size: 12px;
        }
        svg {
            width : 11px;
            height : 11px;
        }
    }
`;

const BreadCrumbs = ({ breadData }) => {
    return (
        <Bread>
            {breadData?.map((data, key) => (
                <Crumbs key={key}>
                    {key > 0 && <ArrowIcon />}
                    <Link
                        to={data.path || "#"}
                        className={`text ${
                            key === breadData.length - 1 && key > 0 && "gold"
                        }`}
                        state={data.state}
                    >
                        {data.text}
                    </Link>
                </Crumbs>
            ))}
        </Bread>
    );
};

export default BreadCrumbs;
