/* eslint-disable react-hooks/exhaustive-deps */
import {
  ApplicationClosed,
  Section,
  Container,
  FormWrapper,
  FormGroup,
  Input,
  PhoneInput,
  RadioInput,
  Button,
  TextArea,
  DateInput,
  FormSuccessModal,
} from "../../Components";
import { useState, useMemo, useCallback } from "react";
import { debounceFunction } from "../../utils";
import styledComponents from "styled-components";
import { useAxiosBase } from "../../config/axios.config";
import { FormLoader } from "../../Components";
import { toast } from "react-toastify";
import Volunteer from "../Volunteer";

const DepartmentOption = [
  {
    name: "Media",
    label: "media",
    default: true,
  },
  {
    name: "Chess Education",
    label: "chess education",
  },
  {
    name: "Welfare",
    label: "welfare",
  },
  {
    name: "Other",
    label: "others",
  },
];

const VolunteerForm = ({ introText, callToAction }) => {
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState({ preferredDepartment: "media" });
  const [{ loading }, submit] = useAxiosBase(
    { url: "/volunteers", method: "POST" },
    { manual: true }
  );
  const clearForm = useCallback(async (form) => {
    form?.reset?.();
    setData({});
  }, []);

  const onDataChange = useCallback(
    (key, noRef = false) =>
      debounceFunction((e) => {
        setData((data) => {
          let newData = { ...data };
          if (noRef) {
            newData[key] = e;
          } else {
            newData[key] = e.target.value;
          }
          return newData;
        });
      }),
    []
  );

  const onEmailChange = useMemo(() => onDataChange("email"), []);
  const onFirstNameChange = useMemo(() => onDataChange("firstName"), []);
  const onLastNameChange = useMemo(() => onDataChange("lastName"), []);
  const onPhoneChange = useMemo(() => onDataChange("phoneNumber"), []);
  const onDepartmentChange = useMemo(
    () => onDataChange("preferredDepartment", true),
    []
  );
  const onDetailsChange = useMemo(() => onDataChange("skills"), []);
  const onLocationChange = useMemo(() => onDataChange("location"), []);
  const onCommentChange = useMemo(() => onDataChange("comments"), []);
  const onDayChange = useMemo(() => onDataChange("dayOfBirth", true), []);
  const onMonthChange = useMemo(() => onDataChange("monthOfBirth", true), []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let response = await submit({ data });
      setOpenModal(true);
      clearForm(e.target);
    } catch (e) {
      const data = e.response?.data;
      const message = data.message;
      if (message.email) {
        toast.error(message.email);
      }
    }
  };

  return (
    <VolunteerSection id="volunteer__section">
      <Container maxWidth={"1000px"}>
        {/* <form onSubmit={handleSubmit} method="POST">
          <FormWrapperP>
            <div className="form_title">
              {introText ?? "Thank you for choosing to volunteer with us"}
            </div>
            <div className="form">
              <div className="description">
                {callToAction ?? "Kindly fill the form below to get started"}
              </div>
              <div className="row">
                <div className="col">
                  <FormGroup label={"Firt Name"} forName="first_name">
                    <Input
                      required
                      placeholder="John"
                      name="first_name"
                      onChange={onFirstNameChange}
                    />
                  </FormGroup>
                </div>
                <div className="col">
                  <FormGroup label={"Last Name"} forName="last_name">
                    <Input
                      required
                      placeholder="Doe"
                      name="last_name"
                      onChange={onLastNameChange}
                    />
                  </FormGroup>
                </div>
              </div>
              <div className="row">
                <div className="col shrink">
                  <FormGroup label={"Date of birth (mm/dd)"} forName="dob">
                    <DateInput
                      required
                      onChangeDay={onDayChange}
                      onChangeMonth={onMonthChange}
                    />
                  </FormGroup>
                </div>
                <div className="col grow">
                  <FormGroup label={"Preferred Department"} forName="status">
                    <RadioInput
                      name="department"
                      options={DepartmentOption}
                      onChange={onDepartmentChange}
                    />
                  </FormGroup>
                </div>
              </div>
              <div className="row">
                <FormGroup label={"Skills"} forName="skills_details">
                  <TextArea
                    name="skills_details"
                    required
                    onChange={onDetailsChange}
                  />
                </FormGroup>
              </div>
              <div className="row">
                <div className="col">
                  <FormGroup label={"Location"} forName="location">
                    <Input
                      required
                      placeholder="Lagos Nigeria"
                      name="lacation"
                      onChange={onLocationChange}
                    />
                  </FormGroup>
                </div>
                <div className="col">
                  <FormGroup label={"Phone number"} forName="number">
                    <PhoneInput name="phone" onChange={onPhoneChange} />
                  </FormGroup>
                </div>
              </div>
              <div className="row">
                <FormGroup label={"Email"} forName="email">
                  <Input
                    required
                    placeholder="john@doe.com"
                    name="email"
                    type="email"
                    onChange={onEmailChange}
                  />
                </FormGroup>
              </div>
              <div className="row">
                <FormGroup label={"Comments"} forName="comments">
                  <TextArea
                    name="comments"
                    required
                    onChange={onCommentChange}
                  />
                </FormGroup>
              </div>
              <div className="row">
                <Button text="PROCEED" />
              </div>
            </div>
          </FormWrapperP>
        </form>
        <FormSuccessModal
          open={openModal}
          close={() => setOpenModal((v) => !v)}
        /> */}
        {/* <ApplicationClosed open={openModal} close={() => setOpenModal(v => !v)}/>*/}
        <Volunteer />
      </Container>
      {loading && <FormLoader />}
    </VolunteerSection>
  );
};

const VolunteerSection = styledComponents(Section)`
    padding: 9rem 1rem;
`;

const FormWrapperP = styledComponents(FormWrapper)`
    padding: 0;
    .form_title{
        margin-bottom: 1rem;
        max-width: 635px;
    }
    .form{
        .description{            
            font-family: 'Kumbh Sans';
            font-style: normal;
            font-weight: 500;
            font-size: 22px;
            line-height: 120%;
            color: #101010;
            margin-top: 3rem;
        }
        .row{
            margin-top: 1.5rem;
            .col{
                max-width: 500px;
                min-width: 400px;
                @media screen and (max-width: 830px){
                    min-width: unset; 
                }
                &.grow{
                    max-width: unset; 
                    flex: auto;
                }
                &.shrink{
                    min-width: unset;
                    flex : 0 0 auto; 
                    padding-right: 4rem;
                }
             }
        
            button{
                padding: 1rem 3.5rem;
            }
        }
    }
`;
export default VolunteerForm;
