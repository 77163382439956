import { useState, useCallback, useEffect } from "react";
import {useAxiosBase} from "../config/axios.config";

const useImageComments = (id) => {
    const [commnets, setComments] = useState([]);
    const [count, setCount] = useState(0);
    const [{ loading, error }, getComments] = useAxiosBase(
        { url: `/comments/picture/${id}`, method: "GET" },
        { manual: true }
    );
    const [{loading: sending}, sendComment] = useAxiosBase(
        { url: `/comments`, method: "POST" },
        { manual: true }
    );
    const [currentPage, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(false);

    const loadMore = useCallback(async () => {
        if (loading) return;
        let response = await getComments({
            params: {
                page: currentPage + 1,
            },
        });
        let { docs: commnets, hasNextPage, page, totalDocs } = response.data;
        setComments((v) => v.concat(commnets));
        setHasMore(hasNextPage);
        setPage(page);
        setCount(totalDocs);
    }, [currentPage, hasMore]);

    const postComment = useCallback(
        async (name, text) => {
            if (!name || !text) {
                return false;
            }
            try {
                let response = await sendComment({
                    data: { name, text, type: "picture", contentId: id },
                });
                let comment = response.data;
                setComments(v => {
                    return [comment,...v]
                })
                setCount(v => v + 1)
                return true;
            } catch (e) {
                return false;
            }
        },
        [id]
    );

    useEffect(() => {
        loadMore();
    }, []);

    return {
        read: () => commnets,
        loading,
        error,
        loadMore,
        hasMore,
        count,
        create: postComment,
        sending
    };
};

export default useImageComments;
