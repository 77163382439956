const TikTokIcon = () => (
 <svg width={27} height={27} viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.884.747a17.958 17.958 0 1 0 0 35.915 17.958 17.958 0 0 0 0-35.915Zm8.095 15.956c-1.602.177-2.975-.456-4.348-1.264 0 .132.04 4.234-.028 6.19-.069 2.011-.787 3.781-2.363 5.102-3.34 2.805-8.083 1.29-9.215-2.998-.614-2.337-.08-4.443 1.753-6.108 1.033-.946 2.306-1.366 3.672-1.541.293-.038.588-.054.905-.083v3.176c-.402.1-.808.187-1.207.305a5.43 5.43 0 0 0-.808.305 2.599 2.599 0 0 0-.945 4.016 2.836 2.836 0 0 0 2.71 1.144c1.032-.125 1.68-.775 2.063-1.708a5.91 5.91 0 0 0 .337-2.306c-.014-3.857-.014-7.71-.02-11.567.002-.04.008-.079.016-.118h3.083c.345 2.58 1.654 4.216 4.395 4.447v3.008Z"
      fill="#000"
    />
    </svg>
)

export default TikTokIcon

