import { ActivitiesCard } from "../../Components";
import ChessAct from "../../assets/ChessAct.png";
import Tutor from "../../assets/Tutor.png";
import Tournament from "../../assets/Tournament.png";
import Education from "../../assets/Education.png";
import { useContext } from "react";
import AppContext from "../../context/app.context";
import "./ourPrograms.style.scss";

const colours = ["#F6FFF5", "#FFFCF5", "#F6F5FF", "#FFF5FF"];
const OurPrograms = () => {
    const { workStreams: suspender } = useContext(AppContext);
    const { docs: programs } = suspender?.read() || {};
    return (
        <section id="our_program">
            <div className="container">
                <div className="title">Our Program Workstream</div>
                <div className="activites">
                    <div className="top">
                        {programs?.slice(0, 2).map((program, key) => (
                            <ActivitiesCard
                                image={program.image}
                                key={key}
                                background={colours[key]}
                                title={program.title}
                                content={program.description}
                            />
                        ))}
                    </div>
                    <div className="bottom">
                        {programs?.slice(2, 4).map((program, key) => (
                            <ActivitiesCard
                                image={program.image}
                                key={key}
                                background={colours[key + 2]}
                                title={program.title}
                                content={program.description}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default OurPrograms;
