import { BsArrowRight } from "react-icons/bs";
import "./donationCard.style.scss";
import Button from "../Button";
import { FaHeart } from "react-icons/fa";

const DonationCard = ({
  icon,
  name,
  color,
  backgrounColor,
  title,
  content,
}) => {
  return (
    <>
      {icon && (
        <div
          className="donation_card"
          style={{
            backgroundColor: backgrounColor,
            "--backgroud-color": backgrounColor,
            "--color": color,
          }}
        >
          <>
            <div className="icon">{icon}</div>
            <div className="name_arrow">
              <div className="name_div">
                <div className="name">{name}</div>
              </div>
              <div className="arrow_icon">
                <BsArrowRight size={30} color={color} />
              </div>
            </div>
          </>
        </div>
      )}
      {!icon && (
        <div
          className="donation_card1"
          style={{
            backgroundColor: backgrounColor,
            "--backgroud-color": backgrounColor,
            "--color": color,
          }}
        >
          <div className="title1">{title}</div>
          <div className="content1">{content}</div>
          <div className="btn12">
            <p className="btn-text">Donate monthly</p>{" "}
            <FaHeart color="#BA8C00" />
          </div>
        </div>
      )}
    </>
  );
};

export default DonationCard;
