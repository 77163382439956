import React from "react";
import "./volunteer.scss";

const Volunteer = () => {
  return (
    <div>
      <h1 className="header__text">
        Thank you for choosing to volunteer with us
      </h1>
      <div className="text__container">
        <p className="paragraoh__1">Dear Prospective Game-Changer,</p>
        <p className="paragraoh__2">
          Thank you for your enthusiastic interest in lending your skills and
          time to the Chess in Slums Initiative. We deeply appreciate your
          willingness to make a difference.
        </p>
        <p className="paragraoh__2">
          We kindly request that you complete this
          <a
            href="https://bit.ly/CISAVolunteerForm"
            className="paragraoh__link"
          >
            {" "}
            form
          </a>
          .
        </p>
        <p className="paragraoh__2">
          <span className="paragraoh__span">
            If the form indicates that we are not currently accepting
            volunteers, please do not feel discouraged. We warmly welcome you to
            join us during any of our upcoming community projects in the field.
          </span>
        </p>
        <p className="paragraoh__2">
          We encourage you to stay connected with us for real-time updates on
          our next community intervention via our social media platforms;
          Facebook, Instagram, Twitter, LinkedIn To receive further updates
          about our activities beyond social media, we invite you to subscribe
          to our{" "}
          <a
            href="https://www.chessinslumsafrica.com/blog"
            className="paragraoh__link"
          >
            {" "}
            Newsletter
          </a>
          .
        </p>
        <p className="paragraoh__2">
          We eagerly anticipate the possibility of your involvement! Other Ways
          to Join the Movement:{" "}
          <span className="paragraoh__span">
            Advocacy! Donation! Partnerships
          </span>
        </p>
      </div>
    </div>
  );
};

export default Volunteer;
