import {Section, Container} from "../../Components";
import Skeleton from "react-loading-skeleton";
const BlogLoader = () => {
    return (
        <Section id="loading">
            <Container maxWidth="1000px">
                <Skeleton width="50px" height="25px" style={{marginBottom: "1rem", marginTop: "4rem"}}/>
                <Skeleton width="100%" height="25px" style={{marginBottom: "1rem"}}/>
                <Skeleton width="100%" height="200px" style={{marginBottom: "1rem"}}/>
                <Skeleton width="100%" height="25px" style={{marginBottom: "1rem"}}/>
                <Skeleton width="90%" height="25px" style={{marginBottom: "1rem"}}/>
                <Skeleton width="100%" height="25px" style={{marginBottom: "1rem"}}/>
                <Skeleton width="100%" height="25px" style={{marginBottom: "1rem"}}/>
                <Skeleton width="50%" height="25px" style={{marginBottom: "1rem"}}/>
                <Skeleton width="100%" height="25px" style={{marginBottom: "1rem"}}/>
                <Skeleton width="100%" height="25px" style={{marginBottom: "1rem"}}/>
                <Skeleton width="100%" height="25px" style={{marginBottom: "1rem"}}/>
                <Skeleton width="100%" height="25px" style={{marginBottom: "1rem"}}/>
                <Skeleton width="100%" height="25px" style={{marginBottom: "1rem"}}/>
            </Container>
        </Section>
    )    
}
export default BlogLoader;
