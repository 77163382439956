import * as React from "react"

const PawnIcon = (props) => (
  <svg
    width={16}
    height={29}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.05 25.863c.1-.099.15-.198.2-.247.95-1.04.9-1.83-.2-2.77-.349-.298-.749-.495-1.098-.743-.3-.198-.65-.346-.95-.544-.599-.396-.599-.89-.15-1.385.55-.594.5-.693-.2-1.04-.299-.148-.749-.247-.898-.494-.7-1.286-1.45-2.573-1.35-4.106.05-1.039-.05-.841.9-.99.5-.049 1-.099 1.499-.247.2-.05.45-.346.45-.544 0-.247-.15-.495-.3-.693-.1-.148-.35-.198-.55-.296-.75-.347-1.499-.743-2.298-1.089 3.747-1.93 3.697-5.986 1.848-8.064C9.955.336 6.458.237 4.36 2.364 3.26 3.452 2.81 4.788 2.91 6.272c.15 2.028 1.25 3.414 3.098 4.354-.999.494-1.898.89-2.797 1.335-.5.248-.6.99-.15 1.237.2.149.5.198.75.198.599.099 1.148.148 1.748.198.3 1.484-.1 2.77-.7 4.007-.35.693-.65 1.336-1.498 1.484-.1 0-.2.1-.3.149-.25.148-.3.346-.15.544.1.148.25.247.35.396.35.445.35.84-.1 1.187-.2.198-.5.297-.75.445-.6.396-1.199.742-1.748 1.237-.7.643-.7 1.435-.1 2.177.15.148.25.297.45.495-.25.099-.4.148-.6.247-.1.05-.25.148-.3.247-.3.792 0 1.386.85 1.633.1.05.25.05.35.099 2.947.643 5.944.742 8.942.544 1.648-.099 3.347-.445 4.996-.693.4-.05.749-.247.749-.742 0-.94 0-.94-.7-1.187-.05.099-.1.05-.25 0Z"
      fill="#fff"
    />
  </svg>
)

export default PawnIcon;
