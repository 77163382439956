import { BsFillTelephoneFill } from "react-icons/bs";
import { RiWhatsappFill } from "react-icons/ri";
import FbIcon from "../../assets/svgs/FbIcon";
import IgIcon from "../../assets/svgs/IgIcon";
import LinkedInIcon from "../../assets/svgs/LinkedInIcon";
import TwitterIcon from "../../assets/svgs/Twitter";
import YoutubeIcon from "../../assets/svgs/Youtube";
import TikTokIcon from "../../assets/svgs/TikTok";
import "./contactCard.style.scss";

const ContactCard = ({ data }) => {
    return (
        <div className="contact_card">
            <div className="under" />
            <div className="card">
                <div className="card_title">Contact details</div>
                <div className="contact_group tele">
                    <div>
                        <div className="info_title">Telephone:</div>
                        <div className="informations">
                            <a
                            href={`tel:${data.telephone.split(" ").join("")}`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {" "}
                                <BsFillTelephoneFill size={16} /> +{data.telephone}
                            </a>
                        </div>
                    </div>
                    <div>
                        <div className="info_title">Whatsapp:</div>
                        <div className="informations">
                            <a
                                href={`https://wa.me/${data.whatsApp.split(" ").join("")}`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <RiWhatsappFill size={16} />
                                +{data.whatsApp}
                            </a>
                        </div>
                    </div>
                </div>
                <div className="contact_group  email">
                    <div className="info_title">Email:</div>
                    <div className="informations">
                        {data.emails?.map(e => (
                            <a href={`mailto:${e}`} key={e}>
                                {e}
                            </a>
                        ))}
                    </div>
                </div>
                <div className="contact_group address">
                    <div className="info_title">Address:</div>
                    <div className="informations">
                        <div>
                            1st floor Block C Adebowale House, 150 Ikorodu Road,
                            Onipanu - Lagos
                        </div>
                    </div>
                </div>
                <div className="social_handles">
                    <a
                        href={data.instagram || "#"}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <IgIcon />
                    </a>
                    <a
                        href={data.youtube || "#"}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <YoutubeIcon />
                    </a>
                    <a
                        href={data.tikTok || "#"}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <TikTokIcon />
                    </a>
                    <a
                        href={data.linkedIn || "#"}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <LinkedInIcon />
                    </a>
                    <a
                        href={data.facebook || "#"}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <FbIcon />
                    </a>
                    <a
                        href={data.twitter || "#"}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <TwitterIcon />
                    </a>
                </div>
            </div>
        </div>
    );
};

export default ContactCard;
