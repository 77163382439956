const LikeIcon = ({size}) => (
  <svg width={size || 20} height={size || 17} viewBox="0 0 20 17"  fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.25 1.333a4.583 4.583 0 0 0-4.583 4.584c0 4.583 5.416 8.75 8.333 9.719 2.917-.97 8.333-5.136 8.333-9.72A4.583 4.583 0 0 0 10 3.282a4.578 4.578 0 0 0-3.75-1.948Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default LikeIcon

