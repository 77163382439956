import { TeamCard } from "../../Components";

const SubTeamSection = ({ subteamTitle, subteamData }) => {
  // Filter out the specific data entry
  const filteredData = subteamData.filter(member => !(member.firstName === "Daniel" && member.lastName === "Anwuli"));
  console.log({filteredData});
  return (
    <>
      <div className="sub_title">{subteamTitle}</div>
      <div className="teams">
        {filteredData.map((teamMember) => (
          <TeamCard
            key={teamMember.id}
            image={teamMember?.image}
            name={`${teamMember?.firstName} ${teamMember?.lastName}`}
            position={teamMember?.position}
            email={teamMember?.email}
            facebook={teamMember?.facebook}
            twitter={teamMember?.twitter}
            linkedIn={teamMember?.linkedIn}
            isExecutive={Boolean(teamMember.type === "Executive")}
          />
        ))}
      </div>
    </>
  );
};

export default SubTeamSection;
