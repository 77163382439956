import styledComponents from "styled-components";

const FormWrapper = styledComponents.div`
    width : 100%;
    padding: 3rem 2rem;
    @media screen and (max-width: 500px){
        padding: 0;
    }      
    .form_title {
        font-family: 'New York Extra Large';
        font-style: normal;
        font-weight: 800;
        font-size: 40px;
        color : #303030;    
        width: 100%;
        @media screen and (max-width: 800px){
            font-size: 25px;
        }
    
    };
    .form {
        width : 100%;
        .row{
            margin-top: 1rem;
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            .col:first-child {     
                @media screen and (min-width: 600px){
                    padding-right: 1rem;
                }      
            }
            .col {
                margin-top: 1rem;
                flex: auto;
                max-width: 380px;
            }
            button{
                background-color: var(--primary-two);
                color: var(--white);
                border-radius: 30px;
                padding: 1rem 2.5rem;
                margin-top: 2rem;
            }
        }
    }
`

export default FormWrapper;
