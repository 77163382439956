import './NFTcard.style.scss';

const NFTCard = ({ url, image, name, price }) => {
	return (
		<a href={url} target='_blank' rel='noreferrer' className='NFTcard'>
			<div className='showcase'>
				<img src={image} alt='' />
			</div>
			<div className='details'>
				<div className='name_detail'>
					<div className='label'>Chess In Slums</div>
					<div className='value'>{name}</div>
				</div>
				<div className='price_detail'>
					<div className='name_detail'>
						<div className='label'>Reserve Price</div>
						<div className='value p'>{price}</div>
					</div>
				</div>
			</div>
		</a>
	);
};

export default NFTCard;
