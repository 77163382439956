import {Hero} from "../../Components";

const VolunteerHero = ({headerText}) => {
    return(
        <Hero 
            id="volunteer__hero"
            title={headerText ?? "Join our amazing team, let’s do some good together"}
            buttonText={"VOLUNTEER NOW"}
            sectionId="#volunteer__section"
            background={`linear-gradient( 90.35deg, rgba(34, 34, 34, 0.76) 26.34%,	rgba(57, 57, 57, 0) 99.71% ),
        url('https://ik.imagekit.io/w348wgpgc11/assets/tr:q-40/HomeGallery6_IU272Mlfg.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1644616844063')`}    
    />
    )
}

export default VolunteerHero;
