const TwHeartIcon = ({ size }) => (
    <svg
        width={size || 21}
        height={size || 18}
        viewBox="0 0 21 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M18.112 2.415a4.73 4.73 0 0 0-1.537-1.047 4.659 4.659 0 0 0-3.626 0 4.733 4.733 0 0 0-1.537 1.047l-.912.93-.913-.93A4.693 4.693 0 0 0 6.237 1a4.693 4.693 0 0 0-3.35 1.415A4.875 4.875 0 0 0 1.5 5.828c0 1.28.499 2.509 1.387 3.414l.913.93L10.5 17l6.7-6.828.912-.93c.44-.448.789-.98 1.027-1.566a4.906 4.906 0 0 0 0-3.695 4.833 4.833 0 0 0-1.027-1.566v0Z"
            stroke="currentColor"
            strokeWidth={1.3}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default TwHeartIcon;
