import { RiLoader4Fill } from 'react-icons/ri';

import './loader.scss';

const Loader = ({size}) => {
	return (
		<div className='loader__main'>
			<RiLoader4Fill  size={size}/>
		</div>
	);
};

export default Loader;
