import { useState, useEffect } from 'react';
import axios from '../config/axios.config';
import { wrapPromise } from '../utils';

const useTeamMembers = () => {
	const [teamMembers, setTeamMembers] = useState(null);

	useEffect(() => {
		setTeamMembers(wrapPromise(axios.get('/teamMembers/byDepartments')));
	}, []);

	return teamMembers;
};

export default useTeamMembers;
