const DownloadsIcon = ({ size }) => (
    <svg
        width={size || 19}
        height={size || 18}
        viewBox="0 0 19 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M17.5 11.666v3.556A1.778 1.778 0 0 1 15.722 17H3.278A1.778 1.778 0 0 1 1.5 15.222v-3.556M13.945 5.444 9.5 1 5.056 5.444M9.5 1v10.667"
            stroke="currentColor"
            strokeWidth={1.3}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default DownloadsIcon;
