import * as React from 'react';

const CrownIcon = (props) => (
	<svg
		width={16}
		height={12}
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{...props}
	>
		<path
			d='M.4 2.766a1.1 1.1 0 0 1 1.146-.155L5.02 4.13 7.04.55c.096-.168.236-.307.405-.403A1.12 1.12 0 0 1 8.96.55l2.018 3.58 3.474-1.52a1.115 1.115 0 0 1 1.15.155c.157.128.275.298.34.49.065.19.075.396.027.592l-1.744 7.315a1.065 1.065 0 0 1-.508.683c-.172.101-.369.155-.57.155a1.06 1.06 0 0 1-.288-.04 18.514 18.514 0 0 0-9.714 0 1.15 1.15 0 0 1-.858-.115 1.065 1.065 0 0 1-.508-.683L.029 3.847a1.064 1.064 0 0 1 .37-1.08Z'
			fill='#BA8C00'
		/>
	</svg>
);

export default CrownIcon;
