import './statbanner.scss';

const StatBanner = ({stats, descriptions}) => {
	return (
		<section id='stat__banner'>
			<img src={"https://ik.imagekit.io/w348wgpgc11/assets/tr:q-40/StatAfrica_E29fxFabP.png?ik-sdk-version=javascript-1.4.3&updatedAt=1644616768045"} className='stat__africa' alt='africa-chess-bg' />
			<div className='stat__banner__content'>
                <div className='stats'>
                    {stats?.map((s,i) => (    
                        <div className='stat' key={i}>
                            <p className='value'>{s}</p>
                            <p className='desc'>{descriptions[i]}</p>
                        </div>
                    ))}
				</div>
			</div>
		</section>
	);
};

export default StatBanner;
