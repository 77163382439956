import {useContext, useEffect, useState} from "react"
import AppContext from "../context/app.context";

const useCarouselData = (type) => {
    const [data, setData] = useState({read : () => []});
    const context = useContext(AppContext);
    useEffect(() => {
        switch(type){
            case "blogs" : 
                setData(context.blogs || {read : () => []})
                break;
            case "features":
                setData(context.features || {read: () => []})
                break;
            case "announcements":
                setData(context.announcements || {read: () => []})
                break;
            case "news":
                setData(context.news || {read: () => []})
                break;
            default:
                setData({read : () => []})
        }
    },[context, type])
    return data
}

export default useCarouselData;
