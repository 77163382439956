import { useState, useEffect } from 'react';
import axios from '../config/axios.config';
import { wrapPromise } from '../utils';

const usePartnership = () => {
	const [partnershipPageData, setPartnershipPageData] = useState(null);
	useEffect(() => {
		setPartnershipPageData(wrapPromise(axios('/partnershipPages')));
	}, []);
	return partnershipPageData;
};

export default usePartnership;
