import debounce from 'lodash.debounce';
const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
]
const clickOutside = (outter, inner, callBack) => {
    const handleClick = (e) => {
        if (!inner.contains(e.target)) {
            callBack();
        }
    };
    outter.addEventListener("click", handleClick);
    return () => outter.removeEventListener("click", handleClick);
};

const wrapPromise = (promise) => {
    let status = "pending";
    let result;
    let suspender = promise.then(
        (r) => {
            status = "success";
            result = r;
        },
        (e) => {
            status = "error";
            result = e;
        }
    );
    return {
        read() {
            if (status === "pending") {
                throw suspender;
            } else if (status === "error") {
                throw result;
            } else if (status === "success") {
                return result.data; //returns the users data on success
            }
        },
    };
};

const splitIntoParagraphs = (str) => {
    return str?.trim().split(/\r+\n+\r+/) || [];
};

const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    let year = date.getFullYear();
    let month = months[date.getMonth()]
    let dateNo = date.getDate();
    return `${month} ${dateNo}, ${year}`
}

const htmlToString  = (str) => {
    const div = document.createElement("div");
    div.innerHTML = str;
    const strOut = div.innerText
    div.remove();
    return strOut;
} 

const debounceFunction = (fn, time=500) => {
    return debounce(fn, time) 
}

export { clickOutside, wrapPromise, splitIntoParagraphs, formatDate, htmlToString, debounceFunction};
