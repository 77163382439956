const CopyIcon = ({copied}) => (
  <svg width={31} height={31} fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#a)" fill={copied ? "green" : "#000"}>
      <path d="M26.492 30.61h-15a3.129 3.129 0 0 1-3.125-3.126V8.734a3.129 3.129 0 0 1 3.125-3.125h15a3.129 3.129 0 0 1 3.125 3.125v18.75a3.129 3.129 0 0 1-3.125 3.125Zm-15-23.75a1.877 1.877 0 0 0-1.875 1.874v18.75c0 1.034.84 1.875 1.875 1.875h15a1.877 1.877 0 0 0 1.875-1.875V8.734a1.877 1.877 0 0 0-1.875-1.875h-15Z" />
      <path d="M6.492 26.86h-1.25a3.129 3.129 0 0 1-3.125-3.126v-20A3.129 3.129 0 0 1 5.242.61h15a3.129 3.129 0 0 1 3.125 3.125.625.625 0 0 1-1.25 0 1.877 1.877 0 0 0-1.875-1.875h-15a1.877 1.877 0 0 0-1.875 1.875v20c0 1.034.841 1.875 1.875 1.875h1.25a.625.625 0 0 1 0 1.25ZM23.992 21.86h-10a.625.625 0 0 1 0-1.25h10a.625.625 0 0 1 0 1.25ZM23.992 26.86h-10a.625.625 0 0 1 0-1.25h10a.625.625 0 0 1 0 1.25Z" />
      <path d="M23.992 16.86h-10a.625.625 0 0 1 0-1.25h10a.625.625 0 0 1 0 1.25ZM23.992 11.86h-10a.625.625 0 0 1 0-1.25h10a.625.625 0 0 1 0 1.25Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" transform="translate(.867 .61)" d="M0 0h30v30H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default CopyIcon
