import { useNavigate } from "react-router-dom";
import PawnIcon from "../../assets/svgs/PawnIcon";
import { Button } from "../../Components";
import "./losingpotential.scss";

const LosingPotential = ({ image, title, facts, description }) => {
    const navigate = useNavigate();

    return (
        <section id="losing__potential">
            <div className="losing__potential__content">
                <div className="left__text">
                    <h1 className="title">{title}</h1>
                    <p className="text">{description}</p>
                    {facts?.map((f, i) => (
                        <div className="point" key={i}>
                            <div className="pawn__icon">
                                <PawnIcon />
                            </div>
                            <p className="text">{f}</p>
                        </div>
                    ))}
                    <Button
                        text={"LEARN MORE"}
                        className={"button__primary learn__more"}
                        onClick={() => navigate("/about")}
                    />
                </div>
                <img
                    src={
                        `${image}/tr:q-60`
                    }
                    alt="potential bg"
                />
            </div>
        </section>
    );
};

export default LosingPotential;
