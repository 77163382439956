import React from "react";

const DonationLink = ({
  item,
  index,
  img1,
  img2,
  hoveredIndex,
  handleMouseOver,
  handleMouseOut,
}) => {
  return (
    <>
      {hoveredIndex === index && (
        <div className="donation-link-hover-con">
          <p className="donation-link-hover-text">{item.hoverText}</p>
        </div>
      )}
      <div key={index} className="donation-link-con">
        <div
          onMouseOver={() => handleMouseOver(index)}
          onMouseOut={handleMouseOut}
          onTouchStart={() => handleMouseOver(index)}
          onTouchEnd={handleMouseOut}
          style={{
            cursor: "pointer",
          }}
        >
          <img src={img1} alt="alert" className="alert-img" />
        </div>
        <a
          href={item.link}
          target="_blank"
          rel="noreferrer"
          className="monthly-donate-link"
        >
          {item.name}
        </a>
        <img src={img2} alt="arrow" className="arrow-img" />
      </div>
    </>
  );
};

export default DonationLink;
