import useAxios from "axios-hooks"

const useLikesFunctions = () => {
    const [_, picturesApi] = useAxios({url:"/pictures/like", method: "POST"},{manual: true});
    const [__, articlesApi] = useAxios({url:"/articles/like", method: "POST"},{manual: true});

    const likeArticle = (id) => {
        return articlesApi({data:{id}});
    }
    
    const likePicture = (id) => {
        return picturesApi({data:{id}});
    }

    return {
        likePicture,
        likeArticle
    }
}

export default useLikesFunctions
