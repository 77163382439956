import styledComponents from "styled-components";
import { useState, useEffect } from "react";
import { ThemeProvider } from "styled-components";
import ListIcon from "../../assets/svgs/ListIcon";
import GridIcon from "../../assets/svgs/GridIcon";
const BlogListWrapper = styledComponents.div`
    width: 100%;
    padding-bottom: 1rem;
    .header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 1.5rem;
        align-items: center;
        .title{
            font-family: 'New York Extra Large';
            font-style: normal;
            font-weight: 600;
            font-size: 32px;
            color: #000000;
            @media screen and (max-width: 600px){
                font-size: 24px;
            }
        }
        .toggle__actions{
            display: flex;
            justify-content: space-between;
            align-items: center;
            i{
                margin-left: 1rem;
                cursor: pointer;
                color: #C4C4C4;
                &:hover, &.active{
                    color: var(--primary-two)
                }
            }
            @media screen and (max-width: 600px){
                display: none
            }
            ${props => props.grid ? "display: none" :""}
        }
    }
    .list{
        width: 100%;
        display: ${(props) => props.theme.display}; 
        flex-flow: column; 
        grid-gap: 1rem; 
        grid-template-columns: repeat(auto-fit, minmax(260px, 270px));
        @media screen and (max-width: 600px){
            display: flex;
        }
        @media screen and (min-width: 1200px){
        }
    }
`;

const BlogList = ({ children, title, grid }) => {
    const [list, setList] = useState(false);
    const [theme, setTheme] = useState({ display: "grid" });

    useEffect(() => {
        if (!list) {
            setTheme({
                display: "grid",
            });
        } else {
            setTheme({
                display: "flex",
            });
        }
    }, [list]);

    return (
        <ThemeProvider theme={theme}>
            <BlogListWrapper list={list} grid={grid}>
                <div className="header">
                    <div className="title">{title}</div>
                    <div className="toggle__actions" >
                        <i className={`${list && "active"}`} onClick={() => setList(true)}>
                            <ListIcon size={22} />
                        </i>
                        <i className={`${!list && "active"}`} onClick={() => setList(false)}>
                            <GridIcon />
                        </i>
                    </div>
                </div>
                <div className="list">{children}</div>
            </BlogListWrapper>
        </ThemeProvider>
    );
};

export default BlogList;
