import styledComponents from "styled-components";

const CommentDiv = styledComponents.div`
    width: 100%;
    display: flex;
    flex-flow: column;
    padding: 1rem 0;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
    max-width: 500px;
    ${(props) => (props.curved ? "padding: 2rem;" : "")}
    ${(props) => (props.curved ? "max-width: unset;" : "")}
    .name{
        font-size: 1rem;
        font-weight: bold;
        color: black;
        padding-bottom: 0.5rem;
    }
    .text{
        font-size: 1rem;
        font-weight: 400px;
        line-height: 150%;
        max-width: 400px;
        line-height: 150%;
        ${(props) => (props.curved ? "max-width: unset" : "")}
   }
   ${(props) =>
       props.curved
           ? "background: rgba(248, 248, 248, 0.5); border-radius: 12px; border: none"
           : ""}
`;

const Comment = ({ name, text , curved}) => {
    return (
        <CommentDiv curved={curved}>
            <div className="name">{name}</div>
            <div className="text">{text}</div>
        </CommentDiv>
    );
};

export default Comment;
