import { BlogCard } from '..';
import './relatedarticles.scss';

const RelatedArticles = ({ posts }) => {
	return (
		<section id='related__articles'>
			<div className='related__articles__content'>
				<h1 className='title'>Related articles</h1>
				<div className='posts__content'>
					{posts.map((post) => (
						<BlogCard
							key={post.id}
							blogId={post.id}
							blogImage={post.imgUrl}
							blogTitle={post.title}
							blogExcerpts={post.excerpts}
							blogUrl={post.slug}
						/>
					))}
				</div>
			</div>
		</section>
	);
};

export default RelatedArticles;
