import "./accountModal.style.scss";
import CopyIcon from "../../assets/svgs/CopyIcon";
import { useRef, useState } from "react";
import Modal from "../Modal";
const AccountModal = ({ close, open }) => {
    const [copied, setCopied] = useState(false);
    const divRef = useRef();
    const copyAccount = () => {
        navigator.clipboard.writeText("0491316991");
        setCopied((v) => !v);
        divRef.current.title = "account number copied";
        setTimeout(() => {
            setCopied((v) => !v);
            divRef.current.title = "copy account";
        }, 1000);
    };
    return (
        <Modal open={open} close={close}>
            <div className="acc_title">Donate to us via:</div>
            <div
                ref={divRef}
                className="account_number"
                title="copy account"
                onClick={copyAccount}
            >
                <div className="account">0491316991</div>
                <div className="icon">
                    <CopyIcon copied={copied} />
                </div>
            </div>
            <div className="bank_name">
                Guaranty Trust Bank Chess In Slums Initiative
            </div>
            <div className="thanks-note">
                Thank you for choosing to be a game changer
            </div>
            <div className="note">
                Note - Enquiry or receipt for confirmation of payment can be
                sent to{" "}
                <a href="mailto:chessinslums@gmail.com">
                    chessinslums@gmail.com
                </a>
            </div>
        </Modal>
    );
};

export default AccountModal;
