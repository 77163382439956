export const HOME = '/';
export const ABOUT = '/about';
export const MERCHANDISE = '/merchandise';
export const CONTACT = '/contact';
export const TEAMS = '/teams';
export const DONATE = '/donate';
export const SCHOLARSHIP = '/scholarship';
export const PARTNER = '/partner';
export const VOLUNTEER = '/volunteer';
export const GALLERY = 'gallery';
export const ALBUM = 'gallery/:album';
export const FOLDER = 'gallery/:album/:folder';
export const BLOG = '/blog';
export const BLOGS = '/blog';
export const NEWS = '/blog/news';
export const ALL = '/blog/all';
export const ANNOUNCEMENTS = '/blog/announcements';
export const FEATURES = '/blog/features';
