import styledComponents from "styled-components";
import ExitIcon from "../../assets/svgs/ExitIcon";
import Button from "../Button";

const ErrorFallback = ({error, resetErrorBoundary}) => {
    return (
        <ErrorFallbackWrapper>
            <ExitIcon size={120}/>
            <div className="error">{ error.message.toLowerCase().includes("network") ? "Network error!":"Application error!"}</div>
         <Button text={"reload"} onClick={() => resetErrorBoundary()}/>
        </ErrorFallbackWrapper>
    )
}

const ErrorFallbackWrapper = styledComponents.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column; 
    .error{
        padding: 2rem 0;
        font-size: 30px;
        font-family: "New York Extra Large";
        color: var(--title-color);
    }
    button{
        background-color: var(--primary-two);
        color: var(--white);
        padding: 0.7rem 1.5rem;
        border-radius: 30px;
    }
`
export default ErrorFallback;
