import './homeimagecards.scss';

const HomeImageCards = () => {
	return (
		<section id='home__image__cards'>
			<div className='home__image__cards__content'>
                <img src={"https://ik.imagekit.io/w348wgpgc11/assets/HomeImageCard1_SkeYr4_qQhs.png/tr:q-40"} className='img one' alt='card-one' />
                <img src={"https://ik.imagekit.io/w348wgpgc11/assets/tr:q-40/HomeImageCard2_RYd3ZTsXw.png?ik-sdk-version=javascript-1.4.3&updatedAt=1644616776154"} className='img two' alt='card-two' />
                <img src={"https://ik.imagekit.io/w348wgpgc11/assets/tr:q-40/HomeImageCard3_f27m8OX2L.png?ik-sdk-version=javascript-1.4.3&updatedAt=1644616774112"} className='img three' alt='card-three' />
                <img src={"https://ik.imagekit.io/w348wgpgc11/assets/tr:q-40/HomeImageCard4_d2XZiDRUd.png?ik-sdk-version=javascript-1.4.3&updatedAt=1644616781144"} className='img four' alt='card-four' />
                <img src={"https://ik.imagekit.io/w348wgpgc11/assets/tr:q-40/HomeImageCard5_NHFQeUxmq.png?ik-sdk-version=javascript-1.4.3&updatedAt=1644616774130"} className='img five' alt='card-five' />
                <img src={"https://ik.imagekit.io/w348wgpgc11/assets/tr:q-40/HomeImageCard6_m0jNUoEld.png?ik-sdk-version=javascript-1.4.3&updatedAt=1644616754907"} className='img six' alt='card-six' />
			</div>
		</section>
	);
};

export default HomeImageCards;
