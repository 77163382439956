import { useState, useEffect } from "react";
import axios from "../config/axios.config";
import {wrapPromise} from "../utils";

const useFeatured = () => {
    const [blog, setBlog] = useState(null);
    useEffect(() => {
        setBlog(wrapPromise(axios("/articles/featured")))
    },[])
    return blog
};

export default useFeatured;


