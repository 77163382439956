const ListIcon = ({size}) => (
  <svg width={size || 19} height={size || 15} viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.726 11.604v1.875c0 .26-.09.482-.272.664a.898.898 0 0 1-.66.273H1.682a.898.898 0 0 1-.66-.273.906.906 0 0 1-.273-.664v-1.875c0-.26.09-.482.272-.664a.898.898 0 0 1 .661-.274h3.11c.26 0 .48.092.661.274a.906.906 0 0 1 .272.664Zm0-5v1.875c0 .26-.09.482-.272.664a.898.898 0 0 1-.66.273H1.682a.898.898 0 0 1-.66-.273.906.906 0 0 1-.273-.664V6.604c0-.26.09-.482.272-.664a.898.898 0 0 1 .661-.274h3.11c.26 0 .48.092.661.274a.906.906 0 0 1 .272.664Zm12.44 5v1.875c0 .26-.09.482-.271.664a.897.897 0 0 1-.661.273h-9.33a.898.898 0 0 1-.662-.273.906.906 0 0 1-.272-.664v-1.875c0-.26.091-.482.272-.664a.898.898 0 0 1 .661-.274h9.33c.26 0 .48.092.662.274a.906.906 0 0 1 .272.664Zm-12.44-10v1.875c0 .26-.09.482-.272.664a.898.898 0 0 1-.66.274H1.682a.898.898 0 0 1-.66-.274.906.906 0 0 1-.273-.664V1.604c0-.26.09-.482.272-.664a.898.898 0 0 1 .661-.273h3.11c.26 0 .48.09.661.273a.906.906 0 0 1 .272.664Zm12.44 5v1.875c0 .26-.09.482-.271.664a.898.898 0 0 1-.661.273h-9.33a.898.898 0 0 1-.662-.273.906.906 0 0 1-.272-.664V6.604c0-.26.091-.482.272-.664a.898.898 0 0 1 .661-.274h9.33c.26 0 .48.092.662.274a.906.906 0 0 1 .272.664Zm0-5v1.875c0 .26-.09.482-.271.664a.898.898 0 0 1-.661.274h-9.33a.898.898 0 0 1-.662-.274.906.906 0 0 1-.272-.664V1.604c0-.26.091-.482.272-.664a.898.898 0 0 1 .661-.273h9.33c.26 0 .48.09.662.273a.906.906 0 0 1 .272.664Z"
      fill="currentColor"
    />
  </svg>
)

export default ListIcon
