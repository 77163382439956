import { useState, useEffect } from 'react';
import axios from '../config/axios.config';
import { wrapPromise } from '../utils';

const useDonation = () => {
	const [donationData, setDonationData] = useState(null);
	useEffect(() => {
		setDonationData(wrapPromise(axios('/donationPages')));
	}, []);
	return donationData;
};

export default useDonation;
