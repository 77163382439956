import { useContext } from "react";
import Helmet from "react-helmet";
import styled from "styled-components";

import AppContext from "../context/app.context";
import { TeamSection } from "../sections";

const TeamsPage = () => {
  // const { detailedTeamMembersData: suspender } = useContext(AppContext);
  const teamsData = [
    {
      docs: [
        {
          docPosition: 1,
          firstName: "Tunde",
          lastName: "Onakoya",
          position: "Convener, Chess In Slums Africa",
          linkedIn: null,
          facebook: null,
          twitter: null,
          type: "Executive",
          image:
            "https://ik.imagekit.io/w348wgpgc11/ChessInSlums/PRODUCTION/teamMember/6294e932fd92d9001638e4c2/image-1655495480736",
          createdBy: {
            email: "enebelidavid@gmail.com",
            firstName: "Default",
            lastName: "admin",
            id: "624b68d9ccb98ca1e598fc66",
          },
          updatedBy: {
            firstName: "Olashile",
            lastName: "Ayobami",
            email: "olashile@chessinslumsafrica.com",
            id: "629919f85b991000169244e8",
          },
          id: "6294e932fd92d9001638e4c2",
        },
        {
          docPosition: 2,
          firstName: "Obinna",
          lastName: "Ogbonnaya",
          position: "International Chess Arbiter, FIDE Technical Adviser",
          linkedIn: null,
          facebook: null,
          twitter: null,
          type: "Board Of Trustees",
          image:
            "https://ik.imagekit.io/w348wgpgc11/ChessInSlums/PRODUCTION/teamMember/6294e95ffd92d9001638e4c9/image-1654265235226",
          createdBy: {
            email: "enebelidavid@gmail.com",
            firstName: "Default",
            lastName: "admin",
            id: "624b68d9ccb98ca1e598fc66",
          },
          updatedBy: {
            email: "enebelidavid@gmail.com",
            firstName: "Default",
            lastName: "admin",
            id: "624b68d9ccb98ca1e598fc66",
          },
          id: "6294e95ffd92d9001638e4c9",
        },
        // {
        //     "docPosition": 3,
        //     "firstName": "Daniel",
        //     "lastName": "Anwuli",
        //     "position": "International Chess Master and West African Chess Champion",
        //     "linkedIn": null,
        //     "facebook": null,
        //     "twitter": null,
        //     "type": "Board Of Trustees",
        //     "image": "https://ik.imagekit.io/w348wgpgc11/ChessInSlums/PRODUCTION/teamMember/6294e98bfd92d9001638e4d0/image-1654265254532",
        //     "createdBy": {
        //         "email": "enebelidavid@gmail.com",
        //         "firstName": "Default",
        //         "lastName": "admin",
        //         "id": "624b68d9ccb98ca1e598fc66"
        //     },
        //     "updatedBy": {
        //         "email": "enebelidavid@gmail.com",
        //         "firstName": "Default",
        //         "lastName": "admin",
        //         "id": "624b68d9ccb98ca1e598fc66"
        //     },
        //     "id": "6294e98bfd92d9001638e4d0"
        // },
        {
          docPosition: 3,
          firstName: "Veronica",
          lastName: "Akaolisa Egere",
          position: "Partnership Manager",
          linkedIn:
            "https://www.linkedin.com/in/chiugo-veronica-akaolisa-a3a7a6157",
          facebook:
            "https://www.linkedin.com/in/chiugo-veronica-akaolisa-a3a7a6157",
          twitter:
            "https://www.linkedin.com/in/chiugo-veronica-akaolisa-a3a7a6157",
          type: "Team Lead",
          image:
            "https://ik.imagekit.io/w348wgpgc11/ChessInSlums/PRODUCTION/teamMember/629a5e98429c5700168a7cfb/image-1655495971027",
          createdBy: {
            email: "enebelidavid@gmail.com",
            firstName: "Default",
            lastName: "admin",
            id: "624b68d9ccb98ca1e598fc66",
          },
          updatedBy: {
            firstName: "Olashile",
            lastName: "Ayobami",
            email: "olashile@chessinslumsafrica.com",
            id: "629919f85b991000169244e8",
          },
          id: "629a5e98429c5700168a7cfb",
        },
        {
          docPosition: 4,
          firstName: "Kabir",
          lastName: "Ahmed",
          position: "CEO, Hydeashub Limited",
          linkedIn: null,
          facebook: null,
          twitter: null,
          type: "Board Of Trustees",
          image:
            "https://ik.imagekit.io/w348wgpgc11/ChessInSlums/PRODUCTION/teamMember/6294e9bffd92d9001638e4d7/image-1654265273254",
          createdBy: {
            email: "enebelidavid@gmail.com",
            firstName: "Default",
            lastName: "admin",
            id: "624b68d9ccb98ca1e598fc66",
          },
          updatedBy: {
            email: "enebelidavid@gmail.com",
            firstName: "Default",
            lastName: "admin",
            id: "624b68d9ccb98ca1e598fc66",
          },
          id: "6294e9bffd92d9001638e4d7",
        },
        {
          docPosition: 5,
          firstName: "Carey",
          lastName: "Fan",
          position: "CEO, Chesskid",
          linkedIn: null,
          facebook: null,
          twitter: null,
          type: "Board Of Trustees",
          image:
            "https://ik.imagekit.io/w348wgpgc11/ChessInSlums/PRODUCTION/teamMember/6294e9effd92d9001638e4de/image-1654265291115",
          createdBy: {
            email: "enebelidavid@gmail.com",
            firstName: "Default",
            lastName: "admin",
            id: "624b68d9ccb98ca1e598fc66",
          },
          updatedBy: {
            email: "enebelidavid@gmail.com",
            firstName: "Default",
            lastName: "admin",
            id: "624b68d9ccb98ca1e598fc66",
          },
          id: "6294e9effd92d9001638e4de",
        },
        {
          docPosition: 6,
          firstName: "Bunmi",
          lastName: "Akinyemiju",
          position: "Venture Garden Foundation",
          linkedIn: null,
          facebook: null,
          twitter: null,
          type: "Board Of Trustees",
          image:
            "https://ik.imagekit.io/w348wgpgc11/ChessInSlums/PRODUCTION/teamMember/6294ea24fd92d9001638e4e5/image-1654265309284",
          createdBy: {
            email: "enebelidavid@gmail.com",
            firstName: "Default",
            lastName: "admin",
            id: "624b68d9ccb98ca1e598fc66",
          },
          updatedBy: {
            email: "enebelidavid@gmail.com",
            firstName: "Default",
            lastName: "admin",
            id: "624b68d9ccb98ca1e598fc66",
          },
          id: "6294ea24fd92d9001638e4e5",
        },
        {
          docPosition: 7,
          firstName: "Kunmi",
          lastName: "Demuren",
          position: "Venture Garden Foundation",
          linkedIn: null,
          facebook: null,
          twitter: null,
          type: "Board Of Trustees",
          image:
            "https://ik.imagekit.io/w348wgpgc11/ChessInSlums/PRODUCTION/teamMember/6294ea54fd92d9001638e4ec/image-1654265327640",
          createdBy: {
            email: "enebelidavid@gmail.com",
            firstName: "Default",
            lastName: "admin",
            id: "624b68d9ccb98ca1e598fc66",
          },
          updatedBy: {
            email: "enebelidavid@gmail.com",
            firstName: "Default",
            lastName: "admin",
            id: "624b68d9ccb98ca1e598fc66",
          },
          id: "6294ea54fd92d9001638e4ec",
        },
        {
          docPosition: 8,
          firstName: "Emmanuel Abiodun",
          lastName: "Oke",
          position: "Chief Operations Officer",
          linkedIn: "https://www.linkedin.com/in/okeemmanuel",
          facebook: "https://www.linkedin.com/in/okeemmanuel",
          twitter: "https://www.linkedin.com/in/okeemmanuel",
          type: "Team Lead",
          image:
            "https://ik.imagekit.io/w348wgpgc11/ChessInSlums/PRODUCTION/teamMember/6294ea83fd92d9001638e4f3/image-1655495926118",
          createdBy: {
            email: "enebelidavid@gmail.com",
            firstName: "Default",
            lastName: "admin",
            id: "624b68d9ccb98ca1e598fc66",
          },
          updatedBy: {
            firstName: "Olashile",
            lastName: "Ayobami",
            email: "olashile@chessinslumsafrica.com",
            id: "629919f85b991000169244e8",
          },
          id: "6294ea83fd92d9001638e4f3",
        },
        {
          docPosition: 10,
          firstName: "Esther",
          lastName: "Hunkuten",
          position: "Scholarship Manager",
          linkedIn: "https://www.linkedin.com/in/esther-hunkuten-91092917b",
          facebook: "https://www.linkedin.com/in/esther-hunkuten-91092917b",
          twitter: "https://www.linkedin.com/in/esther-hunkuten-91092917b",
          type: "Team Lead",
          image:
            "https://ik.imagekit.io/w348wgpgc11/ChessInSlums/PRODUCTION/teamMember/6294e732fd92d9001638e481/image-1655495660882",
          createdBy: {
            email: "enebelidavid@gmail.com",
            firstName: "Default",
            lastName: "admin",
            id: "624b68d9ccb98ca1e598fc66",
          },
          updatedBy: {
            firstName: "Olashile",
            lastName: "Ayobami",
            email: "olashile@chessinslumsafrica.com",
            id: "629919f85b991000169244e8",
          },
          id: "6294e732fd92d9001638e481",
        },
        {
          docPosition: 11,
          firstName: "Adebukola",
          lastName: "Benjamin",
          position: "Communications & Media Officer",
          linkedIn: "https://www.linkedin.com/in/bukola-benjamin",
          facebook: "https://www.linkedin.com/in/bukola-benjamin",
          twitter: "https://www.linkedin.com/in/bukola-benjamin",
          type: "Team Lead",
          image:
            "https://ik.imagekit.io/w348wgpgc11/ChessInSlums/PRODUCTION/teamMember/6294e79bfd92d9001638e48b/image-1655495863896",
          createdBy: {
            email: "enebelidavid@gmail.com",
            firstName: "Default",
            lastName: "admin",
            id: "624b68d9ccb98ca1e598fc66",
          },
          updatedBy: {
            firstName: "Olashile",
            lastName: "Ayobami",
            email: "olashile@chessinslumsafrica.com",
            id: "629919f85b991000169244e8",
          },
          id: "6294e79bfd92d9001638e48b",
        },
        {
          docPosition: 12,
          firstName: "Samuel",
          lastName: "Awobajo",
          position: "Project Coordinator Officer",
          linkedIn: "https://www.linkedin.com/in/samuel-awobajo-7b786821b",
          facebook: "https://www.linkedin.com/in/samuel-awobajo-7b786821b",
          twitter: "https://www.linkedin.com/in/samuel-awobajo-7b786821b",
          type: "Team Lead",
          image:
            "https://ik.imagekit.io/w348wgpgc11/ChessInSlums/PRODUCTION/teamMember/6294e7d3fd92d9001638e492/image-1655495895421",
          createdBy: {
            email: "enebelidavid@gmail.com",
            firstName: "Default",
            lastName: "admin",
            id: "624b68d9ccb98ca1e598fc66",
          },
          updatedBy: {
            firstName: "Olashile",
            lastName: "Ayobami",
            email: "olashile@chessinslumsafrica.com",
            id: "629919f85b991000169244e8",
          },
          id: "6294e7d3fd92d9001638e492",
        },
      ],
      hasNextPage: false,
      hasPrevPage: false,
      limit: 1000,
      nextPage: null,
      page: 1,
      prevPage: null,
      totalDocs: 12,
      totalPages: 1,
    },
  ];
  console.log(teamsData[0].docs);

  return (
    <div id="teams-page">
      <Helmet>
        <title>Teams | Chess In Slums Africa</title>
        <meta
          name="description"
          content="We are a social development initiative transforming impoverished communities by empowering the young through chess educational resources."
        />
      </Helmet>
      <TeamPageContainer>
        {/* 				<TeamSection teamMembersData={suspender?.read()?.docs} noTitle /> */}
        <TeamSection teamMembersData={teamsData[0]?.docs} noTitle />
      </TeamPageContainer>
    </div>
  );
};

const TeamPageContainer = styled.div`
  padding: 5rem 0;
`;

export default TeamsPage;
