import styledComponents from "styled-components";
import Button from "../Button";
import Loader from "../Loader";

const MoreDiv = styledComponents.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4rem 1rem; 
    button{
        padding : 1rem 2rem;
        min-width: 200px;
        text-align: center;
        border-radius: 40px;
        color: var(--white);
        background-color: var(--primary-two);
    }
    svg{
        color: var(--primary-two);
    }
`;
const LoadMore = ({ loadMore, loading, hasMore }) => {
    if(!hasMore) return null;
    return (
        <MoreDiv>
            {loading ? (
                <Loader size={50} />
            ) : (
                <Button onClick={() => loadMore?.()} text={"VIEW MORE"} />
            )}
        </MoreDiv>
    );
};

export default LoadMore;
