import Axios from "axios";
import {makeUseAxios} from "axios-hooks";

const axios = Axios.create({
    baseURL: "https://cisa-api.fly.dev/frontend",
});

const axiosBase = Axios.create({
    baseURL: "https://cisa-api.fly.dev",
});

axios.interceptors.request.use(
    (config) => {
        config.url = config.url?.replace(/[^\x20-\x7E]/g, "");
        config.headers["Content-Type"] = "application/json";
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosBase.interceptors.request.use(
    (config) => {
        config.url = config.url?.replace(/[^\x20-\x7E]/g, "");
        config.headers["Content-Type"] = "application/json";
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

const useAxiosBase = makeUseAxios({axios : axiosBase})
export { axiosBase , useAxiosBase};
export default axios;
