import { useNavigate } from 'react-router-dom';
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';

import { Button } from '..';
import blogsData from '../../data/blogsData';
import './navigatebuttons.scss';

const NavigateButtons = ({ currentPost }) => {
	const navigate = useNavigate();

	return (
		<div id='navigate__buttons'>
			<div className='navigate__buttons__content'>
				<Button
					className='navigate__button previous'
					disabled={currentPost.id === 0}
					onClick={() => navigate(blogsData[currentPost.id - 1].slug)}
				>
					<FiArrowLeft />
					<span>PREVIOUS</span>
				</Button>
				<Button
					className='navigate__button next'
					disabled={currentPost.id === 4}
					onClick={() => navigate(blogsData[currentPost.id + 1].slug)}
				>
					<span>NEXT POST</span>
					<FiArrowRight />
				</Button>
			</div>
		</div>
	);
};

export default NavigateButtons;
