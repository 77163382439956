import * as React from "react"

const GridIcon = ({size}) => (
  <svg width={size || 22} height={size || 22} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.556 2.444H3.668c-.675 0-1.223.548-1.223 1.223v4.888c0 .675.548 1.223 1.223 1.223h4.888c.675 0 1.223-.548 1.223-1.223V3.667c0-.675-.548-1.223-1.223-1.223ZM18.334 2.444h-4.89c-.674 0-1.221.548-1.221 1.223v4.888c0 .675.547 1.223 1.222 1.223h4.889c.675 0 1.222-.548 1.222-1.223V3.667c0-.675-.547-1.223-1.222-1.223ZM8.556 12.222H3.668c-.675 0-1.223.547-1.223 1.222v4.89c0 .674.548 1.221 1.223 1.221h4.888c.675 0 1.223-.547 1.223-1.222v-4.889c0-.675-.548-1.222-1.223-1.222ZM18.334 12.222h-4.89c-.674 0-1.221.547-1.221 1.222v4.89c0 .674.547 1.221 1.222 1.221h4.889c.675 0 1.222-.547 1.222-1.222v-4.889c0-.675-.547-1.222-1.222-1.222Z"
      fill="currentColor"
    />
  </svg>
)

export default GridIcon

