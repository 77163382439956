import "./sponsorbanner.scss";

const SponsorBanner = () => {
    return (
        <section id="donate__banner">
            <div className="donate__banner__content">
                <h1 className="title">Donate to the cause</h1>
                <p className="description"> You can be a part of a child's future by donating through these channels. </p> 
                <div className="buttons">
                    <a
                        className="button__primary"
                        href="#chaperone__form"
                    >
                        SPONSOR NOW
                    </a>
                </div>
            </div>
        </section>
    );
};


export default SponsorBanner;

