import { Link } from "react-router-dom";
import "./blogcard.style.scss";

const BlogCard = ({ blogId, blogImage, blogTitle, blogExcerpts, blogUrl, type }) => {
    let link = "";
    const typeIs = type?.toLowerCase();
    if(typeIs?.includes("blog")){
        link = "/blog/" + blogUrl;
    }else if(typeIs.includes("news")){
        link = "/blog/news/" + blogUrl;
    }else{
        link = "/blog/announcements/" + blogUrl;
    }
    return (
        <div className="blog__card">
            <Link to={link} className="showcase">
                {/* <img src={blogImage} alt='' /> */}
                <div
                    style={{
                        "--img": `url(${blogImage})`,
                        "--position": "center",
                    }}
                ></div>
            </Link>
            <div className="details">
                <p className="blog__title">{blogTitle}</p>
                <p className="blog__excerpts">{`${blogExcerpts.slice(
                    0,
                    159
                )}...`}</p>
                <Link to={link} className="read_button">
                    Read More
                </Link>
            </div>
        </div>
    );
};

export default BlogCard;
