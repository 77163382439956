import styledComponent from "styled-components";

const GroupDiv = styledComponent.div`
        width: 100%;
        display: flex;
        flex-flow: column;
`;
const Label = styledComponent.label`
    font-family: 'Kumbh Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #5C5C5C;
`;

const Inputs = styledComponent.div`
    width: 100%;
    margin-top: .5rem;
`;

const FormGroup = ({ label, forName, children }) => {
    return (
        <GroupDiv>
            <Label forName={forName}>{label}</Label>
            <Inputs>{children}</Inputs>
        </GroupDiv>
    );
};

export default FormGroup;
