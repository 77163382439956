import { useState, useEffect } from 'react';
import { wrapPromise } from '../utils';
import axios from '../config/axios.config';

const useAboutData = () => {
	const [about, setAbout] = useState({ read: () => {} });
	const [teamMembers, setTeamMembers] = useState({ read: () => {} });

	useEffect(() => {
		setAbout(wrapPromise(axios.get('/abouts')));
		setTeamMembers(wrapPromise(axios.get('/teamMembers')));
	}, []);

	return {
		about,
		teamMembersData: teamMembers,
	};
};

export default useAboutData;
