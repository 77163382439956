import useAxios from "axios-hooks";
import { useState, useCallback, useEffect } from "react";

const useArticles = (type) => {
    const [articles, setArticles] = useState([]);
    const [{ loading, error }, getArticles] = useAxios(
        { url: "/articles", method: "GET" },
        { manual: true }
    );
    const [currentPage, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(false);

    const loadMore = useCallback(async () => {
        if (loading) return;
        let response = await getArticles({
            params: {
                page: currentPage + 1,
                filters: [
                    { field: "articleType", comparator: "eq", value: type },
                ],
            },
        });
        let { docs: articles, hasNextPage, page } = response.data;
        setArticles((v) => v.concat(articles));
        setHasMore(hasNextPage);
        setPage(page);
    }, [currentPage, hasMore]);

    useEffect(() => {
        loadMore();
    }, []);

    return {
        read: () => articles,
        loading,
        error,
        loadMore,
        hasMore,
    };
};

export default useArticles;
