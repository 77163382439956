const TwitterIcon = ({ inverted, size }) => {
    if (inverted) {
        return (
            <svg
                width={size || 20}
                height={size || 17}
                fill="none"
                viewBox="0 0 20 17"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M6.467 16.212c7.189 0 11.122-5.963 11.122-11.132 0-.167-.004-.339-.011-.506a7.943 7.943 0 0 0 1.95-2.026 7.702 7.702 0 0 1-2.244.615A3.933 3.933 0 0 0 19.003.999a7.854 7.854 0 0 1-2.482.95 3.913 3.913 0 0 0-6.664 3.567A11.094 11.094 0 0 1 1.8 1.431a3.92 3.92 0 0 0 1.21 5.221 3.911 3.911 0 0 1-1.771-.488v.049a3.914 3.914 0 0 0 3.136 3.836 3.882 3.882 0 0 1-1.763.067 3.92 3.92 0 0 0 3.65 2.72 7.835 7.835 0 0 1-5.79 1.618 11.082 11.082 0 0 0 5.994 1.758Z"
                    fill="currentColor"
                />
            </svg>
        );
    }
    return (
        <svg
            width={size || 26}
            height={size || 26}
            viewBox="0 0 26 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M13 0C5.82 0 0 5.82 0 13s5.82 13 13 13 13-5.82 13-13S20.18 0 13 0Zm5.288 10.65c.005.11.007.221.007.33 0 3.385-2.575 7.286-7.284 7.286a7.224 7.224 0 0 1-3.925-1.15c.2.024.404.033.611.033 1.2 0 2.304-.407 3.18-1.095a2.565 2.565 0 0 1-2.392-1.777c.384.073.779.058 1.155-.045a2.562 2.562 0 0 1-2.054-2.51v-.031c.345.19.74.307 1.16.32a2.56 2.56 0 0 1-.792-3.42 7.28 7.28 0 0 0 5.278 2.677 2.56 2.56 0 0 1 4.363-2.336 5.142 5.142 0 0 0 1.625-.621 2.573 2.573 0 0 1-1.126 1.417 5.144 5.144 0 0 0 1.471-.405 5.194 5.194 0 0 1-1.277 1.326Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default TwitterIcon;
