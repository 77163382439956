import useAxios from "axios-hooks";
import { useState, useCallback, useEffect } from "react";

const useFeatures = () => {
    const [features, setFeatures] = useState([]);
    const [{ loading, error }, getFeatures] = useAxios(
        { url: "/features", method: "GET" },
        { manual: true }
    );
    const [currentPage, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(false);

    const loadMore = useCallback(async () => {
        if (loading) return;
        let response = await getFeatures({
            params: {
                page: currentPage + 1,
                filters: [
                ],
                limit: 6
            },
        });
        let { docs: features, hasNextPage, page } = response.data;
        setFeatures((v) => v.concat(features));
        setHasMore(hasNextPage);
        setPage(page);
    }, [currentPage, hasMore]);

    useEffect(() => {
        loadMore();
    }, []);

    return {
        read: () => features,
        loading,
        error,
        loadMore,
        hasMore,
    };
};

export default useFeatures;

