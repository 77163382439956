import styledComponents from "styled-components";
import { Section, Container, BreadCrumbs } from "../../Components";


const GallerySectionContainer = styledComponents(Container)`
        min-height: 90vh;
        margin: 90px auto;
        @media screen and (max-width: 1000px){
            margin-top: 62px;
        }
` 

const Title = styledComponents.div`
    width : 100%;
    text-align: center;
    padding :2rem 0 4.3rem;
    color: var(--title-color);
    font-family : "New York Extra Large";
    font-width: bold;
    font-size: 56px; 
    @media screen and (max-width: 900px){
        color: var(--primary-two);
        font-size: 32px;
        padding: 2rem 0; 
    }
`
const GallerySection = ({breadData, children}) => {
    return (
        <Section id="gallery_section" padding="0 1rem">
            <GallerySectionContainer maxWidth="1300px">
                    <Title>Gallery</Title>
                    <BreadCrumbs breadData={breadData}/>
                    {children}
                </GallerySectionContainer>
        </Section>
    )
}

export default GallerySection;
