import {useNavigate} from "react-router-dom";
import styledComponents from "styled-components";
import NotFoundIcon from "../../assets/svgs/NotFoundIcon";
import Button from "../Button";

const NotFound = ({error}) => {
    const navigate = useNavigate();
    return (
        <NotFoundWrapper>
            <div className="icon">
                <NotFoundIcon />
            </div>
            <div className="error">
                {error || "Page not found"}
            </div>
            <Button text={"Home"} onClick={() => {navigate("/")}} />
        </NotFoundWrapper>
    );
};

const NotFoundWrapper = styledComponents.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column; 
    padding: 0 1rem;
    .icon{
        max-width: 500px;
    }
    .error{
        padding: 2rem 0;
        font-size: 30px;
        font-family: "New York Extra Large";
        color: var(--title-color);
    }
    button{
        background-color: var(--primary-two);
        color: var(--white);
        padding: 0.7rem 1.5rem;
        border-radius: 30px;
    }
`;
export default NotFound;
